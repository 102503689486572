import { RETAILER_ROUTE_PATH } from "utils/constants/routePath";
import { Icons } from "components";
import DashboardContainer from "containers/RetailerSystem/DashboardSystem/DashboardContainer";
import UserDataContainer from "containers/RetailerSystem/UserDataSystem/UserDataContainer";
import UserDataUpdateContainer from "containers/RetailerSystem/UserDataSystem/UserDataUpdateContainer";
import UserDataEditContainer from "containers/RetailerSystem/UserDataSystem/UserDataEditContainer";
import ProductRegisterContainer from "containers/RetailerSystem/ProductRegisterSystem/ProductRegisterContainer";
import ProductRegisterDetailContainer from "containers/RetailerSystem/ProductRegisterSystem/ProductRegisterDetailContainer";
// import WheelGameContainer from 'containers/RetailerSystem/WheelGameSystem/WheelGameContainer';
// import WheelDetailContainer from 'containers/RetailerSystem/WheelGameSystem/WheelDetailContainer';
// import WheelUpdateContainer from 'containers/RetailerSystem/WheelGameSystem/WheelUpdateContainer';
// import RedemptionStatisticContainer from 'containers/RetailerSystem/RedemptionStatisticSystem/RedemptionStatisticContainer';
// import RedemptionStatisticDetailContainer from 'containers/RetailerSystem/RedemptionStatisticSystem/RedemptionStatisticDetailContainer';
// import RedemptionOnProcessContainer from 'containers/RetailerSystem/RedemptionOnProcessSystem/RedemptionOnProcessContainer';
// import RedemptionOnProcessDetailContainer from 'containers/RetailerSystem/RedemptionOnProcessSystem/RedemptionOnProcessDetailContainer';
// import RedemptionSuccessContainer from 'containers/RetailerSystem/RedemptionSuccessSystem/RedemptionSuccessContainer';
// import RedemptionSuccessDetailContainer from 'containers/RetailerSystem/RedemptionSuccessSystem/RedemptionSuccessDetailContainer';
import PrizesContainer from "containers/RetailerSystem/PrizesSystem/PrizesContainer";
import PrizesDetailContainer from "containers/RetailerSystem/PrizesSystem/PrizesDetailContainer";
import PrizesUpdateContainer from "containers/RetailerSystem/PrizesSystem/PrizesUpdateContainer";
import RedemptionOnProcessBottomContainer from "containers/RetailerSystem/RedemptionOnProcessBottomSystem/RedemptionOnProcessBottomContainer";
import RedemptionOnProcessBottomDetailContainer from "containers/RetailerSystem/RedemptionOnProcessBottomSystem/RedemptionOnProcessBottomDetailContainer";
import RedemptionSuccessBottomContainer from "containers/RetailerSystem/RedemptionSuccessBottomSystem/RedemptionSuccessBottomContainer";
import RedemptionSuccessBottomDetailContainer from "containers/RetailerSystem/RedemptionSuccessBottomSystem/RedemptionSuccessBottomDetailContainer";
import NewContainer from "containers/RetailerSystem/NewSystem/NewContainer";
import NewUpdateContainer from "containers/RetailerSystem/NewSystem/NewUpdateContainer";
import NewEditContainer from "containers/RetailerSystem/NewSystem/NewEditContainer";
import BannerContainer from "containers/RetailerSystem/BannerSystem/BannerContainer";
import BannerUpdateContainer from "containers/RetailerSystem/BannerSystem/BannerUpdateContainer";
import BannerEditContainer from "containers/RetailerSystem/BannerSystem/BannerEditContainer";
import BackgroundContainer from "containers/RetailerSystem/BackgroundContainer";

const cmsRoutes = [
  {
    key: "dashboard",
    name: "Dashboard",
    children: [
      {
        key: "dashboard",
        name: "Dash Board",
        path: RETAILER_ROUTE_PATH.DASHBOARD,
        element: <DashboardContainer />,
        icon: <Icons.Home />,
      },
    ],
  },
  {
    key: "group2",
    name: "group2",
    children: [
      {
        key: "user_data",
        name: "Retailer data",
        path: RETAILER_ROUTE_PATH.USER_DATA,
        element: <UserDataContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: RETAILER_ROUTE_PATH.USER_DATA_DETAIL,
        isSubMenu: true,
        element: <UserDataUpdateContainer />,
      },
      {
        path: RETAILER_ROUTE_PATH.USER_DATA_EDIT_DETAIL,
        isSubMenu: true,
        element: <UserDataEditContainer />,
      },
      {
        key: "product_register",
        name: "Product register data",
        path: RETAILER_ROUTE_PATH.PRODUCT_REGISTER_DATA,
        element: <ProductRegisterContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: RETAILER_ROUTE_PATH.PRODUCT_REGISTER_DATA_DETAIL,
        isSubMenu: true,
        element: <ProductRegisterDetailContainer />,
      },
      // {
      //   key: 'whell_game_prizes',
      //   name: 'Wheel game prizes',
      //   path: RETAILER_ROUTE_PATH.WHEEL_GAME_PRIZES,
      //   element: <WheelGameContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   isSubMenu: true,
      //   path: RETAILER_ROUTE_PATH.WHEEL_GAME_PRIZES_DETAIL,
      //   element: <WheelDetailContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   isSubMenu: true,
      //   path: RETAILER_ROUTE_PATH.WHEEL_GAME_PRIZES_UPDATE,
      //   element: <WheelUpdateContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   key: 'redemption_statistic',
      //   name: 'Redemption statistics ',
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_STATISTIC,
      //   element: <RedemptionStatisticContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_STATISTIC_DETAIL,
      //   isSubMenu: true,
      //   element: <RedemptionStatisticDetailContainer />,
      // },
      // {
      //   key: 'redemption_process',
      //   name: 'Redemption on process',
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_PROCESS,
      //   element: <RedemptionOnProcessContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_PROCESS_DETAIL,
      //   isSubMenu: true,
      //   element: <RedemptionOnProcessDetailContainer />,
      // },
      // {
      //   key: 'redemption_success',
      //   name: 'Redemption success',
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_SUCCESS,
      //   element: <RedemptionSuccessContainer />,
      //   icon: <Icons.Graph />,
      // },
      // {
      //   path: RETAILER_ROUTE_PATH.REDEMPTION_SUCCESS_DETAIL,
      //   isSubMenu: true,
      //   element: <RedemptionSuccessDetailContainer />,
      // },
    ],
  },
  {
    key: "group3",
    name: "group3",
    children: [
      {
        key: "prizes",
        name: "Reward game prizes",
        path: RETAILER_ROUTE_PATH.PRIZES,
        element: <PrizesContainer />,
        icon: <Icons.Papper />,
      },
      {
        path: RETAILER_ROUTE_PATH.PRIZES_DETAIL,
        isSubMenu: true,
        element: <PrizesDetailContainer />,
      },
      {
        path: RETAILER_ROUTE_PATH.PRIZES_UPDATE,
        isSubMenu: true,
        element: <PrizesUpdateContainer />,
      },
      {
        key: "redemption_process_b",
        name: "Redemption on process",
        path: RETAILER_ROUTE_PATH.REDEMPTION_PROCESS_B,
        element: <RedemptionOnProcessBottomContainer />,
        icon: <Icons.Papper />,
      },
      {
        path: RETAILER_ROUTE_PATH.REDEMPTION_PROCESS_B_DETAIL,
        isSubMenu: true,
        element: <RedemptionOnProcessBottomDetailContainer />,
      },
      {
        key: "redemption_success_b",
        name: "Redemption success",
        path: RETAILER_ROUTE_PATH.REDEMPTION_SUCCESS_B,
        element: <RedemptionSuccessBottomContainer />,
        icon: <Icons.Papper />,
      },
      {
        path: RETAILER_ROUTE_PATH.REDEMPTION_SUCCESS_B_DETAIL,
        isSubMenu: true,
        element: <RedemptionSuccessBottomDetailContainer />,
      },
      {
        key: "new",
        name: "New",
        path: RETAILER_ROUTE_PATH.NEW,
        element: <NewContainer />,
        icon: <Icons.Chat />,
      },
      {
        path: RETAILER_ROUTE_PATH.NEW_UPDATE,
        isSubMenu: true,
        element: <NewUpdateContainer />,
      },
      {
        path: RETAILER_ROUTE_PATH.NEW_EDIT_DETAIL,
        isSubMenu: true,
        element: <NewEditContainer />,
      },
      {
        key: "banner",
        name: "Banner",
        path: RETAILER_ROUTE_PATH.BANNER,
        element: <BannerContainer />,
        icon: <Icons.Chat />,
      },
      {
        path: RETAILER_ROUTE_PATH.BANNER_UPDATE,
        isSubMenu: true,
        element: <BannerUpdateContainer />,
      },
      {
        path: RETAILER_ROUTE_PATH.BANNER_EDIT_DETAIL,
        isSubMenu: true,
        element: <BannerEditContainer />,
      },
      {
        key: "system-config",
        name: "Change BG",
        path: RETAILER_ROUTE_PATH.SYSTEM_CONFIG,
        element: <BackgroundContainer />,
        icon: <Icons.Papper />,
      },
    ],
  },
];

export default cmsRoutes;
