import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputLineBottomStyled } from './styled';

const InputLineBottom = ({ theme_standard, label, errors, ...props }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  
  return (
    <InputLineBottomStyled>
      <div className={customClass}>
        <div className="input_line_bottom_container">
          <div className="input_line_bottom_label">{label}</div>
          <input className="input_line_bottom" {...props} />
        </div>
        {errors && <div className="error">{errors}</div>}
      </div>
    </InputLineBottomStyled>
  );
};

InputLineBottom.propTypes = {};

export default InputLineBottom;
