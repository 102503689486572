import styled from 'styled-components';

export const HeaderBarWidgetStyled = styled.div`
  height: 68px;
  padding: 0 32px;
  background: ${({ theme }) => theme.COLORS.GREEN_1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav_left_col {
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S25};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    cursor: pointer;
  }
  .nav_right_col {
    display: flex;
    column-gap: 10px;
    .nav_profile {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
    .nav_line_between {
      width: 1px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .nav_logout {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      cursor: pointer;
    }
  }
`;
