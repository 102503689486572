import { CONSUMER_ROUTE_PATH } from "utils/constants/routePath";
import { Icons } from "components";
import DashboardContainer from "containers/ConsumerSystem/DashboardSystem/DashboardContainer";
import UserDataContainer from "containers/ConsumerSystem/UserDataSystem/UserDataContainer";
import UserDataUpdateContainer from "containers/ConsumerSystem/UserDataSystem/UserDataUpdateContainer";
import UserDataEditContainer from "containers/ConsumerSystem/UserDataSystem/UserDataEditContainer";
import ProductRegisterContainer from "containers/ConsumerSystem/ProductRegisterSystem/ProductRegisterContainer";
import ProductRegisterDetailContainer from "containers/ConsumerSystem/ProductRegisterSystem/ProductRegisterDetailContainer";
import WheelGameContainer from "containers/ConsumerSystem/WheelGameSystem/WheelGameContainer";
import WheelDetailContainer from "containers/ConsumerSystem/WheelGameSystem/WheelDetailContainer";
import WheelUpdateContainer from "containers/ConsumerSystem/WheelGameSystem/WheelUpdateContainer";
import RedemptionStatisticContainer from "containers/ConsumerSystem/RedemptionStatisticSystem/RedemptionStatisticContainer";
import RedemptionStatisticDetailContainer from "containers/ConsumerSystem/RedemptionStatisticSystem/RedemptionStatisticDetailContainer";
import RedemptionOnProcessContainer from "containers/ConsumerSystem/RedemptionOnProcessSystem/RedemptionOnProcessContainer";
import RedemptionOnProcessDetailContainer from "containers/ConsumerSystem/RedemptionOnProcessSystem/RedemptionOnProcessDetailContainer";
import RedemptionSuccessContainer from "containers/ConsumerSystem/RedemptionSuccessSystem/RedemptionSuccessContainer";
import RedemptionSuccessDetailContainer from "containers/ConsumerSystem/RedemptionSuccessSystem/RedemptionSuccessDetailContainer";
// import PrizesContainer from 'containers/ConsumerSystem/PrizesSystem/PrizesContainer';
// import PrizesDetailContainer from 'containers/ConsumerSystem/PrizesSystem/PrizesDetailContainer';
// import PrizesUpdateContainer from 'containers/ConsumerSystem/PrizesSystem/PrizesUpdateContainer';
// import RedemptionOnProcessBottomContainer from 'containers/ConsumerSystem/RedemptionOnProcessBottomSystem/RedemptionOnProcessBottomContainer';
// import RedemptionOnProcessBottomDetailContainer from 'containers/ConsumerSystem/RedemptionOnProcessBottomSystem/RedemptionOnProcessBottomDetailContainer';
// import RedemptionSuccessBottomContainer from 'containers/ConsumerSystem/RedemptionSuccessBottomSystem/RedemptionSuccessBottomContainer';
// import RedemptionSuccessBottomDetailContainer from 'containers/ConsumerSystem/RedemptionSuccessBottomSystem/RedemptionSuccessBottomDetailContainer';
import NewContainer from "containers/ConsumerSystem/NewSystem/NewContainer";
import NewUpdateContainer from "containers/ConsumerSystem/NewSystem/NewUpdateContainer";
import NewEditContainer from "containers/ConsumerSystem/NewSystem/NewEditContainer";
import BannerContainer from "containers/ConsumerSystem/BannerSystem/BannerContainer";
import BannerUpdateContainer from "containers/ConsumerSystem/BannerSystem/BannerUpdateContainer";
import BannerEditContainer from "containers/ConsumerSystem/BannerSystem/BannerEditContainer";
import BackgroundContainer from "containers/ConsumerSystem/BackgroundContainer";

const cmsRoutes = [
  {
    key: "dashboard",
    name: "Dashboard",
    children: [
      {
        key: "dashboard",
        name: "Dash Board",
        path: CONSUMER_ROUTE_PATH.DASHBOARD,
        element: <DashboardContainer />,
        icon: <Icons.Home />,
      },
    ],
  },
  {
    key: "group2",
    name: "group2",
    children: [
      {
        key: "user_data",
        name: "Consumer data",
        path: CONSUMER_ROUTE_PATH.USER_DATA,
        element: <UserDataContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: CONSUMER_ROUTE_PATH.USER_DATA_DETAIL,
        isSubMenu: true,
        element: <UserDataUpdateContainer />,
      },
      {
        path: CONSUMER_ROUTE_PATH.USER_DATA_EDIT_DETAIL,
        isSubMenu: true,
        element: <UserDataEditContainer />,
      },
      {
        key: "product_register",
        name: "Product register data",
        path: CONSUMER_ROUTE_PATH.PRODUCT_REGISTER_DATA,
        element: <ProductRegisterContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: CONSUMER_ROUTE_PATH.PRODUCT_REGISTER_DATA_DETAIL,
        isSubMenu: true,
        element: <ProductRegisterDetailContainer />,
      },
      {
        key: "whell_game_prizes",
        name: "Wheel game prizes",
        path: CONSUMER_ROUTE_PATH.WHEEL_GAME_PRIZES,
        element: <WheelGameContainer />,
        icon: <Icons.Graph />,
      },
      {
        isSubMenu: true,
        path: CONSUMER_ROUTE_PATH.WHEEL_GAME_PRIZES_DETAIL,
        element: <WheelDetailContainer />,
        icon: <Icons.Graph />,
      },
      {
        isSubMenu: true,
        path: CONSUMER_ROUTE_PATH.WHEEL_GAME_PRIZES_UPDATE,
        element: <WheelUpdateContainer />,
        icon: <Icons.Graph />,
      },
      {
        key: "redemption_statistic",
        name: "Redemption statistics ",
        path: CONSUMER_ROUTE_PATH.REDEMPTION_STATISTIC,
        element: <RedemptionStatisticContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: CONSUMER_ROUTE_PATH.REDEMPTION_STATISTIC_DETAIL,
        isSubMenu: true,
        element: <RedemptionStatisticDetailContainer />,
      },
      {
        key: "redemption_process",
        name: "Redemption on process",
        path: CONSUMER_ROUTE_PATH.REDEMPTION_PROCESS,
        element: <RedemptionOnProcessContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: CONSUMER_ROUTE_PATH.REDEMPTION_PROCESS_DETAIL,
        isSubMenu: true,
        element: <RedemptionOnProcessDetailContainer />,
      },
      {
        key: "redemption_success",
        name: "Redemption success",
        path: CONSUMER_ROUTE_PATH.REDEMPTION_SUCCESS,
        element: <RedemptionSuccessContainer />,
        icon: <Icons.Graph />,
      },
      {
        path: CONSUMER_ROUTE_PATH.REDEMPTION_SUCCESS_DETAIL,
        isSubMenu: true,
        element: <RedemptionSuccessDetailContainer />,
      },
    ],
  },
  {
    key: "group3",
    name: "group3",
    children: [
      // {
      //   key: 'prizes',
      //   name: 'Reward game prizes',
      //   path: CONSUMER_ROUTE_PATH.PRIZES,
      //   element: <PrizesContainer />,
      //   icon: <Icons.Papper />,
      // },
      // {
      //   path: CONSUMER_ROUTE_PATH.PRIZES_DETAIL,
      //   isSubMenu: true,
      //   element: <PrizesDetailContainer />,
      // },
      // {
      //   path: CONSUMER_ROUTE_PATH.PRIZES_UPDATE,
      //   isSubMenu: true,
      //   element: <PrizesUpdateContainer />,
      // },
      // {
      //   key: 'redemption_process_b',
      //   name: 'Redemption on process',
      //   path: CONSUMER_ROUTE_PATH.REDEMPTION_PROCESS_B,
      //   element: <RedemptionOnProcessBottomContainer />,
      //   icon: <Icons.Papper />,
      // },
      // {
      //   path: CONSUMER_ROUTE_PATH.REDEMPTION_PROCESS_B_DETAIL,
      //   isSubMenu: true,
      //   element: <RedemptionOnProcessBottomDetailContainer />,
      // },
      // {
      //   key: 'redemption_success_b',
      //   name: 'Redemption success',
      //   path: CONSUMER_ROUTE_PATH.REDEMPTION_SUCCESS_B,
      //   element: <RedemptionSuccessBottomContainer />,
      //   icon: <Icons.Papper />,
      // },
      // {
      //   path: CONSUMER_ROUTE_PATH.REDEMPTION_SUCCESS_B_DETAIL,
      //   isSubMenu: true,
      //   element: <RedemptionSuccessBottomDetailContainer />,
      // },
      {
        key: "new",
        name: "New",
        path: CONSUMER_ROUTE_PATH.NEW,
        element: <NewContainer />,
        icon: <Icons.Chat />,
      },
      {
        path: CONSUMER_ROUTE_PATH.NEW_UPDATE,
        isSubMenu: true,
        element: <NewUpdateContainer />,
      },
      {
        path: CONSUMER_ROUTE_PATH.NEW_EDIT_DETAIL,
        isSubMenu: true,
        element: <NewEditContainer />,
      },
      {
        key: "banner",
        name: "Banner",
        path: CONSUMER_ROUTE_PATH.BANNER,
        element: <BannerContainer />,
        icon: <Icons.Chat />,
      },
      {
        path: CONSUMER_ROUTE_PATH.BANNER_UPDATE,
        isSubMenu: true,
        element: <BannerUpdateContainer />,
      },
      {
        path: CONSUMER_ROUTE_PATH.BANNER_EDIT_DETAIL,
        isSubMenu: true,
        element: <BannerEditContainer />,
      },
      {
        key: "system-config",
        name: "Change BG",
        path: CONSUMER_ROUTE_PATH.SYSTEM_CONFIG,
        element: <BackgroundContainer />,
        icon: <Icons.Papper />,
      },
    ],
  },
];

export default cmsRoutes;
