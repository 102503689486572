import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSwitchStyled } from './styled';
import Switch from "react-switch";

const InputSwitch = ({ theme_standard, onChange, value }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputSwitchStyled>
      <div className={customClass}>
        <Switch onChange={onChange} checked={value} />
      </div>
    </InputSwitchStyled>
  );
};

InputSwitch.propTypes = {};

export default InputSwitch;
