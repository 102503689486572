import React from "react";
import { DashboardExportContainerStyled } from "./styled";
import moment from "moment";
import "moment-timezone";
import { Tables, Buttons, Displays } from "components";
import { RenderTypeName } from "utils/functions/TypeName";
import * as XLSX from "xlsx";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
// import * as XLSX from 'xlsx/xlsx.mjs';

class DashboardExportContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  }

  handleDownloadExcel = async () => {
    const { data } = this.props;
    var wb = XLSX.utils.book_new();
    var ws_name = "SheetJS";
    if (data) {
      /* make worksheet */
      var ws_data = data.reduce((result, e, i) => {
        result.push([
          i + 1,
          moment(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          e.serial_no,
          e.retailer_detail.tax_id,
          RenderTypeName(e.retailer_detail.type),
          e.retailer_detail.store_name,
          e.retailer_detail.is_main_branch ? "สาขาหลัก" : "สาขาย่อย",
          e.retailer_detail.shop_no,
          e.retailer_detail.phone_number,
          `${e.retailer_detail.address} ต.${e.retailer_detail.sub_district} อ.${e.retailer_detail.district} ${e.retailer_detail.zipcode}`,
          e.retailer_detail.province,
          e.car_plate,
          RenderCommaMoney(Number(e.distance_registed), true),
          e.car_brand,
          e.car_model,
          e.car_year,
          e.product_type,
          e.install_position,
          e.product_model,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "วันที่ลงทะเบียน",
        "เลข S/N",
        "เลขที่บัตร",
        "ประเภทร้าน",
        "ชื่อร้าน",
        "สาขาหลัก/ย่อย",
        "รหัสสาขา",
        "เบอร์โทรศัพท์",
        "ที่อยู่ของสาขาที่ลงทะเบียน",
        "จังหวัด",
        "เลขทะเบียนรถ",
        "ระยะกิโลเมตร",
        "ยี่ห้อรถ",
        "รุ่นรถ",
        "ปี",
        "ประเภทโช้คอัพ",
        "ตำแหน่งโช้คอัพ",
        "รุ่นสินค้า",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 16 },
        { wch: 17 },
        { wch: 15 },
        { wch: 15 },
        { wch: 35 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 40 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Retailer รายงานสรุปยอด ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
    }
  };

  render() {
    const { onCloseModal, data } = this.props;
    const { isLoading } = this.state;
    return (
      <DashboardExportContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="btn_action">
              <div className="btn175">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="ย้อนกลับ"
                  onClick={onCloseModal}
                />
              </div>
              <div className="btn175">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="Export"
                  onClick={this.handleDownloadExcel}
                />
              </div>
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              useNo
              columns={columns2}
              data={data}
            />
          </>
        )}
      </DashboardExportContainerStyled>
    );
  }
}

const columns2 = [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "วันที่ลงทะเบียน",
    field: "created_at",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ value }) =>
      `${moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY")}`,
  },
  {
    title: "เลข S/N",
    field: "serial_no",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "right" },
  },
  // {
  //   title: 'รหัสสินค้า',
  //   field: 'label',
  //   headerStyle: { textAlign: 'center' },
  //   style: { textAlign: 'left' },
  // },
  // {
  //   title: 'ชื่อสินค้า',
  //   field: 'label',
  //   headerStyle: { textAlign: 'center' },
  //   style: { textAlign: 'left' },
  // },
  {
    title: "เลขบัตร",
    field: "retailer_detail.tax_id",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ประเภทร้าน",
    field: "retailer_detail.type",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{RenderTypeName(value)}</div>,
  },
  {
    title: "ชื่อร้าน",
    field: "retailer_detail.store_name",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "สาขาหลัก/ย่อย",
    field: "retailer_detail.is_main_branch",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value ? "สาขาหลัก" : "สาขาย่อย"}</div>,
  },
  {
    title: "รหัสสาขา",
    field: "retailer_detail.shop_no",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "เบอร์โทรศัพท์",
    field: "retailer_detail.phone_number",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ที่อยู่ของสาขาที่ลงทะเบียน",
    field: "retailer_detail.address",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{`${value} ต.${origin.retailer_detail.sub_district} อ.${origin.retailer_detail.district} ${origin.retailer_detail.zipcode}`}</div>
    ),
  },
  {
    title: "จังหวัด",
    field: "retailer_detail.province",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "เลขทะเบียนรถ",
    field: "car_plate",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ระยะกิโลเมตร",
    field: "distance_registed",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{RenderCommaMoney(Number(value), true)}</div>
    ),
  },
  {
    title: "ยี่ห้อรถ",
    field: "car_brand",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "รุ่นรถ",
    field: "car_model",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ปี",
    field: "car_year",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ประเภทโช้คอัพ",
    field: "product_type",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "ตำแหน่งโช้คอัพ",
    field: "install_position",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "รุ่นสินค้า",
    field: "product_model",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
];

export default DashboardExportContainer;
