import React from 'react';
import { WheelDetailContainerStyled } from './styled';
import { Tables, Icons, Buttons, Inputs, Displays } from 'components';
import { retailerService } from 'apiServices';
import { withRouter } from 'navigations/withRouter';
import { RenderSortTable } from 'utils/functions/SortTable';
import moment from 'moment';
import 'moment-timezone';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class WheelDetailContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    isLoadingTable: true,
    filterQuery: null,
    perPage: 10,
    sortTable: {
      name_shipping: 1,
      phone_number_shipping: 1,
      address_province: 1,
      created_at: 1,
    }
  };

  componentDidMount() {
    const { perPage } = this.state
    scrollToTopPageFN()
    this.fetchData(`?limit=${perPage}&reward_ref_id=${this.props.router.params.id}&memberType=2`);
  }

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1)
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state

    this.setState({
      perPage: e
    })
    this.handleJoinQuery(filterQuery, e, 1);
  }

  handleFilterSelect = (e) => {
    const { perPage } = this.state

    this.setState({
      filterQuery: e
    })
    this.handleJoinQuery(e, perPage, 1)
  }

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query ? `${query}&limit=${per_page}&page=${page}&memberType=2&reward_ref_id=${this.props.router.params.id}` : `?limit=${per_page}&page=${page}&memberType=2&reward_ref_id=${this.props.router.params.id}`
    this.setState({
      isLoadingTable: true,
    })
    this.fetchData(joinQuery)
  }

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_WHEEL_GAME_HISTORY_LIST(stringQuery);

    if (res && res.status === 200) {
      let tempData = []
      res.data.docs.forEach((e) => {
        tempData.push({ ...e, name_shipping: e.firstname_shipping ? `${e.firstname_shipping} ${e.lastname_shipping}` : '' })
      })
      this.setState({
        // data: res.data.docs,
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      })
    }
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state

    if (data && data.length > 0) {
      let sortValue
      switch (e) {
        case 'name_shipping':
          sortValue = RenderSortTable(e, data, sortTable.name_shipping)
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              name_shipping: sortValue.sortNumber
            }
          })

          break;

        case 'phone_number_shipping':
          sortValue = RenderSortTable(e, data, sortTable.phone_number_shipping)
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              phone_number_shipping: sortValue.sortNumber
            }
          })

          break;

        case 'address_province':
          sortValue = RenderSortTable(e, data, sortTable.address_province)
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              address_province: sortValue.sortNumber
            }
          })

          break;

        case 'created_at':
          sortValue = RenderSortTable(e, data, sortTable.created_at)

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber
            }
          })
          break;

        default:
          break;
      }
    }
  }

  handleDownloadExcel = async () => {
    const { data } = this.state;
    var wb = XLSX.utils.book_new();
    var ws_name = 'SheetJS';
    if (data) {
      /* make worksheet */
      var ws_data = data.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.firstname_shipping,
          e.lastname_shipping,
          e.phone_number_shipping,
          e.address_province,
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'ชื่อ',
        'นามสกุล',
        'เบอร์โทร',
        'จังหวัด',
        'วันที่ลงทะเบียน',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 17 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Consumer Pizes report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
    }
  };

  render() {
    const { isLoading, data, pagination, isLoadingTable, } = this.state;
    const { dataShop } = this.props

    return (
      <WheelDetailContainerStyled>
        {
          isLoading
            ?
            <div className='loading_show'>
              <Displays.Loading />
            </div>
            :
            <>
              <div className='title_show'>
                Prizes
              </div>
              <div className='title_show'>
                <Inputs.InputFilter
                  theme_standard_input_filter
                  dataFilter={dataFilter4}
                  btnLabel="ค้นหา"
                  onQueryFilter={this.handleFilterSelect}
                />
              </div>
              <div className='opt_zone2'>
                <div className='labl_zone'>
                  {`ชื่อของรางวัล:  ${dataShop}`}
                </div>
                <div className='opt_select'>
                  <div className='drop'>
                    <Inputs.InputDropDownFilter
                      placeholder='เลือก'
                      iconSvgClose={
                        <Icons.ArrowDropDown />
                      }
                      iconSvgOpen={<Icons.ArrowDropDown up />}
                      onChange={this.handleSelectedPerPage}
                      options={[
                        { label: 'แสดง 10 แถว', value: '10' },
                        { label: 'แสดง 20 แถว', value: '20' },
                        { label: 'แสดง 30 แถว', value: '30' },
                        { label: 'แสดง 40 แถว', value: '40' },
                        { label: 'แสดง 50 แถว', value: '50' },
                      ]}
                    />
                  </div>
                  <div className='btnw'>
                    <Buttons.BtnNormal
                      theme_normal_btn label="Export"
                      onClick={this.handleDownloadExcel}
                    />
                  </div>
                </div>
              </div>
              <div className='title_show'>
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={data}
                  pagination={pagination}
                  isLoading={isLoadingTable}
                  handlePageClick={this.handlePageClick}
                  columns={columns14({
                    onSort: this.handleSort,
                  })}
                />
              </div>
            </>
        }
      </WheelDetailContainerStyled>
    );
  }
}

const dataFilter4 = [
  {
    accessor: 'nameBy',
    length: '260px',
    filter: {
      fieldQuery: 'firstname_shipping',
      type: 'text',
      placeholder: 'ค้นหาชื่อ',
    },
  },
  {
    accessor: 'phoneBy',
    length: '260px',
    filter: {
      fieldQuery: 'phone_number_shipping',
      type: 'text',
      placeholder: 'ค้นหาเบอร์โทร',
    },
  },
  {
    accessor: 'provinceBy',
    length: '260px',
    filter: {
      fieldQuery: 'address_province',
      type: 'text',
      placeholder: 'ค้นหาจังหวัด',
    },
  },
];


const columns14 = ({ onSort }) => [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }} onClick={() => onSort('name_shipping')}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name_shipping',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }} onClick={() => onSort('phone_number_shipping')}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone_number_shipping',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }} onClick={() => onSort('address_province')}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address_province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }} onClick={() => onSort('created_at')}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div> {`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
];

const mapStateToProps = (state) => ({
  dataShop: state.dataShop,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WheelDetailContainer));