import apiService from "../apiService";

const apiRetailerPath = "/api/v1/retailer";
const apiConsumerPath = "/api/v1/consumer";
const apiPortalPath = "/api/v1/portal";

export const retailerService = {
  // SING IN
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPortalPath}/login`, params);
  },

  // RETAILER
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPortalPath}/admin/myProfile`);
  },
  GET_RETAILER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiRetailerPath}/retailer${stringQuery ? stringQuery : ""}`
    );
  },
  GET_RETAILER_DETAIL: (id) => {
    return apiService.get(`${apiRetailerPath}/retailer/${id}`);
  },
  PUT_RETAILER: (id, params) => {
    return apiService.put(`${apiRetailerPath}/retailer/${id}`, params);
  },

  // PRODUCT REGISTED
  GET_PRODUCT_REGISTED_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/product_registed${stringQuery ? stringQuery : ""}`
    );
  },
  GET_PRODUCT_REGISTED_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/product_registed/${id}`);
  },
  GET_PRODUCT_REGISTED_LIST_RETAILER: (id) => {
    return apiService.get(
      `${apiPortalPath}/product_registed/list_by_retailer/${id}`
    );
  },
  GET_PRODUCT_REGISTED_LIST_CONSUMER: (id) => {
    return apiService.get(
      `${apiPortalPath}/product_registed/list_by_consumer/${id}`
    );
  },

  // PRODUCT REGISTED
  GET_PRODUCT_CAR_BAND_LIST: () => {
    return apiService.get(`${apiPortalPath}/product/car_brand`);
  },

  //NEW RETAILER
  GET_RETAILER_NEW_LIST: (stringQuery) => {
    return apiService.get(
      `${apiRetailerPath}/r_news${stringQuery ? stringQuery : ""}`
    );
  },
  GET_RETAILER_NEW_DETAIL: (id) => {
    return apiService.get(`${apiRetailerPath}/r_news/${id}`);
  },
  POST_RETAILER_NEW: (params) => {
    return apiService.post(`${apiRetailerPath}/r_news`, params);
  },
  PUT_RETAILER_NEW: (id, params) => {
    return apiService.put(`${apiRetailerPath}/r_news/${id}`, params);
  },
  DELETE_RETAILER_NEW: (id) => {
    return apiService.delete(`${apiRetailerPath}/r_news/${id}`);
  },
  POST_UPLOAD_RETAILER_NEW: (params) => {
    return apiService.post_formdata(`${apiRetailerPath}/r_news/upload`, params);
  },
  POST_DELETE_UPLOAD_RETAILER_NEW: (params) => {
    return apiService.post(`${apiRetailerPath}/r_news/deleteUpload`, params);
  },

  //BANNER RETAILER
  GET_RETAILER_BANNER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiRetailerPath}/r_banner${stringQuery ? stringQuery : ""}`
    );
  },
  GET_RETAILER_BANNER_DETAIL: (id) => {
    return apiService.get(`${apiRetailerPath}/r_banner/${id}`);
  },
  POST_RETAILER_BANNER: (params) => {
    return apiService.post(`${apiRetailerPath}/r_banner`, params);
  },
  PUT_RETAILER_BANNER: (id, params) => {
    return apiService.put(`${apiRetailerPath}/r_banner/${id}`, params);
  },
  DELETE_RETAILER_BANNER: (id) => {
    return apiService.delete(`${apiRetailerPath}/r_banner/${id}`);
  },
  POST_UPLOAD_RETAILER_BANNER: (params) => {
    return apiService.post_formdata(
      `${apiRetailerPath}/r_banner/upload`,
      params
    );
  },
  POST_DELETE_UPLOAD_RETAILER_BANNER: (params) => {
    return apiService.post(`${apiRetailerPath}/r_banner/deleteUpload`, params);
  },

  POST_RETAILER: (params) => {
    return apiService.post(`${apiConsumerPath}/retailer`, params);
  },

  //WHEEL GAME REWARD
  GET_WHEEL_GAME_REWARD_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/wheel_game_reward${stringQuery ? stringQuery : ""}`
    );
  },
  GET_WHEEL_GAME_REWARD_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/wheel_game_reward/${id}`);
  },
  POST_WHEEL_GAME_REWARD: (params) => {
    return apiService.post(`${apiPortalPath}/wheel_game_reward`, params);
  },
  PUT_WHEEL_GAME_REWARD: (id, params) => {
    return apiService.put(`${apiPortalPath}/wheel_game_reward/${id}`, params);
  },
  DELETE_WHEEL_GAME_REWARD: (id) => {
    return apiService.delete(`${apiPortalPath}/wheel_game_reward/${id}`);
  },
  POST_WHEEL_GAME_UPLOAD_REWARD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/wheel_game_reward/upload`,
      params
    );
  },
  POST_WHEEL_GAME_DELETE_UPLOAD_REWARD: (params) => {
    return apiService.post(
      `${apiPortalPath}/wheel_game_reward/deleteUpload`,
      params
    );
  },

  //WHEEL GAME HISTORY
  GET_WHEEL_GAME_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/wheel_game_history${stringQuery ? stringQuery : ""}`
    );
  },
  GET_WHEEL_GAME_REWARD_ON_PROCESS_RETAILER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/wheel_game_history/on_process_retailer${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PUT_WHEEL_GAME_HISTORY: (id, params) => {
    return apiService.put(`${apiPortalPath}/wheel_game_history/${id}`, params);
  },

  //REWARD HISTORY
  GET_REWARD_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward_history${stringQuery ? stringQuery : ""}`
    );
  },
  GET_REWARD_ON_PROCESS_RETAILER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward_history/on_process_retailer${
        stringQuery ? stringQuery : ""
      }`
    );
  },
  PUT_REWARD_HISTORY: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward_history/${id}`, params);
  },

  //REWARD
  GET_REWARD_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward${stringQuery ? stringQuery : ""}`
    );
  },
  GET_REWARD_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/reward/${id}`);
  },
  POST_REWARD: (params) => {
    return apiService.post(`${apiPortalPath}/reward`, params);
  },
  PUT_REWARD: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward/${id}`, params);
  },
  DELETE_REWARD: (id) => {
    return apiService.delete(`${apiPortalPath}/reward/${id}`);
  },
  POST_UPLOAD_REWARD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/reward/upload`, params);
  },
  POST_DELETE_UPLOAD_REWARD: (params) => {
    return apiService.post(`${apiPortalPath}/reward/deleteUpload`, params);
  },

  // SYSTEM CONFIG CONSUMER
  GET_CONSUMER_BG_CONFIG_SYSTEM: () => {
    return apiService.get(`${apiConsumerPath}/system-config`);
  },
  POST_CONSUMER_UPLOAD_BG_CONFIG_SYSTEM: (params) => {
    return apiService.post_formdata(
      `${apiConsumerPath}/system-config/upload`,
      params
    );
  },
  POST_CONSUMER_DELETE_BG_CONFIG_SYSTEM: (params) => {
    return apiService.post(
      `${apiConsumerPath}/system-config/deleteUpload`,
      params
    );
  },

  // SYSTEM CONFIG RETAILER
  GET_RETAILER_BG_CONFIG_SYSTEM: () => {
    return apiService.get(`${apiRetailerPath}/system-config`);
  },
  POST_RETAILER_UPLOAD_BG_CONFIG_SYSTEM: (params) => {
    return apiService.post_formdata(
      `${apiRetailerPath}/system-config/upload`,
      params
    );
  },
  POST_RETAILER_DELETE_BG_CONFIG_SYSTEM: (params) => {
    return apiService.post(
      `${apiRetailerPath}/system-config/deleteUpload`,
      params
    );
  },

  // POST_UPLOAD_RETAILER: (params) => {
  //   return apiService.post_formdata(`${apiPath}/retailer/upload`, params);
  // },
  // POST_DELETE_UPLOAD_RETAILER: (params) => {
  //   return apiService.post(`${apiPath}/retailer/deleteUpload`, params);
  // },
  // PUT_RETAILER: (id, params) => {
  //   return apiService.put(`${apiPath}/retailer/${id}`, params);
  // },

  // // OTP
  // POST_OTP_REQUEST: (params) => {
  //   return apiService.post(`${apiPortalPath}/otp_history/request`, params);
  // },
  // POST_OTP_VERIFY: (params) => {
  //   return apiService.post(`${apiPortalPath}/otp_history/verify`, params);
  // },

  // // REWARD
  // GET_REWARD_LIST: () => {
  //   return apiService.get(`${apiPortalPath}/reward`);
  // },
  // POST_REWARD_REDEEM: (params) => {
  //   return apiService.post(`${apiPortalPath}/reward/redeem`, params);
  // },

  // // REWARD HISTORY
  // GET_MY_REWARD_HISTORY: () => {
  //   return apiService.get(`${apiPortalPath}/reward_history/my_redeem`);
  // },

  // // PRODUCT HISTORY
  // GET_MY_PRODUCT_HISTORY: () => {
  //   return apiService.get(`${apiPortalPath}/product_registed/my_product_regis`);
  // },

  // //PRODUCT REGISTED
  // GET_SERIAL_NO: (id) => {
  //   return apiService.get(`${apiPortalPath}/product_registed/serial_no/${id}`);
  // },
  // POST_ACTIVE_RETAILER: (params) => {
  //   return apiService.post(`${apiPortalPath}/product_registed/active_retailer`, params);
  // },
};
