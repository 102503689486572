import { HeaderBarWidgetStyled } from './styled';
import { useNavigate } from 'react-router-dom';

const HeaderBarWidget = () => {
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  }

  return (
    <HeaderBarWidgetStyled>
      <div className="nav_left_col">SMP Admin</div>
      <div className="nav_right_col">
        <div className="nav_profile">Administrator</div>
        <div className="nav_line_between" />
        <div className="nav_logout" onClick={handleLogout}>Logout</div>
      </div>
    </HeaderBarWidgetStyled>
  );
};

export default HeaderBarWidget;
