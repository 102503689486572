import styled from 'styled-components';

export const ProfileStyled = styled.div`
  .continaer_profile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    .icon_show {
      display: flex;
      margin-left: 11px;
      cursor: pointer;
    }
  }

  .theme_profile {

  }


`;
