import React from 'react';
import { LoginContainerStyled } from './styled';
import { toast } from 'react-toastify';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { Forms } from 'components';
import { connect } from 'react-redux';
import { setReduxAuthen } from 'store/actions';
import { retailerService } from 'apiServices';

class LoginContainer extends React.Component {
  state = {};

  componentDidMount() {
    // this.destroyToken();
  }

  handleSubmit = async (values) => {
    let params = {
      ...values,
    };

    let res = await retailerService.POST_LOGIN(params);
    
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      let resProfile = await retailerService.GET_MY_PROFILE();
      if (resProfile && resProfile.status === 200) {
        this.props.setReduxAuthen(resProfile.data);
        toast.success('Login success');
        this.props.router.navigate(RETAILER_ROUTE_PATH.DASHBOARD);
      }
    } else {
      toast.error('Login failed');
    }
  };

  // destroyToken = () => {
  //   localStorage.clear();
  // };

  render() {
    return (
      <LoginContainerStyled>
        <div className="form_layout">
          <Forms.LoginForm onSubmit={this.handleSubmit} />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));