import React, { forwardRef } from 'react';
import cx from 'classnames';
import { InputTextFieldStyled } from './styled';

const InputTextField = forwardRef(
  (
    {
      theme_standard,
      theme_filter,
      theme_mini,
      placeholder,
      disabled,
      label,
      errors,
      icon,
      onSubmit,
      maxLength,
      onChangeCustom,
      onChange,
      iconTop,
      ...props
    },
    ref
  ) => {
    const customClass = cx({
      theme_standard: theme_standard,
      theme_filter: theme_filter,
      theme_mini: theme_mini,
    });
    return (
      <InputTextFieldStyled disabled={disabled}>
        <div className={customClass}>
          <div className="input_container">
            {label && <div className="label">{label}</div>}
            {iconTop && <div className="icon_top">{iconTop}</div>}
            <input
              ref={ref}
              {...props}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              onChange={(e) => {
                onChange(e);
                onChangeCustom && onChangeCustom(e);
              }}
            />
            {icon && (
              <div className="btn_submit" onClick={onSubmit && onSubmit}>
                {icon}
              </div>
            )}
          </div>
        </div>
        {errors && <div className="error">*{errors}</div>}
      </InputTextFieldStyled>
    );
  }
);

InputTextField.propTypes = {};

export default InputTextField;
