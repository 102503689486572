export const RENDER_STORE_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        label: "ร้านอะไหล่",
      };
    case "2":
      return {
        label: "ศูนย์บริการติดตั้ง",
      };
    case "3":
      return {
        label: "ร้านอะไหล่ และศูนย์บริการติดตั้ง",
      };
    default:
      return {
        label: "",
      };
  }
};
