import React from 'react';
import { UserDataEditContainerStyled } from './styled';
import { Forms, Displays } from 'components';
import { withRouter } from 'navigations/withRouter';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { retailerService } from 'apiServices';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class UserDataEditContainer extends React.Component {
  state = {
    isLoading: true,
    initialValues: null,
    disabled: false,
  };

  componentDidMount() {
    scrollToTopPageFN()
    this.fetchData();
  }

  fetchData = async () => {
    let res = await retailerService.GET_RETAILER_DETAIL(this.props.router.params.id);

    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      })
    }
  };

  handleBack = () => {
    this.props.router.navigate(`${RETAILER_ROUTE_PATH.USER_DATA}/${this.props.router.params.id}`);
  };

  handleSubmit = async (values) => {
    let params = {
      address: values.address,
      firstname: values.firstname,
      lastname: values.lastname,
      phone_number: values.phone_number,
      store_name: values.store_name,
      tax_id: values.tax_id,
      is_active_pdpa: values.is_active_pdpa,
      is_active_tc: values.is_active_tc,
      zipcode: values.zipcode,
      district: values.district.value,
      province: values.province.value,
      sub_district: values.sub_district.value,
    };

    this.setState({
      disabled: true
    })

    let res = await retailerService.PUT_RETAILER(this.props.router.params.id, params);

    if (res && res.status === 200) {
      toast.success('Edit success');
      this.props.router.navigate(`${RETAILER_ROUTE_PATH.USER_DATA}/${this.props.router.params.id}`);
    } else {
      toast.error('Edit failed');
    }
  };

  render() {
    const { isLoading, initialValues, disabled } = this.state;
    const { dataShop } = this.props

    return (
      <UserDataEditContainerStyled>
        {
          isLoading
            ?
            <div className='loading_show'>
              <Displays.Loading />
            </div>
            :
            <>
              <Forms.RetailerForm
                handleBack={this.handleBack}
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                disabled={disabled}
                dataShop={dataShop}
              />
              {/* <div className='table_zone'>
                <div className='title_table_zone'>
                  สาขาย่อย
                </div>
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={tempData4}
                  columns={columns4}
                />
              </div>
              <div className='table_zone'>
                <div className='title_table_zone'>
                  การได้รับรางวัล
                </div>
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={tempData5}
                  columns={columns5}
                />
              </div>
              <div className='table_zone'>
                <div className='title_table_zone'>
                  การลงทะเบียนสินค้า
                </div>
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={tempData6}
                  columns={columns6}
                />
              </div> */}
            </>
        }
      </UserDataEditContainerStyled >
    );
  }
}

// const tempData6 = [
//   {
//     date1: '20/5/2565',
//     sn_num: 'SN123A123A125',
//     car_id: 'รวย 2365',
//     man_regis: 'สาขาหลัก',
//     brand_car: 'Toyota',
//     type_car: 'Camry',
//     year: '2010 - 2014',
//     type1: 'Xxxxxxxxxxxx',
//     position: 'Xxxxxxxxxxxx',
//     type_product: 'Xxxxxxxxxxxx',
//     point: '25',
//   },
//   {
//     date1: '20/5/2565',
//     sn_num: 'SN123A123A125',
//     car_id: 'รวย 2365',
//     man_regis: 'สาขาหลัก',
//     brand_car: 'Toyota',
//     type_car: 'Camry',
//     year: '2010 - 2014',
//     type1: 'Xxxxxxxxxxxx',
//     position: 'Xxxxxxxxxxxx',
//     type_product: 'Xxxxxxxxxxxx',
//     point: '25',
//   },
//   {
//     date1: '20/5/2565',
//     sn_num: 'SN123A123A125',
//     car_id: 'รวย 2365',
//     man_regis: 'สาขาหลัก',
//     brand_car: 'Toyota',
//     type_car: 'Camry',
//     year: '2010 - 2014',
//     type1: 'Xxxxxxxxxxxx',
//     position: 'Xxxxxxxxxxxx',
//     type_product: 'Xxxxxxxxxxxx',
//     point: '25',
//   },
// ]

// const columns5 = [
//   {
//     title: 'ชิ้นที่',
//     field: 'no',
//     headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
//     style: { textAlign: 'center', width: '50px', flexShrink: 0 },
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         เลข S/N
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'sn_num',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div style={{ textDecoration: 'underline' }}>
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         รางวัลที่ได้รับ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'reward',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         จังหวัด
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'province',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         วันที่ทำรายการ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'date1',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         วันที่จัดส่ง
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'date_send',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         บ. ที่จัดส่ง
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'company_send',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         เลขที่ส่ง
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'send_num',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         วันที่ได้รับ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'date2',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
// ];

// const tempData5 = [
//   {
//     sn_num: 'SN123A123A125',
//     reward: 'สุดยอดของรางวัล',
//     province: 'นครศรีธรรมราช',
//     date1: '20/5/2565',
//     date_send: '20/5/2565',
//     company_send: 'Kerry',
//     send_num: 'TH43544D4226',
//     date2: '20/5/2565',
//   },
//   {
//     sn_num: 'SN123A123A125',
//     reward: 'สุดยอดของรางวัล',
//     province: 'นครศรีธรรมราช',
//     date1: '20/5/2565',
//     date_send: '20/5/2565',
//     company_send: 'Kerry',
//     send_num: 'TH43544D4226',
//     date2: '20/5/2565',
//   },
//   {
//     sn_num: 'SN123A123A125',
//     reward: 'สุดยอดของรางวัล',
//     province: 'นครศรีธรรมราช',
//     date1: '20/5/2565',
//     date_send: '20/5/2565',
//     company_send: 'Kerry',
//     send_num: 'TH43544D4226',
//     date2: '20/5/2565',
//   },
//   {
//     sn_num: 'SN123A123A125',
//     reward: 'สุดยอดของรางวัล',
//     province: 'นครศรีธรรมราช',
//     date1: '20/5/2565',
//     date_send: '20/5/2565',
//     company_send: 'Kerry',
//     send_num: 'TH43544D4226',
//     date2: '20/5/2565',
//   },
// ]

// const columns4 = [
//   {
//     title: 'ลำดับ',
//     field: 'no',
//     headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
//     style: { textAlign: 'center', width: '50px', flexShrink: 0 },
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         วันที่ลงทะเบียน
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'date',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ชื่อร้าน
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'shop_name',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div style={{ textDecoration: 'underline' }}>
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ชื่อ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'name',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         นามสกุล
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'surname',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         เบอร์โทร
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'phone',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ที่อยู่
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'address',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         จำนวนลงทะเบียน
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'amount',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'center' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
// ];

// const tempData4 = [
//   {
//     date: '20/5/2565',
//     shop_name: 'รักยานยนต์ สาขายางนก',
//     name: 'รวยยานยต์',
//     surname: 'บุญรักษา',
//     phone: '0816666666',
//     address: 'ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์',
//     amount: '12',
//   },
//   {
//     date: '20/5/2565',
//     shop_name: 'รักยานยนต์ สาขายางนก',
//     name: 'รวยยานยต์',
//     surname: 'บุญรักษา',
//     phone: '0816666666',
//     address: 'ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์',
//     amount: '12',
//   },
// ]

// const columns6 = [
//   {
//     title: 'ลำดับ',
//     field: 'no',
//     headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
//     style: { textAlign: 'center', width: '50px', flexShrink: 0 },
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         วันที่ลงทะเบียน
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'date1',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         เลข S/N
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'sn_num',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div style={{ textDecoration: 'underline' }}>
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ทะเบียนรถ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'car_id',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ผู้ลงทะเบียน
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'man_regis',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ยี้ห้อรถ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'brand_car',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         รุ่นรถ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'type_car',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ปี
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'year',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ประเภทโช้คอัพ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'type1',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         ตำแหน่งโช้คอัพ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'position',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         รุ่นสินค้า
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'type_product',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
//   {
//     title: (
//       <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//         จำนวนแต้มที่ได้รับ
//         <div style={{ marginLeft: '8px', display: 'flex' }}>
//           <Icons.ArrowSort />
//         </div>
//       </div>
//     ),
//     field: 'point',
//     headerStyle: { textAlign: 'left' },
//     style: { textAlign: 'left' },
//     render: ({ origin, value }) => (
//       <div >
//         {value}
//       </div>
//     ),
//   },
// ];

const mapStateToProps = (state) => ({
  dataShop: state.dataShop,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDataEditContainer));