import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvatarUploadStyled } from './styled';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import { useDropzone } from 'react-dropzone';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Icons } from 'components';

const AvatarUpload = ({
  theme_standard,
  onChange,
  acceptType,
  value,
  maxMBFileSizeWarning,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  // const [_crop, _setCrop] = useState();
  const [_files, _setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case 'cover':
        return 'image/*';
      case 'audio':
        return '.mp3,audio/mpeg3';
      case 'video':
        return 'video/mp4';
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      let fileTemp = [
        ..._files
          .filter((e) => !e.isNewUpload)
          .map((e) => ({ ...e, isDelete: true })),
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      _setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      _setFiles(value);
    }
  }, [value]);

  return (
    <AvatarUploadStyled>
      <div className={customClass}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <img
            className="avartar"
            src={
              !_files || _files.length === 0
                ? '/assets/images/logos/logo.png'
                : _files.length > 0 && !_files[_files.length - 1]?.isNewUpload
                ? IMG_PATH + _files[_files.length - 1].media_uri
                : _files[_files.length - 1].preview
            }
            alt="avatar"
          />
          <div className="av_edit">
            <Icons.AvatarEdit />
          </div>
        </div>
        {/* <ReactCrop crop={_crop} onChange={(c) => _setCrop(c)}>
          <img src={src} />
        </ReactCrop> */}
      </div>
    </AvatarUploadStyled>
  );
};

AvatarUpload.propTypes = {};

export default AvatarUpload;
