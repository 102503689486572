import React from 'react';
import { BannerContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { Tables, Icons, Buttons, Inputs, Displays } from 'components';
import { consumerService } from 'apiServices';
import moment from 'moment';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RenderSortTable } from 'utils/functions/SortTable';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class BannerContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    filterQuery: null,
    isLoadingTable: true,
    perPage: 10,
    sortTable: {
      created_at: 1,
      is_active: 1,
      title: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}`);
  }

  fetchData = async (stringQuery) => {
    let res = await consumerService.GET_CONSUMER_BANNER_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleEdit = (e) => {
    this.props.router.navigate(`${CONSUMER_ROUTE_PATH.BANNER}/${e}`);
  };

  handleCreate = () => {
    this.props.router.navigate(`${CONSUMER_ROUTE_PATH.BANNER_EDIT}/create`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;

    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;

    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;

    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case 'created_at':
          sortValue = RenderSortTable(e, data, sortTable.created_at);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber,
            },
          });

          break;

        case 'is_active':
          sortValue = RenderSortTable(e, data, sortTable.is_active);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              is_active: sortValue.sortNumber,
            },
          });

          break;

        case 'title':
          sortValue = RenderSortTable(e, data, sortTable.title);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              title: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  render() {
    const { isLoading, data, pagination, isLoadingTable } = this.state;
    return (
      <BannerContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Banner</div>
            <div className="btn_zone">
              <div className="box_btn">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="เพิ่ม Banner"
                  icon={<Icons.Plus />}
                  onClick={this.handleCreate}
                />
              </div>
            </div>
            <div className="opt_select_2">
              <div className="drop">
                <Inputs.InputDropDownFilter
                  placeholder="เลือก"
                  iconSvgClose={<Icons.ArrowDropDown />}
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  onChange={this.handleSelectedPerPage}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 20 แถว', value: '20' },
                    { label: 'แสดง 30 แถว', value: '30' },
                    { label: 'แสดง 40 แถว', value: '40' },
                    { label: 'แสดง 50 แถว', value: '50' },
                  ]}
                />
              </div>
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter5}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
              />
            </div>
            <div className="title_show">
              <Tables.TableNormal
                theme_standard_table_normal
                showPagination
                useNo
                data={data}
                isLoading={isLoadingTable}
                pagination={pagination}
                handlePageClick={this.handlePageClick}
                columns={columns19({
                  onEdit: this.handleEdit,
                  onSort: this.handleSort,
                })}
                // lableBottom={`แสดง 1 ถึง 25 จาก 25,000 รายการ (คัดจากรายการทั้งหมด 25,000 รายการ)`}
              />
            </div>
          </>
        )}
      </BannerContainerStyled>
    );
  }
}

const dataFilter5 = [
  {
    accessor: 'newsBy',
    length: '311px',
    filter: {
      fieldQuery: 'title',
      type: 'text',
      placeholder: 'ค้นหาชื่อ Banner',
    },
  },
];

const columns19 = ({ onEdit, onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: 'รูป',
    field: 'img_path',
    headerStyle: {
      display: 'flex',
      justifyContent: 'left',
      width: '150px',
      flexShrink: 0,
    },
    style: { textAlign: 'left', width: '150px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div
        style={{
          width: '150px',
          flexShrink: 0,
          height: '90px',
          // background: '#ffff',
        }}
      >
        {value && value !== '' && (
          <img
            src={IMG_PATH + value}
            alt="img"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        )}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อ Banner
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('title')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'title',
    headerStyle: {
      display: 'flex',
      justifyContent: 'left',
      width: '500px',
      flexShrink: 0,
    },
    style: { textAlign: 'left', width: '500px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div
        style={{
          textDecoration: 'underline',
          overflow: 'hidden',
          width: '500px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onClick={() => onEdit(origin._id)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{moment(value).format('DD/MM/YYYY')}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        Active
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('is_active')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'is_active',
    headerStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '100px',
      flexShrink: 0,
    },
    style: { textAlign: 'center', width: '100px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div
        style={{
          width: '100px',
          flexShrink: 0,
          color: value ? '#1F8469' : '#FF0000',
          fontWeight: '600',
        }}
      >
        {value ? 'ON' : 'OFF'}
      </div>
    ),
  },
];

export default withRouter(BannerContainer);
