import styled from 'styled-components';

export const LoginContainerStyled = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  .form_layout {
    max-width: 380px;
    width: 100%;
    margin: auto;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 26%), 0 -1px 0px rgb(0 0 0 / 2%);
  }
`;
