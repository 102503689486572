import styled from 'styled-components';

export const IconLabelStyled = styled.div`
  .continaer_icon_label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme, fontColor }) =>
      fontColor ? fontColor : theme.COLORS.BLACK_1};
    .icon_front {
      dispaly: flex;
      margin-right: 33px;
    }
    .num_show {
      background: ${({ theme }) => theme.COLORS.RED_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      margin-left: 5px;
      border-radius: 12px;
      padding: 2px 6px;
    }
    .icl_line_active {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 10px;
      width: 4px;
      height: 100%;
      background: #0058ff;
    }
  }

  .theme_standard_icon_label {
  }

  .theme_bold {
    .continaer_icon_label {
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
  }

  .theme_sidebar {
    .icon_front {
      margin-right: 22px;
    }
    .continaer_icon_label {
      padding: 0 8px;
      height: 52px;
      background: ${({ active }) => (active ? '#0062ff0d' : 'transparent')};
    }
  }
`;
