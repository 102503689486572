import styled from 'styled-components';

export const InputLineBottomStyled = styled.div`
  .input_line_bottom_container {
    .input_line_bottom_label {
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
    .input_line_bottom {
      width: calc(100% - 4px);
      height: 34px;
      padding: 2px;
      border: none;
      border-bottom: 1px solid rgba(160, 160, 160, 0.2);
      &:focus {
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.GREEN_1};
      }
    }
  }
  .error {
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    margin-top: 0.4rem;
    text-align: right;
  }

  .theme_standard {
  }
`;
