import styled from 'styled-components';

export const DashboardExportContainerStyled = styled.div`
  background: white;
  padding: 10px;
  height: 100%;
  overflow: auto;
  .loading_show {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn_action {
    display: flex;
    column-gap: 10px;
    margin-bottom: 27px;
    .btn175 {
      width: 175px;
    }
  }
`;
