import React from 'react';
import { ProductRegisterContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { retailerService } from 'apiServices';
import moment from 'moment';
import 'moment-timezone';
import { RenderSortTable } from 'utils/functions/SortTable';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import address from 'utils/jsons/thailandAddress/address.json';
import {
  Inputs,
  Buttons,
  Icons,
  Tables,
  Typhographys,
  Displays,
} from 'components';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class ProductRegisterContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    filterQuery: null,
    perPage: 10,
    isLoadingTable: true,
    sortTable: {
      shop_no: 1,
      serial_no: 1,
      created_at: 1,
      created_at_time: 1,
      car_plate: 1,
      province: 1,
      car_brand: 1,
      car_model: 1,
      product_type: 1,
      install_position: 1,
      product_model: 1,
      car_year: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchFilter();
    this.fetchData(
      `?limit=${perPage}&memberType=1&start_date=${moment().format(
        'YYYY-MM-DD'
      )}&end_date=${moment().format('YYYY-MM-DD')}`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_PRODUCT_REGISTED_LIST(stringQuery);
    if (res && res.status === 200) {
      let tempData = [];
      res.data.docs.map((e) =>
        tempData.push({
          ...e,
          created_at_time: moment(e.created_at)
            .tz('Asia/Bangkok')
            .format('HH:mm'),
        })
      );

      this.setState({
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  fetchFilter = async () => {
    let resCarBand = await retailerService.GET_PRODUCT_CAR_BAND_LIST();

    if (resCarBand && resCarBand.status === 200) {
      let tmpCardBand = resCarBand.data.map((e) => ({
        label: e._id,
        value: e._id,
      }));

      tmpCardBand.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      this.setState({
        dataCarBand: tmpCardBand,
      });
    }
  };

  handleClickSN = (e) => {
    this.props.router.navigate(
      `${RETAILER_ROUTE_PATH.PRODUCT_REGISTER_DATA}/${e}`
    );
  };

  handleClickStore = (e) => {
    this.props.router.navigate(`${RETAILER_ROUTE_PATH.USER_DATA}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;

    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleFilterSelect = (e) => {
    const { perPage } = this.state;

    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&memberType=1`
      : `?limit=${per_page}&page=${page}&memberType=1`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;

    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case 'shop_no':
          sortValue = RenderSortTable(e, data, sortTable.shop_no);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shop_no: sortValue.sortNumber,
            },
          });

          break;

        case 'serial_no':
          sortValue = RenderSortTable(e, data, sortTable.serial_no);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              serial_no: sortValue.sortNumber,
            },
          });

          break;

        case 'car_plate':
          sortValue = RenderSortTable(e, data, sortTable.car_plate);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              car_plate: sortValue.sortNumber,
            },
          });

          break;

        case 'product_model':
          sortValue = RenderSortTable(e, data, sortTable.product_model);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              product_model: sortValue.sortNumber,
            },
          });

          break;

        case 'car_year':
          sortValue = RenderSortTable(e, data, sortTable.car_year);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              car_year: sortValue.sortNumber,
            },
          });

          break;

        case 'car_brand':
          sortValue = RenderSortTable(e, data, sortTable.car_brand);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              car_brand: sortValue.sortNumber,
            },
          });
          break;

        case 'province':
          sortValue = RenderSortTable(e, data, sortTable.province);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              province: sortValue.sortNumber,
            },
          });
          break;

        case 'install_position':
          sortValue = RenderSortTable(e, data, sortTable.install_position);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              install_position: sortValue.sortNumber,
            },
          });
          break;

        case 'car_model':
          sortValue = RenderSortTable(e, data, sortTable.car_model);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              car_model: sortValue.sortNumber,
            },
          });
          break;

        case 'product_type':
          sortValue = RenderSortTable(e, data, sortTable.product_type);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              product_type: sortValue.sortNumber,
            },
          });
          break;

        case 'created_at':
          sortValue = RenderSortTable(e, data, sortTable.created_at);

          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber,
            },
          });
          break;

        case 'created_at_time':
          sortValue = RenderSortTable(e, data, sortTable.created_at_time);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at_time: sortValue.sortNumber,
            },
          });
          break;

        default:
          break;
      }
    }
  };

  handleDownloadExcel = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await retailerService.GET_PRODUCT_REGISTED_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = 'SheetJS';
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
          e.created_at_time,
          e.serial_no,
          e.car_plate,
          e.retailer_detail.shop_no,
          e.retailer_detail.province,
          e.car_brand,
          e.car_model,
          e.car_year,
          e.product_type,
          e.install_position,
          e.product_model,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'วันที่ลงทะเบียน',
        'เวลา',
        'เลข S/N',
        'ทะเบียนรถ',
        'รหัสร้านค้า',
        'จังหวัด',
        'ยี่ห้อรถ',
        'รุ่นรถ',
        'ปี',
        'ประเภทโช้คอัพ',
        'ตำแหน่งโช้คอัพ',
        'รุ่นสินค้า',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 16 },
        { wch: 16 },
        { wch: 17 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 15 },
        { wch: 25 },
        { wch: 30 },
        { wch: 25 },
        { wch: 15 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Retailer Product register report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('download fail');
    }
  };

  render() {
    const {
      isLoading,
      isBgLoading,
      data,
      pagination,
      isLoadingTable,
      dataCarBand,
    } = this.state;
    return (
      <ProductRegisterContainerStyled>
        <Displays.BgLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="w200">
              <div className="title_show">
                <Typhographys.HeaderH1 label="Product register data" />
              </div>
              <div className="title_show">
                <Inputs.InputFilter
                  theme_standard_input_filter
                  dataFilter={dataFilter({ dataCarBand: dataCarBand })}
                  btnLabel="เลือก"
                  onQueryFilter={this.handleFilterSelect}
                />
              </div>
              <div className="opt_zone">
                <Inputs.InputDropDownFilter
                  placeholder="เลือก"
                  iconSvgClose={<Icons.ArrowDropDown />}
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  onChange={this.handleSelectedPerPage}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 20 แถว', value: '20' },
                    { label: 'แสดง 30 แถว', value: '30' },
                    { label: 'แสดง 40 แถว', value: '40' },
                    { label: 'แสดง 50 แถว', value: '50' },
                  ]}
                />
                <div className="btnw">
                  <Buttons.BtnNormal
                    theme_normal_btn
                    label="Export"
                    onClick={this.handleDownloadExcel}
                  />
                </div>
              </div>
              <div className="title_show">
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={data}
                  pagination={pagination}
                  isLoading={isLoadingTable}
                  handlePageClick={this.handlePageClick}
                  columns={columns7({
                    onClickSN: this.handleClickSN,
                    onClickStore: this.handleClickStore,
                    onSort: this.handleSort,
                  })}
                />
              </div>
            </div>
          </>
        )}
      </ProductRegisterContainerStyled>
    );
  }
}

const dataFilter = ({ dataCarBand }) => [
  {
    accessor: 'dateBy',
    placeHolder: 'ระยะเวลา',
    filter: {
      fieldQuery: 'types',
      type: 'dateGroup',
      placeholder: 'วันนี้',
      options: [
        { label: 'วันนี้', value: 'day' },
        { label: 'เดือนนี้', value: 'month' },
        { label: 'ไตรมาส', value: 'quarter' },
        { label: 'ระหว่างวันที่', value: 'date_range' },
      ],
    },
  },
  {
    accessor: 'carBy',
    placeHolder: 'ยี่ห้อ',
    length: '176px',
    filter: {
      fieldQuery: 'carBrand',
      type: 'dropdown',
      options: dataCarBand && dataCarBand,
    },
  },
  {
    accessor: 'provinceBy',
    placeHolder: 'จังหวัด',
    length: '162px',
    filter: {
      fieldQuery: 'province',
      type: 'dropdown',
      options: address.reduce((result, data, i) => {
        if (i === 0) {
          result.push({ label: data.province, value: data.province });
        } else {
          let find = result.find((e) => e.value === data.province);
          if (!find) {
            result.push({ label: data.province, value: data.province });
          }
        }
        return result;
      }, []),
    },
  },
  {
    accessor: 'serial_no',
    length: '260px',
    filter: {
      fieldQuery: 'serial_no',
      type: 'text',
      placeholder: 'ค้นหารหัส serial no',
    },
  },
  // {
  //   accessor: 'carBy',
  //   placeHolder: 'ยี่ห้อรถ',
  //   length: '162px',
  //   filter: {
  //     fieldQuery: 'car',
  //     type: 'dropdown',
  //     options: [
  //       { label: 'car 1', value: '1' },
  //       { label: 'car 2', value: '2' },
  //     ],
  //   },
  // },
  // {
  //   accessor: 'genBy',
  //   placeHolder: 'รุ่น',
  //   length: '150px',
  //   filter: {
  //     fieldQuery: 'gen',
  //     type: 'dropdown',
  //     options: [
  //       { label: 'รุ่น 1', value: '1' },
  //       { label: 'รุ่น 2', value: '2' },
  //     ],
  //   },
  // },
  // {
  //   accessor: 'yearBy',
  //   placeHolder: 'ปี',
  //   length: '150px',
  //   filter: {
  //     fieldQuery: 'year',
  //     type: 'dropdown',
  //     options: [
  //       { label: '2565', value: '1' },
  //       { label: '2564', value: '2' },
  //     ],
  //   },
  // },
  // {
  //   accessor: 'type1',
  //   placeHolder: 'ประเภทโช้คอัพ',
  //   length: '170px',
  //   filter: {
  //     fieldQuery: 'type1',
  //     type: 'dropdown',
  //     options: [
  //       { label: 'โช้คอัพ 1', value: '1' },
  //       { label: 'โช้คอัพ 2', value: '2' },
  //     ],
  //   },
  // },
  // {
  //   accessor: 'type1',
  //   placeHolder: 'ตำแหน่งโช้ค',
  //   length: '170px',
  //   filter: {
  //     fieldQuery: 'type2',
  //     type: 'dropdown',
  //     options: [
  //       { label: 'ตำแหน่งโช้ค 1', value: '1' },
  //       { label: 'ตำแหน่งโช้ค 2', value: '2' },
  //     ],
  //   },
  // },
  // {
  //   accessor: 'type2',
  //   placeHolder: 'รุ่นสินค้า',
  //   length: '170px',
  //   filter: {
  //     fieldQuery: 'type2',
  //     type: 'dropdown',
  //     options: [
  //       { label: 'รุ่นสินค้า 1', value: '1' },
  //       { label: 'รุ่นสินค้า 2', value: '2' },
  //     ],
  //   },
  // },
];

const columns7 = ({ onClickSN, onClickStore, onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div> {`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เวลา
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at_time')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at_time',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลข S/N
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('serial_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'serial_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{ textDecoration: 'underline' }}
        onClick={() => onClickSN(origin._id)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ทะเบียนรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_plate')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_plate',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shop_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'retailer_detail.shop_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{ textDecoration: 'underline' }}
        onClick={() => onClickStore(origin.retailer_ref_id)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('province')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'retailer_detail.province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ยี่ห้อรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_brand')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_brand',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_model')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_model',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ปี
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_year')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_year',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทโช้คอัพ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('product_type')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_type',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ตำแหน่งโช้คอัพ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('install_position')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'install_position',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นสินค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('product_model')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_model',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
];

export default withRouter(ProductRegisterContainer);
