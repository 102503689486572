import React from 'react';
import { RedemptionSuccessBottomDetailContainerStyled } from './styled';
import { Tables, Icons, Inputs, Buttons, Displays } from 'components';
import { retailerService } from 'apiServices';
import moment from 'moment';
import 'moment-timezone';
import { withRouter } from 'navigations/withRouter';
import { RenderStatusName } from 'utils/functions/StatusReward';
import { RenderSortTable } from 'utils/functions/SortTable';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class RedemptionSuccessBottomDetailContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    isLoadingTable: true,
    perPage: 10,
    sortTable: {
      shop_no: 1,
      name_shipping: 1,
      phone_number_shipping: 1,
      address_province: 1,
      created_at: 1,
      shipping_date: 1,
      status: 1,
      shipping_brand: 1,
      shipping_ref_no: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(
      `?limit=${perPage}&memberType=1&status_key=success&reward_ref_id=${this.props.router.params.id}`
    );
  }

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_REWARD_HISTORY_LIST(stringQuery);

    if (res && res.status === 200) {
      let tempData = [];
      res.data.docs.forEach((e) => {
        tempData.push({
          ...e,
          name_shipping: e.firstname_shipping
            ? `${e.firstname_shipping} ${e.lastname_shipping}`
            : '',
        });
      });
      this.setState({
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;

    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleFilterSelect = (e) => {
    const { perPage } = this.state;

    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&memberType=1&status_key=success&reward_ref_id=${this.props.router.params.id}`
      : `?limit=${per_page}&page=${page}&memberType=1&status_key=success&reward_ref_id=${this.props.router.params.id}`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;

    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case 'shop_no':
          sortValue = RenderSortTable(e, data, sortTable.shop_no);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shop_no: sortValue.sortNumber,
            },
          });

          break;

        case 'name_shipping':
          sortValue = RenderSortTable(e, data, sortTable.name_shipping);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              name_shipping: sortValue.sortNumber,
            },
          });

          break;

        case 'phone_number_shipping':
          sortValue = RenderSortTable(e, data, sortTable.phone_number_shipping);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              phone_number_shipping: sortValue.sortNumber,
            },
          });

          break;

        case 'address_province':
          sortValue = RenderSortTable(e, data, sortTable.address_province);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              address_province: sortValue.sortNumber,
            },
          });

          break;

        case 'created_at':
          sortValue = RenderSortTable(e, data, sortTable.created_at);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber,
            },
          });

          break;

        case 'status':
          sortValue = RenderSortTable(e, data, sortTable.status);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              status: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_date':
          sortValue = RenderSortTable(e, data, sortTable.shipping_date);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shipping_date: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_brand':
          sortValue = RenderSortTable(e, data, sortTable.shipping_brand);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shipping_brand: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_ref_no':
          sortValue = RenderSortTable(e, data, sortTable.shipping_ref_no);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shipping_ref_no: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  handleDownloadExcel = async () => {
    const { data } = this.state;
    var wb = XLSX.utils.book_new();
    var ws_name = 'SheetJS';
    if (data) {
      /* make worksheet */
      var ws_data = data.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.retailer_detail.shop_no,
          e.name_shipping,
          e.phone_number_shipping,
          e.address_province,
          `จ.${e.address_province} อ.${e.address_district} ต.${e.address_sub_district} ${e.address_zipcode} ${e.address}`,
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
          e.shipping_date
            ? moment(e.shipping_date).tz('Asia/Bangkok').format('DD/MM/YYYY')
            : '',
          e.shipping_brand,
          RenderStatusName(e.status),
          e.shipping_ref_no,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'ชิ้นที่',
        'รหัสร้านค้า',
        'ผู้ที่ได้รับรางวัล',
        'เบอร์โทร',
        'จังหวัด',
        'ที่อยู่จัดส่ง',
        'วันที่ทำรายการ',
        'วันที่จัดส่ง',
        'บ. ที่จัดส่ง',
        'สถานะ',
        'เลขที่ส่ง',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 55 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Retailer Redemption on success report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
    }
  };

  render() {
    const { isLoading, data, pagination, isLoadingTable } = this.state;
    const { dataShop } = this.props;

    return (
      <RedemptionSuccessBottomDetailContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Redemption success</div>
            <div className="title_show">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter4}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterSelect}
              />
            </div>
            <div className="opt_zone2">
              <div className="labl_zone">{`ชื่อของรางวัล:  ${dataShop}`}</div>
              <div className="opt_select">
                <div className="drop">
                  <Inputs.InputDropDownFilter
                    placeholder="เลือก"
                    iconSvgClose={<Icons.ArrowDropDown />}
                    iconSvgOpen={<Icons.ArrowDropDown up />}
                    onChange={this.handleSelectedPerPage}
                    options={[
                      { label: 'แสดง 10 แถว', value: '10' },
                      { label: 'แสดง 20 แถว', value: '20' },
                      { label: 'แสดง 30 แถว', value: '30' },
                      { label: 'แสดง 40 แถว', value: '40' },
                      { label: 'แสดง 50 แถว', value: '50' },
                    ]}
                  />
                </div>
                <div className="btnw">
                  <Buttons.BtnNormal
                    theme_normal_btn
                    label="Export"
                    onClick={this.handleDownloadExcel}
                  />
                </div>
              </div>
            </div>
            <div className="title_show">
              <Tables.TableNormal
                theme_standard_table_normal
                showPagination
                useNo
                data={data}
                pagination={pagination}
                isLoading={isLoadingTable}
                columns={columns14({
                  onSort: this.handleSort,
                })}
              />
            </div>
          </>
        )}
      </RedemptionSuccessBottomDetailContainerStyled>
    );
  }
}

const dataFilter4 = [
  {
    accessor: 'nameBy',
    length: '260px',
    filter: {
      fieldQuery: 'firstname_shipping',
      type: 'text',
      placeholder: 'ค้นหาชื่อ',
    },
  },
  {
    accessor: 'phoneBy',
    length: '260px',
    filter: {
      fieldQuery: 'phone_number_shipping',
      type: 'text',
      placeholder: 'ค้นหาเบอร์โทร',
    },
  },
  {
    accessor: 'provinceBy',
    length: '260px',
    filter: {
      fieldQuery: 'address_province',
      type: 'text',
      placeholder: 'ค้นหาจังหวัด',
    },
  },
];

const columns14 = ({ onSort }) => [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shop_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'retailer_detail.shop_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>{value}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('name_shipping')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name_shipping',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('phone_number_shipping')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone_number_shipping',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('address_province')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address_province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ที่อยู่จัดส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('address_province')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{`จ.${origin.address_province} อ.${origin.address_district} ต.${origin.address_sub_district} ${origin.address_zipcode} ${origin.address}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่จัดส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_date')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_date',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>
        {value && `${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_brand')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_brand',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        สถานะ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('status')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'status',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{
          color:
            value === '4' ? '#30BC76' : value === '1' ? '#ff0000' : '#000000',
        }}
      >
        {RenderStatusName(value)}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_ref_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_ref_no',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
];

const mapStateToProps = (state) => ({
  dataShop: state.dataShop,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedemptionSuccessBottomDetailContainer));
