import BgLoading from './BgLoading';
import IconLabel from './IconLabel';
import TitleSwitchLabel from './TitleSwitchLabel';
import LeftRightLabel from './LeftRightLabel';
import Profile from './Profile';
import PaginationCustom from './PaginationCustom';
import NoOff from './NoOff';
import Loading from './Loading';
import SkeletonLoad from './SkeletonLoad';

const Export = {
  BgLoading,
  IconLabel,
  TitleSwitchLabel,
  LeftRightLabel,
  Profile,
  PaginationCustom,
  NoOff,
  Loading,
  SkeletonLoad,
};

export default Export;
