import React from 'react';
import { PrizesSortContainerStyled } from './styled';
import { Draggable } from 'react-drag-reorder';
import { retailerService } from 'apiServices';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { toast } from 'react-toastify';
import { Buttons, Typhographys } from 'components';

class PrizesSortContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData(`?pagination=false`);
  }

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_REWARD_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
      });
    }
  };

  handleChangedPosition = async (currentPos, newPos) => {
    const { data } = this.state;
    let tempData = [...data];
    let element = tempData[currentPos];
    tempData.splice(currentPos, 1);
    tempData.splice(newPos, 0, element);
    this.setState({
      data: tempData,
    });
  };

  handleClickSave = async () => {
    const { data } = this.state;
    let resArr = await Promise.all(
      data.map((e, i) => {
        return new Promise(async (resolve) => {
          let params = {
            sequence: i + 1,
          };
          let res = await retailerService.PUT_REWARD(e._id, params);
          if (res && res.status === 200) {
            resolve(res);
          }
        });
      })
    );
    if (resArr) {
      toast.success('สลับตำแหน่งสำเร็จ');
      this.props.onSortSuccess();
    }
  };

  render() {
    const { data } = this.state;
    return (
      <PrizesSortContainerStyled>
        <div className="prize_title">
          <Typhographys.HeaderH1 label={'Reward Sort'} />
          <div className="btn_wrap">
            <Buttons.BtnNormal
              label={'บันทึก'}
              onClick={this.handleClickSave}
            />
          </div>
        </div>
        <div className="prize_sort_body">
          {data && (
            <Draggable onPosChange={this.handleChangedPosition}>
              {data.map((e, i) => (
                <div key={i} className="item_wrap">
                  <div className="ps_label">{`${e.name}`}</div>
                  <div className="ps_value">
                    {RenderCommaMoney(e.redeem_point)} แต้ม
                  </div>
                </div>
              ))}
            </Draggable>
          )}
        </div>
      </PrizesSortContainerStyled>
    );
  }
}

export default PrizesSortContainer;
