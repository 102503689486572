import styled from "styled-components";

export const BackgroundContainerStyled = styled.div`
  .loading_show {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title_show {
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    margin-bottom: 17px;
    &.mb37 {
      margin-bottom: 37px;
    }
  }
`;
