import { default as InputDatePikerCustom } from './InputDatePikerCustom';
import { default as InputFilter } from './InputFilter';
import { default as InputDropDownFilter } from './InputDropDownFilter';
import { default as InputLineBottom } from './InputLineBottom';
import { default as InputTextField } from './InputTextField';
import { default as InputTextArea } from './InputTextArea';
import { default as UploadImage } from './UploadImage';
import { default as AvatarUpload } from './AvatarUpload';
import { default as InputSwitch } from './InputSwitch';
import { default as DateGroup } from './DateGroup';
import { default as InputDatePickerBetween } from './InputDatePickerBetween';
import { default as Dropdown } from './Dropdown';
import { default as InputDropDown } from './InputDropDown';

const EXPORT = {
  InputDatePikerCustom,
  InputSwitch,
  InputFilter,
  InputDropDownFilter,
  InputDropDown,
  InputLineBottom,
  InputTextField,
  InputTextArea,
  UploadImage,
  AvatarUpload,
  DateGroup,
  Dropdown,
  InputDatePickerBetween,
};

export default EXPORT;
