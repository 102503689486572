import styled from 'styled-components'

export const ConsumerFormStyled = styled.div`
  .ratailer_container {
    .btn_zone {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      .btn175 {
        width: 175px;
      }
      .btn218 {
        width: 218px;
      }
    }
    .title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 15px;
    }
    .sub_title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 10px;
        text-decoration: underline;
    }
    .box_sub_detail {
        display: flex;
        margin-bottom: 28px;
        .group_box {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            &.mr200 {
                margin-right: 200px;
            }
            &.gap {
              row-gap: 19px;
            }
            .line_box {
                display: flex;
                align-items: center;
                .left_label {
                    width: 160px;
                    margin-right: 20px;
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
                    color: ${({ theme }) => theme.COLORS.BLACK_1};
                }
                .right_label {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                    color: ${({ theme }) => theme.COLORS.BLACK_1};
                    &.w57 {
                        width: 57%;
                    }
                    .group_accept {
                        display: flex;
                        column-gap: 3px;
                        .accept {
                            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
                            color: ${({ theme }) => theme.COLORS.GREEN_2};
                        }
                        .no_accept {
                            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                            color: ${({ theme }) => theme.COLORS.BLACK_1};
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .table_zone {
        .title_table_zone {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            text-decoration: underline;
            margin-bottom: 10px;
        }
    }
  }
`
