import styled from 'styled-components';

export const RedemptionOnProcessContainerStyled = styled.div`
    .loading_show {
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 17px;
    }
    .opt_zone {
        display: flex;
        margin-top: 34px;
        margin-bottom: 8px;
        column-gap: 10px;
        .btnw {
            width: 174px;
        }
    }
    .opt_zone2 {
        margin-top: 19px;
        margin-bottom: 15px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        .labl_zone {
            margin-bottom: 18px;
        }
        .opt_select {
            display: flex;
            column-gap: 10px;
            .drop {
                width: 212px;
            }
            .btnw {
                width: 174px;
            }
        }
    }
`;
