import { default as Graph } from './Graph';
import { default as Home } from './Home';
import { default as Chat } from './Chat';
import { default as Papper } from './Papper';
import { default as ArrowDown } from './ArrowDown';
import { default as ArrowDropDown } from './ArrowDropDown';
import { default as Calendar } from './Calendar';
import { default as ArrowSort } from './ArrowSort';
import { default as ArrowLeftRight } from './ArrowLeftRight';
import { default as Plus } from './Plus';
import { default as AvatarEdit } from './AvatarEdit';

const EXPORT = {
  AvatarEdit,
  Graph,
  Home,
  Chat,
  Papper,
  ArrowDown,
  ArrowDropDown,
  Calendar,
  ArrowSort,
  ArrowLeftRight,
  Plus,
};

export default EXPORT;
