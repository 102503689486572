export const SHIPPING_OPTIONS = [
  {
    label: 'Kerry',
    value: 'Kerry',
    phoneNumber: '0736475645',
    link: 'https://www.google.co.th',
  },
  {
    label: 'Flash',
    value: 'Flash',
    phoneNumber: '0736475645',
    link: 'https://www.google.co.th',
  },
  {
    label: 'SCG',
    value: 'SCG',
    phoneNumber: '0736475645',
    link: 'https://www.google.co.th',
  },
];
