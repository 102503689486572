import React from 'react';
import { UserDataContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { retailerService } from 'apiServices';
import moment from 'moment';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { RenderTypeName } from 'utils/functions/TypeName';
import { RenderSortTable } from 'utils/functions/SortTable';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import {
  Tables,
  Icons,
  Inputs,
  Typhographys,
  Displays,
  Buttons,
} from 'components';

class UserDataContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    filterQuery: null,
    isLoadingTable: true,
    perPage: 10,
    sortTable: {
      shop_no: 1,
      store_name: 1,
      type: 1,
      phone_number: 1,
      province: 1,
      tax_id: 1,
      created_at: 1,
      created_at_time: 1,
      reward_point: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    this.fetchData(`?limit=${perPage}`);
    scrollToTopPageFN();
  }

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_RETAILER_LIST(stringQuery);
    if (res && res.status === 200) {
      let tempData = [];
      res.data.docs.map((e) =>
        tempData.push({
          ...e,
          reward_point: e.reward_point ? e.reward_point : 0,
          created_at_time: moment(e.created_at)
            .tz('Asia/Bangkok')
            .format('HH:mm'),
        })
      );
      this.setState({
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;

    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;

    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleClickItem = (e) => {
    this.props.router.navigate(`${RETAILER_ROUTE_PATH.USER_DATA}/${e}`);
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;

    if (data && data.length > 0) {
      let sortValue;

      switch (e) {
        case 'shop_no_retailer':
          sortValue = RenderSortTable(e, data, sortTable.shop_no);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              shop_no: sortValue.sortNumber,
            },
          });
          break;

        case 'store_name':
          sortValue = RenderSortTable(e, data, sortTable.store_name);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              store_name: sortValue.sortNumber,
            },
          });
          break;

        case 'shop_type':
          sortValue = RenderSortTable(e, data, sortTable.type);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              type: sortValue.sortNumber,
            },
          });
          break;

        case 'phone_number':
          sortValue = RenderSortTable(e, data, sortTable.phone_number);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              phone_number: sortValue.sortNumber,
            },
          });
          break;

        case 'province_retailer':
          sortValue = RenderSortTable(e, data, sortTable.province);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              province: sortValue.sortNumber,
            },
          });
          break;

        case 'tax_id':
          sortValue = RenderSortTable(e, data, sortTable.tax_id);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              tax_id: sortValue.sortNumber,
            },
          });
          break;

        case 'created_at':
          sortValue = RenderSortTable(e, data, sortTable.created_at);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber,
            },
          });
          break;

        case 'created_at_time':
          sortValue = RenderSortTable(e, data, sortTable.created_at_time);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at_time: sortValue.sortNumber,
            },
          });
          break;

        case 'reward_point':
          sortValue = RenderSortTable(e, data, sortTable.reward_point);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              reward_point: sortValue.sortNumber,
            },
          });
          break;

        default:
          break;
      }
    }
  };

  handleDownloadExcel = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await retailerService.GET_RETAILER_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = 'SheetJS';
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.shop_no,
          e.store_name,
          RenderTypeName(e.type),
          e.phone_number,
          e.province,
          e.district,
          e.sub_district,
          e.tax_id,
          RenderCommaMoney(Number(e.reward_point), true),
          // RenderCommaMoney(Number(e.prize_wheel_quota), true),
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
          moment(e.created_at).tz('Asia/Bangkok').format('HH:mm'),
          moment(e.updated_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'รหัสร้านค้า',
        'ชื่อร้านค้า',
        'ประเภทร้าน',
        'เบอร์โทร',
        'จังหวัด',
        'ตำบล',
        'อำเภอ',
        'เลขภาษี/บัตร',
        'แต้ม',
        'สิทธ์คงเหลือ',
        'วันที่ลงทะเบียน',
        'เวลา',
        'วันที่อัพเดตข้อมูล',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 16 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 17 },
        { wch: 17 },
        { wch: 17 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Retailer report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('download fail');
    }
  };

  render() {
    const { isLoading, isBgLoading, data, pagination, isLoadingTable } =
      this.state;
    return (
      <UserDataContainerStyled>
        <Displays.BgLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">
              <Typhographys.HeaderH1 label="Retailer data" />
            </div>
            <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter2}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
              />
            </div>
            <div className="export_zone">
              <Inputs.InputDropDownFilter
                placeholder="เลือก"
                iconSvgClose={<Icons.ArrowDropDown />}
                iconSvgOpen={<Icons.ArrowDropDown up />}
                onChange={this.handleSelectedPerPage}
                options={[
                  { label: 'แสดง 10 แถว', value: '10' },
                  { label: 'แสดง 20 แถว', value: '20' },
                  { label: 'แสดง 30 แถว', value: '30' },
                  { label: 'แสดง 40 แถว', value: '40' },
                  { label: 'แสดง 50 แถว', value: '50' },
                ]}
              />
              <div className="btnw">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="Export"
                  onClick={this.handleDownloadExcel}
                />
              </div>
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              // useRenderNoPage
              data={data}
              isLoading={isLoadingTable}
              pagination={pagination}
              handlePageClick={this.handlePageClick}
              columns={columns3({
                onClickItem: this.handleClickItem,
                onSort: this.handleSort,
              })}
            />
          </>
        )}
      </UserDataContainerStyled>
    );
  }
}

const dataFilter2 = [
  {
    accessor: 'shop_no',
    length: '260px',
    filter: {
      fieldQuery: 'shop_no',
      type: 'text',
      placeholder: 'ค้นหารหัสร้านค้า',
    },
  },
  {
    accessor: 'store_name',
    length: '260px',
    filter: {
      fieldQuery: 'store_name',
      type: 'text',
      placeholder: 'ค้นหาชื่อร้านค้า',
    },
  },
  {
    accessor: 'tax_id',
    length: '374px',
    filter: {
      fieldQuery: 'tax_id',
      type: 'text',
      placeholder: 'ค้นหาเลขผู้เสียภาษี/บัตรประชาชน',
    },
  },
];

const columns3 = ({ onClickItem, onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shop_no_retailer')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shop_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div
        style={{ textDecoration: 'underline' }}
        onClick={() => onClickItem(origin._id)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อร้านค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('store_name')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'store_name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทร้าน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shop_type')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{RenderTypeName(value)}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('phone_number')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone_number',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('province_retailer')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขภาษี/บัตร
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('tax_id')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'tax_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        แต้ม
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('reward_point')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'reward_point',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{RenderCommaMoney(Number(value), true)}</div>
    ),
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       สิทธ์คงเหลือ
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'prize_wheel_quota',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'left' },
  //   render: ({ origin, value }) => (
  //     <div>{RenderCommaMoney(Number(value), true)}</div>
  //   ),
  // },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{moment(value).format('DD/MM/YYYY')}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เวลา
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at_time')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at_time',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่อัพเดตข้อมูล
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('updated_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'updated_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{moment(value).format('DD/MM/YYYY')}</div>
    ),
  },
];

export default withRouter(UserDataContainer);
