import React, { useEffect } from "react";
import { BackgroundUploadFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const BackgroundUploadForm = ({ initialValues, onSubmit }) => {
  const schema = yup.object().shape({
    // test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      if (initialValues.bg_img_path) {
        reset({
          bg_img_file: [{ image_uri: initialValues.bg_img_path }],
        });
      }
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BackgroundUploadFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_form">
          <div className="left_form">
            รูปพื้นหลัง
            <div className="label_desc">
              ไฟล์ มีขนาดไม่ต่ำกว่า 390 x 760 pixel
            </div>
          </div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  theme_standard_upload_image
                  isDelete
                  acceptType="cover"
                  fieldName="bg_img"
                  maxMBFileSizeWarning="1"
                  {...field}
                />
              )}
              name="bg_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="btn_action_price">
          <div className="btn175">
            <Buttons.BtnNormal theme_normal_btn label="ยืนยัน" type="submit" />
          </div>
        </div>
      </form>
    </BackgroundUploadFormStyled>
  );
};

BackgroundUploadForm.propTypes = {};

export default BackgroundUploadForm;
