import styled from 'styled-components';

export const RedemptionOnProcessBottomDetailEditContainerStyled = styled.div`
    width: 450px;
    padding: 10px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 17px;
    }
    .sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        margin-bottom: 10px;
    }
`;
