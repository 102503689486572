import React from 'react';
import { WheelUpdateContainerStyled } from './styled';
import { Forms, Displays } from 'components';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { retailerService } from 'apiServices';
// import moment from 'moment';
// import { IMG_PATH } from 'utils/constants/imgPath';
import { toast } from 'react-toastify';
import { AlertControl } from 'components/Modals/Alert';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class WheelUpdateContainer extends React.Component {
  state = {
    isLoading: true,
    disabled: false,
    initialValues: null,
    labelSucces:
      this.props.router.params.id !== 'create'
        ? 'Update success'
        : 'Add success',
    labelFailed:
      this.props.router.params.id !== 'create' ? 'Update failed' : 'Add failed',
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  fetchData = async () => {
    let res = await retailerService.GET_WHEEL_GAME_REWARD_DETAIL(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleBack = () => {
    this.props.router.navigate(CONSUMER_ROUTE_PATH.WHEEL_GAME_PRIZES);
  };

  handleSubmit = async (values) => {
    const { labelSucces, labelFailed, initialValues } = this.state;

    let params = {
      name: values.name,
      description: values.description,
      memo: values.memo,
      redeem_point: values.redeem_point,
      total_amount: values.total_amount,
      is_active: values.is_active,
      use_condition: '3',
      // reward_type: '2',
      reward_type: values.reward_type.value,
      reward_cate: values.reward_cate.value,
      frequency: values.frequency,
    };

    if (initialValues) {
      params.total_amount = values.total_amount + values.add_number;
    }

    this.setState({
      disabled: true,
    });
    if (this.props.router.params.id === 'create') {
      let res = await retailerService.POST_WHEEL_GAME_REWARD(params);

      if (res && res.status === 200) {
        if (
          !values.img_path ||
          values.img_path.length === 0 ||
          !values.img_path[0].isNewUpload
        ) {
          toast.success(labelSucces);
          this.handleBack();
        } else {
          this.handleUpload(res.data._id, values.img_path);
        }
      } else {
        toast.error(labelFailed);
      }
    } else {
      let res = await retailerService.PUT_WHEEL_GAME_REWARD(
        this.props.router.params.id,
        params
      );
      if (res && res.status === 200) {
        if (
          !values.img_path ||
          values.img_path.length === 0 ||
          !values.img_path[0].isNewUpload
        ) {
          toast.success(labelSucces);
          this.handleBack();
        } else {
          this.handleUpload(this.props.router.params.id, values.img_path);
        }
      } else {
        toast.error(labelFailed);
      }
    }
  };
  handleUpload = async (id, files) => {
    let bodyFormData = new FormData();
    bodyFormData.set('_id', id);
    bodyFormData.append('image', files[0]);
    let res = await retailerService.POST_WHEEL_GAME_UPLOAD_REWARD(bodyFormData);
    if (res && res.status === 200) {
      toast.success('Update success');
      this.handleBack();
    } else {
      toast.error('Upload fail');
    }
  };

  handleDelete = () => {
    AlertControl.show({
      title: 'Confirm Delete',
      description: 'Do you want to delete it?',
      btnLabel1: 'Confirm',
      btnLabel2: 'Cancle',
      bgColor2: 'red',
      onEvent1: this.actionDelete,
    });
  };

  actionDelete = async () => {
    let res = await retailerService.DELETE_WHEEL_GAME_REWARD(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      toast.success('Delete success');
      this.handleBack();
    } else {
      toast.error('Delete Failed');
    }
  };

  render() {
    const { isLoading, initialValues, disabled } = this.state;
    return (
      <WheelUpdateContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Prizes</div>
            {/* <div className="label_zone">
              สร้างเมื่อวันที่ {` `}{' '}
              {
                initialValues
                  ? moment(initialValues.created_at).format('DD/MM/YYYY')
                  : moment().format('DD/MM/YYYY')
              }
            </div> */}
            <div className="title_show">
              <Forms.PrizeForm
                initialValues={initialValues}
                type="wheel"
                disabled={disabled}
                handleBack={this.handleBack}
                onSubmit={this.handleSubmit}
                // handleDelete={this.handleDelete}
              />
            </div>
          </>
        )}
      </WheelUpdateContainerStyled>
    );
  }
}

export default withRouter(WheelUpdateContainer);
