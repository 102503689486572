import React from 'react';
// import PropTypes from 'prop-types'
import { NavSidebarStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { HeaderBarWidget, SidebarWidget } from 'widgets';

const NavSidebar = () => {
  return (
    <NavSidebarStyled>
      <HeaderBarWidget />
      <div className="body_mainlayout">
        <div className="sidbar_wrap">
          <SidebarWidget />
        </div>
        <div className="content_mainlayout">
          <Outlet />
        </div>
      </div>
    </NavSidebarStyled>
  );
};

NavSidebar.propTypes = {};

export default NavSidebar;
