export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  COMPONENT: "/component",
  SYSTEM: "/system",
};

export const RETAILER_ROUTE_PATH = {
  SYSTEM: "/system/retailer_system",
  DASHBOARD: "/system/retailer_system/dashboard",
  USER_DATA: "/system/retailer_system/user_data",
  USER_DATA_DETAIL: "/system/retailer_system/user_data/:id",
  USER_DATA_EDIT: "/system/retailer_system/user_data/edit",
  USER_DATA_EDIT_DETAIL: "/system/retailer_system/user_data/edit/:id",
  PRODUCT_REGISTER_DATA: "/system/retailer_system/product_register",
  PRODUCT_REGISTER_DATA_DETAIL: "/system/retailer_system/product_register/:id",
  WHEEL_GAME_PRIZES: "/system/retailer_system/whell_game_prizes",
  WHEEL_GAME_PRIZES_DETAIL: "/system/retailer_system/whell_game_prizes/:id",
  WHEEL_GAME_PRIZES_UPDATE:
    "/system/retailer_system/whell_game_prizes/:id/update",
  REDEMPTION_STATISTIC: "/system/retailer_system/redemption_statistic",
  REDEMPTION_STATISTIC_DETAIL:
    "/system/retailer_system/redemption_statistic/:id",
  REDEMPTION_PROCESS: "/system/retailer_system/redemption_process",
  REDEMPTION_PROCESS_DETAIL: "/system/retailer_system/redemption_process/:id",
  REDEMPTION_SUCCESS: "/system/retailer_system/redemption_success",
  REDEMPTION_SUCCESS_DETAIL: "/system/retailer_system/redemption_success/:id",
  PRIZES: "/system/retailer_system/prizes",
  PRIZES_DETAIL: "/system/retailer_system/prizes/:id",
  PRIZES_UPDATE: "/system/retailer_system/prizes/:id/update",
  REDEMPTION_PROCESS_B: "/system/retailer_system/redemption_process_b",
  REDEMPTION_PROCESS_B_DETAIL:
    "/system/retailer_system/redemption_process_b/:id",
  REDEMPTION_SUCCESS_B: "/system/retailer_system/redemption_success_b",
  REDEMPTION_SUCCESS_B_DETAIL:
    "/system/retailer_system/redemption_success_b/:id",
  NEW: "/system/retailer_system/new",
  NEW_UPDATE: "/system/retailer_system/new/:id",
  NEW_EDIT: "/system/retailer_system/new/edit",
  NEW_EDIT_DETAIL: "/system/retailer_system/new/edit/:id",
  BANNER: "/system/retailer_system/banner",
  BANNER_UPDATE: "/system/retailer_system/banner/:id",
  BANNER_EDIT: "/system/retailer_system/banner/edit",
  BANNER_EDIT_DETAIL: "/system/retailer_system/banner/edit/:id",
  SYSTEM_CONFIG: "/system/retailer_system/system-config",
};

export const CONSUMER_ROUTE_PATH = {
  SYSTEM: "/system/consumer_system",
  DASHBOARD: "/system/consumer_system/dashboard",
  USER_DATA: "/system/consumer_system/user_data",
  USER_DATA_DETAIL: "/system/consumer_system/user_data/:id",
  USER_DATA_EDIT: "/system/consumer_system/user_data/edit",
  USER_DATA_EDIT_DETAIL: "/system/consumer_system/user_data/edit/:id",
  PRODUCT_REGISTER_DATA: "/system/consumer_system/product_register",
  PRODUCT_REGISTER_DATA_DETAIL: "/system/consumer_system/product_register/:id",
  WHEEL_GAME_PRIZES: "/system/consumer_system/whell_game_prizes",
  WHEEL_GAME_PRIZES_DETAIL: "/system/consumer_system/whell_game_prizes/:id",
  WHEEL_GAME_PRIZES_UPDATE:
    "/system/consumer_system/whell_game_prizes/:id/update",
  REDEMPTION_STATISTIC: "/system/consumer_system/redemption_statistic",
  REDEMPTION_STATISTIC_DETAIL:
    "/system/consumer_system/redemption_statistic/:id",
  REDEMPTION_PROCESS: "/system/consumer_system/redemption_process",
  REDEMPTION_PROCESS_DETAIL: "/system/consumer_system/redemption_process/:id",
  REDEMPTION_SUCCESS: "/system/consumer_system/redemption_success",
  REDEMPTION_SUCCESS_DETAIL: "/system/consumer_system/redemption_success/:id",
  PRIZES: "/system/consumer_system/prizes",
  PRIZES_DETAIL: "/system/consumer_system/prizes/:id",
  PRIZES_UPDATE: "/system/consumer_system/prizes/:id/update",
  REDEMPTION_PROCESS_B: "/system/consumer_system/redemption_process_b",
  REDEMPTION_PROCESS_B_DETAIL:
    "/system/consumer_system/redemption_process_b/:id",
  REDEMPTION_SUCCESS_B: "/system/consumer_system/redemption_success_b",
  REDEMPTION_SUCCESS_B_DETAIL:
    "/system/consumer_system/redemption_success_b/:id",
  NEW: "/system/consumer_system/new",
  NEW_UPDATE: "/system/consumer_system/new/:id",
  NEW_EDIT: "/system/consumer_system/new/edit",
  NEW_EDIT_DETAIL: "/system/consumer_system/new/edit/:id",
  BANNER: "/system/consumer_system/banner",
  BANNER_UPDATE: "/system/consumer_system/banner/:id",
  BANNER_EDIT: "/system/consumer_system/banner/edit",
  BANNER_EDIT_DETAIL: "/system/consumer_system/banner/edit/:id",
  SYSTEM_CONFIG: "/system/consumer_system/system-config",
};
