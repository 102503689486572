import { default as BackgroundUploadForm } from "./BackgroundUploadForm";
import { default as LoginForm } from "./LoginForm";
import { default as RetailerForm } from "./RetailerForm";
import { default as PrizeForm } from "./PrizeForm";
import { default as NewsForm } from "./NewsForm";
import { default as RedemptionSendForm } from "./RedemptionSendForm";
import { default as BannerForm } from "./BannerForm";
import { default as ConsumerForm } from "./ConsumerForm";

const EXPORT = {
  BackgroundUploadForm,
  LoginForm,
  RetailerForm,
  ConsumerForm,
  PrizeForm,
  NewsForm,
  RedemptionSendForm,
  BannerForm,
};

export default EXPORT;
