import styled from 'styled-components';

export const InputFilterStyled = styled.div`
  .theme_standard_input_filter {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    .box_filter {
      .label_top {
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.BLUE_3};
        margin-bottom: 9px;
      }
    }
  }

  .box_label {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    background: ${({ theme }) => theme.COLORS.GREEN_1}1A;
    border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    &.active {
      background: ${({ theme }) => theme.COLORS.GREEN_1}66;
    }
  }

  .btn_action_filter {
    height: 100%;
    width: 147px;
    flex-shrink: 0;
  }
`;
