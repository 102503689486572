export default function renderIcon({ width = "10", height = "6", color = '#a6adb4', up }) {
  if (up) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 6">
        <g id="dropdown" transform="translate(10 6) rotate(180)">
          <path id="dropdown-2" data-name="dropdown" d="M5,6a.908.908,0,0,1-.7-.3l-4-4A.967.967,0,0,1,.3.3.967.967,0,0,1,1.7.3L5,3.6,8.3.3A.967.967,0,0,1,9.7.3a.967.967,0,0,1,0,1.4l-4,4A.908.908,0,0,1,5,6Z" transform="translate(0 0)" fill={color} />
        </g>
      </svg>
    )
  } else {
    return (
      <svg id="dropdown" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 6">
        <path id="dropdown-2" data-name="dropdown" d="M5,6a.908.908,0,0,1-.7-.3l-4-4A.967.967,0,0,1,.3.3.967.967,0,0,1,1.7.3L5,3.6,8.3.3A.967.967,0,0,1,9.7.3a.967.967,0,0,1,0,1.4l-4,4A.908.908,0,0,1,5,6Z" transform="translate(0 0)" fill={color} />
      </svg>
    )
  }
}



