import React from 'react';
import { ProductRegisterDetailContainerStyled } from './styled';
import { Displays } from 'components';
import { retailerService } from 'apiServices';
import { withRouter } from 'navigations/withRouter';
// import moment from 'moment';
// import 'moment-timezone';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class ProductRegisterDetailContainer extends React.Component {
  state = { isLoading: true, data: null, };

  componentDidMount() {
    scrollToTopPageFN()
    this.fetchData();
  }

  fetchData = async () => {
    let res = await retailerService.GET_PRODUCT_REGISTED_DETAIL(this.props.router.params.id);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      })
    } else {
      this.setState({
        data: res.data,
        isLoading: false,
      })
    }
  };

  render() {
    const { isLoading, data, } = this.state;
    return (
      <ProductRegisterDetailContainerStyled>
        {
          isLoading
            ?
            <div className='loading_show'>
              <Displays.Loading />
            </div>
            :
            <>
              <div className='title_show'>
                Product register data
              </div>
              <div className='title_show'>
                <div className='mr20'>S/N Detail:</div>
                <div>{data?.serial_no}</div>
              </div>
              <div className='sub_title_show'>
                <div className='line'>
                  ลงทะเบียนโดย
                </div>
              </div>
              <div className='box_sub_detail'>
                <div className='group_box mr200'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ชื่อ
                    </div>
                    <div className='right_label'>
                      {data?.consumer_detail?.firstname}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      นามสกุล
                    </div>
                    <div className='right_label'>
                      {data?.consumer_detail?.lastname}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      เบอร์โทรศัพท์
                    </div>
                    <div className='right_label'>
                      {data?.consumer_detail?.phone_number}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ที่อยู่
                    </div>
                    <div className='right_label'>
                      {
                        data?.consumer_detail
                          ?
                          `${data.consumer_detail.address} ${data.consumer_detail.sub_district} ${data.consumer_detail.district} ${data.consumer_detail.province} ${data.consumer_detail.zipcode}`
                          :
                          `-`
                      }
                    </div>
                  </div>
                </div>
                <div className='group_box'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ทะเบียนรถ
                    </div>
                    <div className='right_label'>
                      {data?.car_plate}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ระยะกิโลเมตร
                    </div>
                    <div className='right_label'>
                    {RenderCommaMoney(Number(data?.distance_registed), true)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub_title_show mt37'>
                <div className='line'>
                  รายละเอียดสินค้า
                </div>
              </div>
              {
                (data.slip_img_path && data.slip_img_path !== '') &&
                <div className='box_img'>
                  <img
                    src={IMG_PATH + data?.slip_img_path}
                    alt="img"
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  />
                </div>
              }

              <div className='box_sub_detail'>
                <div className='group_box mr400'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ยี้ห้อรถ
                    </div>
                    <div className='right_label'>
                      {data?.car_brand}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      รุ่นรถ
                    </div>
                    <div className='right_label'>
                      {data?.car_model}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ปี
                    </div>
                    <div className='right_label'>
                      {data?.car_year}
                    </div>
                  </div>
                </div>
                <div className='group_box'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ประเภทโช้คอัพ
                    </div>
                    <div className='right_label'>
                      {data?.product_type}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ตำแหน่งโช้คอัพ
                    </div>
                    <div className='right_label'>
                      {data?.install_position}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      รุ่นสินค้า
                    </div>
                    <div className='right_label'>
                      {data?.product_model}
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
      </ProductRegisterDetailContainerStyled>
    );
  }
}

export default withRouter(ProductRegisterDetailContainer);