import styled from 'styled-components';

export const DropdownStyled = styled.div`
  .label {
  }
  .input_dropdown {
    // border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    // border-radius: 6px;
    // padding: 10px 52px 10px 10px;
    // font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    // font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    // color: ${({ theme }) => theme.COLORS.GRAY_5};
    
    padding: 4px 16px;
        padding-right: 24px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
        border-radius: 0px;
  }
  .arrow_dropdown {
    top: 18px;
  }
  .errors {
    margin-top: 2px;
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .theme_standard {
  }
`;
