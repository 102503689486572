export const RenderSortTable = (type, data, sortType) => {
  let sortNumber = 1

  switch (type) {
    case 'shop_no_retailer':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.shop_no > b.shop_no ? 1 : b.shop_no > a.shop_no ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.shop_no < b.shop_no ? 1 : b.shop_no < a.shop_no ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case "shop_no":
      if (sortType === 1) {
        data.sort((a, b) =>
          a.retailer_detail.shop_no > b.retailer_detail.shop_no ? 1 : b.retailer_detail.shop_no > a.retailer_detail.shop_no ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.retailer_detail.shop_no < b.retailer_detail.shop_no ? 1 : b.retailer_detail.shop_no < a.retailer_detail.shop_no ? -1 : 0
        );
        sortNumber = 1
      }
      break;


    case 'store_name':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.store_name > b.store_name ? 1 : b.store_name > a.store_name ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.store_name < b.store_name ? 1 : b.store_name < a.store_name ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'shop_type':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.type > b.type ? 1 : b.type > a.type ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.type < b.type ? 1 : b.type < a.type ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'phone_number':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.phone_number > b.phone_number ? 1 : b.phone_number > a.phone_number ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.phone_number < b.phone_number ? 1 : b.phone_number < a.phone_number ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'firstname':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.firstname < b.firstname ? 1 : b.firstname < a.firstname ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'lastname':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.lastname < b.lastname ? 1 : b.lastname < a.lastname ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'reward_detail.name':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.reward_detail.name > b.reward_detail.name ? 1 : b.reward_detail.name > a.reward_detail.name ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.reward_detail.name < b.reward_detail.name ? 1 : b.reward_detail.name < a.reward_detail.name ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'address_province':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.address_province > b.address_province ? 1 : b.address_province > a.address_province ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.address_province < b.address_province ? 1 : b.address_province < a.address_province ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'shipping_date':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.shipping_date > b.shipping_date ? 1 : b.shipping_date > a.shipping_date ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.shipping_date < b.shipping_date ? 1 : b.shipping_date < a.shipping_date ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'prize_wheel_quota':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.prize_wheel_quota > b.prize_wheel_quota ? 1 : b.prize_wheel_quota > a.prize_wheel_quota ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.prize_wheel_quota < b.prize_wheel_quota ? 1 : b.prize_wheel_quota < a.prize_wheel_quota ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'shipping_brand':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.shipping_brand > b.shipping_brand ? 1 : b.shipping_brand > a.shipping_brand ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.shipping_brand < b.shipping_brand ? 1 : b.shipping_brand < a.shipping_brand ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'shipping_ref_no':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.shipping_ref_no > b.shipping_ref_no ? 1 : b.shipping_ref_no > a.shipping_ref_no ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.shipping_ref_no < b.shipping_ref_no ? 1 : b.shipping_ref_no < a.shipping_ref_no ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'serial_no':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.serial_no > b.serial_no ? 1 : b.serial_no > a.serial_no ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.serial_no < b.serial_no ? 1 : b.serial_no < a.serial_no ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'car_plate':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.car_plate > b.car_plate ? 1 : b.car_plate > a.car_plate ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.car_plate < b.car_plate ? 1 : b.car_plate < a.car_plate ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'product_model':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.product_model > b.product_model ? 1 : b.product_model > a.product_model ? -1 : 0
        );
        sortNumber = -1

      } else {
        data.sort((a, b) =>
          a.product_model < b.product_model ? 1 : b.product_model < a.product_model ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'car_brand':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.car_brand > b.car_brand ? 1 : b.car_brand > a.car_brand ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.car_brand < b.car_brand ? 1 : b.car_brand < a.car_brand ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'province':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.retailer_detail.province > b.retailer_detail.province ? 1 : b.retailer_detail.province > a.retailer_detail.province ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.retailer_detail.province < b.retailer_detail.province ? 1 : b.retailer_detail.province < a.retailer_detail.province ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'province_only':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.province > b.province ? 1 : b.province > a.province ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.province < b.province ? 1 : b.province < a.province ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'province_retailer':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.province > b.province ? 1 : b.province > a.province ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.province < b.province ? 1 : b.province < a.province ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'tax_id':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.tax_id > b.tax_id ? 1 : b.tax_id > a.tax_id ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.tax_id < b.tax_id ? 1 : b.tax_id < a.tax_id ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'install_position':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.install_position > b.install_position ? 1 : b.install_position > a.install_position ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.install_position < b.install_position ? 1 : b.install_position < a.install_position ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'car_model':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.car_model > b.car_model ? 1 : b.car_model > a.car_model ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.car_model < b.car_model ? 1 : b.car_model < a.car_model ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'product_type':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.product_type > b.product_type ? 1 : b.product_type > a.product_type ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.product_type < b.product_type ? 1 : b.product_type < a.product_type ? -1 : 0
        );
        sortNumber = 1
      }

      break;

    case 'created_at':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'created_at_time':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.created_at_time > b.created_at_time ? 1 : b.created_at_time > a.created_at_time ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.created_at_time < b.created_at_time ? 1 : b.created_at_time < a.created_at_time ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'reward_point':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.reward_point > b.reward_point ? 1 : b.reward_point > a.reward_point ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.reward_point < b.reward_point ? 1 : b.reward_point < a.reward_point ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'title':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.title < b.title ? 1 : b.title < a.title ? -1 : 0
        );
        sortNumber = 1
      }
      break;

    case 'car_year':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.car_year > b.car_year ? 1 : b.car_year > a.car_year ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.car_year < b.car_year ? 1 : b.car_year < a.car_year ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'reward_name':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.name < b.name ? 1 : b.name < a.name ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'reward_name_group':
      if (sortType === 1) {
        data.sort((a, b) =>
          a._id.reward_name > b._id.reward_name ? 1 : b._id.reward_name > a._id.reward_name ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a._id.reward_name < b._id.reward_name ? 1 : b._id.reward_name < a._id.reward_name ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'total_amount':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.total_amount > b.total_amount ? 1 : b.total_amount > a.total_amount ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.total_amount < b.total_amount ? 1 : b.total_amount < a.total_amount ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'redeem_point':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.redeem_point > b.redeem_point ? 1 : b.redeem_point > a.redeem_point ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.redeem_point < b.redeem_point ? 1 : b.redeem_point < a.redeem_point ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'point':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.point > b.point ? 1 : b.point > a.point ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.point < b.point ? 1 : b.point < a.point ? -1 : 0
        );
        sortNumber = 1
      };

      break;


    case 'name_shipping':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.name_shipping > b.name_shipping ? 1 : b.name_shipping > a.name_shipping ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.name_shipping < b.name_shipping ? 1 : b.name_shipping < a.name_shipping ? -1 : 0
        );
        sortNumber = 1
      };

      break;


    case 'phone_number_shipping':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.phone_number_shipping > b.phone_number_shipping ? 1 : b.phone_number_shipping > a.phone_number_shipping ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.phone_number_shipping < b.phone_number_shipping ? 1 : b.phone_number_shipping < a.phone_number_shipping ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'totalCount':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.totalCount > b.totalCount ? 1 : b.totalCount > a.totalCount ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.totalCount < b.totalCount ? 1 : b.totalCount < a.totalCount ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'updated_at':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.updated_at > b.updated_at ? 1 : b.updated_at > a.updated_at ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.updated_at < b.updated_at ? 1 : b.updated_at < a.updated_at ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'status':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.status > b.status ? 1 : b.status > a.status ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.status < b.status ? 1 : b.status < a.status ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'redeem_amount':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.redeem_amount > b.redeem_amount ? 1 : b.redeem_amount > a.redeem_amount ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.redeem_amount < b.redeem_amount ? 1 : b.redeem_amount < a.redeem_amount ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'remain':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.remain > b.remain ? 1 : b.remain > a.remain ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.remain < b.remain ? 1 : b.remain < a.remain ? -1 : 0
        );
        sortNumber = 1
      };

      break;

    case 'is_active':
      if (sortType === 1) {
        data.sort((a, b) =>
          a.is_active > b.is_active ? 1 : b.is_active > a.is_active ? -1 : 0
        );
        sortNumber = -1
      } else {
        data.sort((a, b) =>
          a.is_active < b.is_active ? 1 : b.is_active < a.is_active ? -1 : 0
        );
        sortNumber = 1
      };

      break;


    default:
      return { data: [], sortNumber: 1 };
  }

  return { data: data, sortNumber: sortNumber };
};
