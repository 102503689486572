import React, { useEffect, useState } from 'react';
import { ConsumerFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';
// import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import address from 'utils/jsons/thailandAddress/address.json';

const ConsumerForm = ({
  onSubmit,
  handleBack,
  initialValues,
  disabled,
  dataShop,
}) => {
  const schema = yup.object().shape({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    phone_number: yup.string().required('Phone number is required'),
    address: yup.string().required('Address is required'),
    province: yup.object().required('Province is required').nullable(),
    district: yup.object().required('District is required').nullable(),
    zipcode: yup.string().required('Zipcode is required'),
    sub_district: yup.object().required('Sub district is required').nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_provinceOption, _setProvinceOption] = useState([]);
  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        province: {
          label: initialValues.province,
          value: initialValues.province,
        },
        district: {
          label: initialValues.district,
          value: initialValues.district,
        },
        sub_district: {
          label: initialValues.sub_district,
          value: initialValues.sub_district,
        },
      });

      if (initialValues.sub_district) {
        _setDistrictOption(
          address.reduce((result, data, i) => {
            if (data.province === initialValues.province) {
              result.push({
                label: `${data.amphoe} ${data.district} ${data.zipcode}`,
                value: data.amphoe,
              });
              // }
            }
            return result;
          }, [])
        );
      }
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _setProvinceOption(
      address.reduce((result, data, i) => {
        if (i === 0) {
          result.push({ label: data.province, value: data.province });
        } else {
          let find = result.find((e) => e.value === data.province);
          if (!find) {
            result.push({ label: data.province, value: data.province });
          }
        }
        return result;
      }, [])
    );
  }, []);

  const _handleChangeProvince = (e) => {
    setValue('district', '');
    setValue('sub_district', '');
    setValue('zipcode', '');
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(' ');
    setValue('sub_district', { label: splitData[1], value: splitData[1] });
    setValue('zipcode', splitData[2]);
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue('zipcode', find.zipcode);
    }
  };

  return (
    <ConsumerFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="ratailer_container">
          <div className="title_show">Consumer data</div>
          <div className="sub_title_show">รายละเอียดลูกค้า</div>
          <div className="box_sub_detail">
            <div className="group_box mr200">
              <div className="line_box">
                <div className="left_label">ชื่อ</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_mini
                        {...field}
                        placeholder="ชื่อ"
                        errors={errors.firstname?.message}
                      />
                    )}
                    name="firstname"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">นามสกุล</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_mini
                        {...field}
                        placeholder="นามสกุล"
                        errors={errors.lastname?.message}
                      />
                    )}
                    name="lastname"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">ที่อยู่</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_mini
                        {...field}
                        placeholder="ที่อยู่"
                        errors={errors.address?.message}
                      />
                    )}
                    name="address"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">จังหวัด</div>
                <div className="right_label w57">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        theme_normal
                        placeholder="จังหวัด"
                        {...field}
                        options={_provinceOption}
                        // errors={errors.province?.message}
                        onChaneCustom={_handleChangeProvince}
                      />
                    )}
                    name="province"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">อำเภอ</div>
                <div className="right_label w57">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        theme_normal
                        placeholder="อำเภอ"
                        {...field}
                        options={_districtOption}
                        errors={errors.district?.message}
                        onChaneCustom={_handleChangeDistrict}
                      />
                    )}
                    name="district"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">ตำบล</div>
                <div className="right_label w57">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Dropdown
                        theme_normal
                        placeholder="ตำบล"
                        {...field}
                        disabled
                        options={_subdistrictOption}
                        errors={errors.sub_district?.message}
                        onChaneCustom={_handleChangeSubDistrict}
                      />
                    )}
                    name="sub_district"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">รหัสไปรษณีย์</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_mini
                        disabled
                        {...field}
                        placeholder="รหัสไปรษณีย์"
                        // errors={errors.zipcode?.message}
                      />
                    )}
                    name="zipcode"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">เบอร์โทรศัพท์</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_mini
                        {...field}
                        placeholder="เบอร์โทรศัพท์"
                        errors={errors.phone_number?.message}
                      />
                    )}
                    name="phone_number"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="group_box gap">
              <div className="line_box">
                <div className="left_label">PDPA</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSwitch
                        {...field}
                        // errors={errors.phone?.message}
                      />
                    )}
                    name="is_active_pdpa"
                    defaultValue={true}
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">นโยบาย กติกา</div>
                <div className="right_label">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputSwitch
                        {...field}
                        // errors={errors.phone?.message}
                      />
                    )}
                    name="is_active_tc"
                    defaultValue={true}
                  />
                </div>
              </div>
              <div className="line_box">
                <div className="left_label">จำนวนสิทธิ์ที่เหลือ</div>
                <div className="right_label">{dataShop.quota}</div>
              </div>
              {/* <div className='line_box'>
                <div className='left_label'>
                  จำนวนของรางวัลที่ได้รับ
                </div>
                <div className='right_label'>
                  {dataShop.reward_amount}
                </div>
              </div> */}
            </div>
          </div>
          <div className="btn_zone">
            <div className="btn175">
              <Buttons.BtnNormal
                theme_normal_btn
                label="ย้อนกลับ"
                onClick={handleBack}
              />
            </div>
            <div className="btn218">
              <Buttons.BtnNormal
                theme_normal_btn
                label="ยืนยันการแก้ไข"
                disabled={disabled}
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </ConsumerFormStyled>
  );
};

ConsumerForm.propTypes = {};

export default ConsumerForm;
