import React from 'react';
import { RedemptionOnProcessDetailEditContainerStyled } from './styled';
import { Forms } from 'components';
import { retailerService } from 'apiServices';
import { toast } from 'react-toastify';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class RedemptionOnProcessDetailEditContainer extends React.Component {
  componentDidMount() {
    scrollToTopPageFN();
  }

  handleSubmit = async (values) => {
    const { succesEdit, data, onCloseModal } = this.props
    let params = {
      shipping_ref_no: values.shipping_ref_no,
      shipping_brand: values.shipping_brand.value,
      shipping_date: values.shipping_date,
      status: '3',
    };

    let res = await retailerService.PUT_WHEEL_GAME_HISTORY(data._id, params);
    if (res && res.status === 200) {
      toast.success('Update success');
      onCloseModal()
      succesEdit()
    } else {
      toast.error('Update failed');
    }
  };

  render() {
    const { onCloseModal, data } = this.props

    return (
      <RedemptionOnProcessDetailEditContainerStyled>
        <div className='title_show'>
          Redemption on process
        </div>
        <div className='sub_title'>
        {`รหัสร้านค้า : ${data && data.retailer_detail.shop_no ? data.retailer_detail.shop_no : '-'}`}
        </div>
        <div className='sub_title'>
          {`ผู้ที่ได้รับรางวัล :  ${data && data.firstname_shipping && `${data.firstname_shipping} ${data.lastname_shipping}`}`}
        </div>
        <div className='title_show'>
          <Forms.RedemptionSendForm
            onSubmit={this.handleSubmit}
            handleBack={onCloseModal}
            initialValues={data}
          />
        </div>
      </RedemptionOnProcessDetailEditContainerStyled>
    );
  }
}

export default RedemptionOnProcessDetailEditContainer;