import styled from 'styled-components';

export const InputDatePickerBetweenStyled = styled.div`
  .date_between_container {
    display: flex;
    margin: 0 -5px;
  }
  .custom_date {
    margin: 0 5px;
    border-radius: 4px;
    padding: 8px 30px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    background: ${({ theme }) => theme.COLORS.GREEN_1}1A;
    border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 180px;
    max-width: 180px;
    width: 100%;
    .arrow_icon {
      margin-left: 5px;
      padding-top: 3px;
    }
  }
  .date_picker_container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: fit-content;
  }

  .theme_standard {
    .custom_date {
      flex-shrink: 0;
      padding: 10px 10px;
      border-radius: 8px;
    }
  }
`;
