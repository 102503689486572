import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import retailerRoutes from './retailerRoutes';
import consumerRoutes from './consumerRoutes';
import { Mainlayouts } from 'components';
import LoginContainer from 'containers/LoginContainer';
import ComponentContainer from 'containers/ComponentContainer';

const Navigations = () => {
  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route index element={<LoginContainer />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route path={ROUTE_PATH.COMPONENT} element={<ComponentContainer />} />
        {/* <Route path="*" element={<RegisterContainer />} /> */}
      </Route>
      <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.NavSidebar />}>
        {retailerRoutes &&
          retailerRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      index={f.index}
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
        {consumerRoutes &&
          consumerRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      index={f.index}
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
      </Route>
      {/* <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.NavSidebar />}>
        {' '}
      </Route> */}
    </Routes>
  );
};

export default Navigations;
