import React, { useEffect } from 'react';
import { PrizeFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons, Icons } from 'components';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import moment from 'moment';

const PrizeForm = ({
  initialValues,
  type,
  handleBack,
  disabled,
  // handleDelete,
  onSubmit,
}) => {
  const schema =
    type === 'wheel'
      ? yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
        // reward_cate: yup.string().required('Reward Category is required'),
        reward_cate: yup.object().shape({
          label: yup.string().required('Reward Category is required'),
          value: yup.string().required('Reward Category is required'),
        }),
        reward_type: yup.object().shape({
          label: yup.string().required('Reward Type is required'),
          value: yup.string().required('Reward Type is required'),
        }),
        add_number: yup
          .number()
          .typeError('Amount must be a number')
          .min(0, 'Must be more than 0 point'),
        // agency_fee: yup
        //   .number()
        //   .typeError('Agency fee must be a number')
        //   .min(0, 'Must be more than 0 point'),
        // shipping_cost: yup
        //   .number()
        //   .typeError('Shipping cost must be a number')
        //   .min(0, 'Must be more than 0 point'),
        // total_value: yup
        //   .number()
        //   .typeError('Total value must be a number')
        //   .min(0, 'Must be more than 0 point'),
        frequency: yup
          .number()
          .typeError('Amount must be a number')
          .min(0, 'Must be more than 0 point'),
        total_amount: yup
          .number()
          .typeError('Amount must be a number')
          .min(1, 'Must be more than 1 point')
          .required('Total amount is requried'),
      })
      : yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
        reward_cate: yup.object().shape({
          label: yup.string().required('Reward Category is required'),
          value: yup.string().required('Reward Category is required'),
        }),
        reward_type: yup.object().shape({
          label: yup.string().required('Reward Type is required'),
          value: yup.string().required('Reward Type is required'),
        }),
        redeem_point: yup
          .number()
          .typeError('Amount must be a number')
          .min(1, 'Must be more than 1 point')
          .required('Redeem point is requried'),
        add_number: yup
          .number()
          .typeError('Amount must be a number')
          .min(0, 'Must be more than 0 point'),
        // agency_fee: yup
        //   .number()
        //   .typeError('Agency fee must be a number')
        //   .min(0, 'Must be more than 0 point'),
        // shipping_cost: yup
        //   .number()
        //   .typeError('Shipping cost must be a number')
        //   .min(0, 'Must be more than 0 point'),
        // total_value: yup
        //   .number()
        //   .typeError('Total value must be a number')
        //   .min(0, 'Must be more than 0 point'),
        total_amount: yup
          .number()
          .typeError('Amount must be a number')
          .min(1, 'Must be more than 1 point')
          .required('Total amount is requried'),
      });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        reward_cate: initialValues.reward_cate && { label: initialValues.reward_cate === '1' ? 'Coupon' : 'Premium product', value: initialValues.reward_cate },
        reward_type: initialValues.reward_type && { label: initialValues.reward_type === '1' ? 'Barcode' : 'สินค้า', value: initialValues.reward_type },
        img_path: initialValues.img_path
          ? [{ image_uri: initialValues.img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PrizeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='right_label'>
          <div className='label_date'>
            สร้างเมื่อวันที่ {` `}{' '}
            {
              initialValues
                ? moment(initialValues.created_at).format('DD/MM/YYYY')
                : moment().format('DD/MM/YYYY')
            }
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputSwitch
                {...field}
              // errors={errors.phone?.message}
              />
            )}
            name="is_active"
            defaultValue={true}
          />
        </div>
        <div className="box_form">
          <div className="left_form">ชื่อรางวัล</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextField
                  theme_standard
                  {...field}
                  placeholder="ชื่อรางวัล"
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">หมวดรางวัล</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputDropDown
                  theme_big
                  placeholder='หมวดรางวัล'
                  {...field}
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'Coupon', value: '1' },
                    { label: 'Premium product', value: '2' },
                  ]}
                  errors={errors.reward_cate?.message}
                />
              )}
              name="reward_cate"
              defaultValue={{ label: 'Coupon', value: '1' }}
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">ประเภท</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputDropDown
                  theme_big
                  placeholder='ประเภท'
                  {...field}
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'Barcode', value: '1' },
                    { label: 'สินค้า', value: '2' },
                  ]}
                  errors={errors.reward_type?.message}
                />
              )}
              name="reward_type"
              defaultValue={{ label: 'Barcode', value: '1' }}
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">รูปภาพ</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  theme_standard_upload_image
                  acceptType="cover"
                  maxMBFileSizeWarning="1"
                  {...field}
                />
              )}
              name="img_path"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">รายละเอียดรางวัล</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextArea
                  {...field}
                  placeholder="รายละเอียดของรางวัล"
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">หมายเหตุ</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextArea
                  {...field}
                  placeholder="หมายเหตุ"
                // errors={errors.memo?.message}
                />
              )}
              name="memo"
              defaultValue=""
            />
          </div>
        </div>
        {type !== 'wheel' && (
          <>
            <div className="box_form">
              <div className="left_form">จำนวนแต้มที่ใช้</div>
              <div className="right_form">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextField
                      theme_standard
                      {...field}
                      placeholder="จำนวนแต้มที่ใช้"
                      errors={errors.redeem_point?.message}
                    />
                  )}
                  name="redeem_point"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="box_form">
              <div className="left_form">ค่า Agency Fee %</div>
              <div className="right_form">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextField
                      theme_standard
                      {...field}
                      placeholder="ค่า Agency Fee %"
                      errors={errors.agency_fee?.message}
                    />
                  )}
                  name="agency_fee"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="box_form">
              <div className="left_form">ค่าขนส่ง</div>
              <div className="right_form">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextField
                      theme_standard
                      {...field}
                      placeholder="ค่าขนส่ง"
                      errors={errors.shipping_cost?.message}
                    />
                  )}
                  name="shipping_cost"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="box_form">
              <div className="left_form">มูลค่ารวม</div>
              <div className="right_form">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextField
                      theme_standard
                      {...field}
                      placeholder="มูลค่ารวม"
                      errors={errors.total_value?.message}
                    />
                  )}
                  name="total_value"
                  defaultValue=""
                />
              </div>
            </div>
          </>
        )}
        {type === 'wheel' && (
          <div className="box_form">
            <div className="left_form">จำนวนรอบที่ออกรางวัล</div>
            <div className="right_form">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextField
                    theme_standard
                    {...field}
                    placeholder="จำนวนรอบที่ออกรางวัล"
                    errors={errors.frequency?.message}
                  />
                )}
                name="frequency"
                defaultValue=""
              />
            </div>
          </div>
        )}
        <div className="box_form">
          <div className="left_form">จำนวนรางวัลทั้งหมด</div>
          <div className="right_form flex_show">
            <div className="w120">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.InputTextField
                    theme_standard
                    {...field}
                    errors={errors.total_amount?.message}
                    disabled={initialValues && true}
                  />
                )}
                name="total_amount"
                defaultValue=""
              />
            </div>
            {initialValues && (
              <div className="dual_box">
                <div className="d_label">เพิ่มจำนวนรางวัลอีก</div>
                <div className="d_input">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.InputTextField
                        theme_standard
                        {...field}
                        errors={errors.add_number?.message}
                        min={0}
                        type="number"
                      // disabled
                      />
                    )}
                    name="add_number"
                    defaultValue="0"
                  />
                </div>
              </div>
            )}
            {initialValues && (
              <div className="dual_box">
                <div className="d_label">จำนวนรางวัลคงเหลือ</div>
                <div className="d_input">
                  {RenderCommaMoney(
                    Number(
                      initialValues.total_amount - initialValues.redeem_amount
                    ),
                    true
                  )}
                  {/* <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputTextField
                      theme_standard
                      {...field}
                      errors={errors.num3?.message}
                    />
                  )}
                  name="num3"
                  defaultValue=""
                /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="btn_action_price">
          <div className="btn175">
            <Buttons.BtnNormal
              theme_normal_btn
              label="ย้อนกลับ"
              onClick={handleBack}
            />
          </div>
          <div className="btn175">
            <Buttons.BtnNormal
              theme_normal_btn
              label="ยืนยัน"
              type="submit"
              disabled={disabled}
            />
          </div>
          {/* {initialValues && (
            <div className="btn175">
              <Buttons.BtnNormal
                theme_normal_btn
                bgColor="red"
                label="ลบ"
                onClick={handleDelete}
              />
            </div>
          )} */}
        </div>
      </form>
    </PrizeFormStyled>
  );
};

PrizeForm.propTypes = {};

export default PrizeForm;
