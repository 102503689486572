import styled from 'styled-components';

export const InputDatePikerCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  .label {
    margin-bottom: 16px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
  }
  .custom_date {
    border-radius: 4px;
    padding: 8px 30px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    background: ${({ theme }) => theme.COLORS.GREEN_1}1A;
    border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 110px;
    width: 100%;
    .arrow_icon {
      margin-left: 5px;
      padding-top: 3px;
    }
  }
  .date_picker_container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: fit-content;
  }

  .theme_standard_date_picker_custom_input {
    .custom_date {
      padding: 10px 10px;
      border-radius: 8px;
    }
  }
`;
