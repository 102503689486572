import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { SkeletonLoadStyled } from './styled';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoad = ({ theme_skeleton_normal, height }) => {
  const customClass = cx({
    theme_skeleton_normal: theme_skeleton_normal,
  });
  return (
    <SkeletonLoadStyled height={height}>
      <div className={customClass}>
        <SkeletonTheme color="#DEDEDE" highlightColor="#F3F3F4">
          <div className="body_skeleton">
            <Skeleton width='100%' height='100%' />
          </div>
        </SkeletonTheme>
      </div>
    </SkeletonLoadStyled>
  );
};

SkeletonLoad.propTypes = {};

export default SkeletonLoad;
