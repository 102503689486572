import styled from 'styled-components'

export const RedemptionSendFormStyled = styled.div`
  .box_form {
    background: ${({ theme }) => theme.COLORS.BLUE_2};
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
    .left_form {
      border-right: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      width: 20%;
      padding: 18px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .right_form {
      width: 80%;
      padding: 16px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      &.flex_show {
        display: flex;
        justify-content: space-between;
      }
      .w120 {
        width: 120px;
      }
      .dual_box {
        display: flex;
        // align-items: center;
        .d_label {
          margin-right: 10px;
        }
        .d_input {
          width: 120px;
        }
      }
    }
  }
  .btn_action_price {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 27px;
    .btn175 {
      width: 175px;
    }
  }
`
