import styled from 'styled-components';

export const DashboardContainerStyled = styled.div`
  .loading_show {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top_sec {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .ts_left_col {
      flex: 1;
      max-width: 500px;
      .display_item_wrap {
        margin-bottom: 10px;
      }
    }
    .ts_right_col {
      width: 188px;
      height: 46px;
      flex-shrink: 0;
    }
  }
  .bottom_sec {
    .filter_wrap {
      margin-bottom: 18px;
    }
    .row {
      margin-bottom: 16px;
      display: flex;
      column-gap: 16px;
      .left_col {
        flex: 1;
        max-width: 600px;
        .score_wrap {
          margin-bottom: 18px;
          max-width: 500px;
        }
      }
      .right_col {
        flex: 1;
        max-width: 600px;
        .score_wrap {
          margin-bottom: 18px;
          max-width: 500px;
        }
      }
    }
  }
  .m_dashboard_container {
    width: 95vw;
    height: 90vh;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
