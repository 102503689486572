import React from "react";
import { UserDataContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { CONSUMER_ROUTE_PATH } from "utils/constants/routePath";
import { consumerService } from "apiServices";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { RenderSortTable } from "utils/functions/SortTable";
import * as XLSX from "xlsx";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { toast } from "react-toastify";
import {
  Tables,
  Icons,
  Inputs,
  Typhographys,
  Displays,
  Buttons,
} from "components";

class UserDataContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    filterQuery: null,
    isLoadingTable: true,
    perPage: 10,
    sortTable: {
      firstname: 1,
      lastname: 1,
      phone_number: 1,
      province: 1,
      created_at: 1,
      created_at_time: 1,
      prize_wheel_quota: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}`);
  }

  fetchData = async (stringQuery) => {
    let res = await consumerService.GET_CONSUMER_LIST(stringQuery);
    if (res && res.status === 200) {
      let tempData = [];
      res.data.docs.map((e) =>
        tempData.push({
          ...e,
          created_at_time: moment(e.created_at)
            .tz("Asia/Bangkok")
            .format("HH:mm"),
        })
      );
      this.setState({
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;
    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;

    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  handleClickItem = (e) => {
    this.props.router.navigate(`${CONSUMER_ROUTE_PATH.USER_DATA}/${e}`);
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;
    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case "firstname":
          sortValue = RenderSortTable(e, data, sortTable.firstname);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              firstname: sortValue.sortNumber,
            },
          });
          break;

        case "lastname":
          sortValue = RenderSortTable(e, data, sortTable.lastname);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              lastname: sortValue.sortNumber,
            },
          });
          break;

        case "phone_number":
          sortValue = RenderSortTable(e, data, sortTable.phone_number);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              phone_number: sortValue.sortNumber,
            },
          });
          break;

        case "province_only":
          sortValue = RenderSortTable(e, data, sortTable.province);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              province: sortValue.sortNumber,
            },
          });
          break;

        case "prize_wheel_quota":
          sortValue = RenderSortTable(e, data, sortTable.prize_wheel_quota);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              prize_wheel_quota: sortValue.sortNumber,
            },
          });
          break;

        case "created_at":
          sortValue = RenderSortTable(e, data, sortTable.created_at);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at: sortValue.sortNumber,
            },
          });
          break;

        case "created_at_time":
          sortValue = RenderSortTable(e, data, sortTable.created_at_time);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              created_at_time: sortValue.sortNumber,
            },
          });
          break;

        default:
          break;
      }
    }
  };

  handleDownloadExcel = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await consumerService.GET_CONSUMER_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = "SheetJS";
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.firstname,
          e.lastname,
          e.phone_number,
          e.province,
          e.district,
          e.sub_district,
          RenderCommaMoney(Number(e.prize_wheel_quota), true),
          moment(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          moment(e.created_at).tz("Asia/Bangkok").format("HH:mm"),
          moment(e.updated_at).tz("Asia/Bangkok").format("DD/MM/YYYY"),
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "ชื่อ",
        "นามสกุล",
        "เบอร์โทร",
        "จังหวัด",
        'ตำบล',
        'อำเภอ',
        "สิทธ์คงเหลือ",
        "วันที่ลงทะเบียน",
        "เวลา",
        "วันที่อัพเดตข้อมูล",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 17 },
        { wch: 17 },
        { wch: 17 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Consumer report ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("download fail");
    }
  };

  render() {
    const { isLoading, isBgLoading, data, pagination, isLoadingTable } =
      this.state;
    return (
      <UserDataContainerStyled>
        <Displays.BgLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">
              <Typhographys.HeaderH1 label="Consumer data" />
            </div>
            <div className="filter_wrap">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter2}
                btnLabel="ค้นหา"
                onQueryFilter={this.handleFilterKeyword}
              />
            </div>
            <div className="export_zone">
              <Inputs.InputDropDownFilter
                placeholder="เลือก"
                iconSvgClose={<Icons.ArrowDropDown />}
                iconSvgOpen={<Icons.ArrowDropDown up />}
                onChange={this.handleSelectedPerPage}
                options={[
                  { label: "แสดง 10 แถว", value: "10" },
                  { label: "แสดง 20 แถว", value: "20" },
                  { label: "แสดง 30 แถว", value: "30" },
                  { label: "แสดง 40 แถว", value: "40" },
                  { label: "แสดง 50 แถว", value: "50" },
                ]}
              />
              <div className="btnw">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="Export"
                  onClick={this.handleDownloadExcel}
                />
              </div>
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              // useRenderNoPage
              data={data}
              isLoading={isLoadingTable}
              pagination={pagination}
              handlePageClick={this.handlePageClick}
              columns={columns3({
                onClickItem: this.handleClickItem,
                onSort: this.handleSort,
              })}
            />
          </>
        )}
      </UserDataContainerStyled>
    );
  }
}

const dataFilter2 = [
  {
    accessor: "firstname",
    length: "260px",
    filter: {
      fieldQuery: "firstname",
      type: "text",
      placeholder: "ค้นหาชื่อ",
    },
  },
  {
    accessor: "lastname",
    length: "260px",
    filter: {
      fieldQuery: "lastname",
      type: "text",
      placeholder: "ค้นหานามสกุล",
    },
  },
  {
    accessor: "phone_number",
    length: "260px",
    filter: {
      fieldQuery: "phone_number",
      type: "text",
      placeholder: "ค้นหาเบอร์โทร",
    },
  },
];

const columns3 = ({ onClickItem, onSort }) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        ชื่อ
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("firstname")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "firstname",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div
        style={{ textDecoration: "underline" }}
        onClick={() => onClickItem(origin._id)}
      >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        นามสกุล
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("lastname")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "lastname",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        เบอร์โทร
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("phone_number")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "phone_number",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        จังหวัด
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("province_only")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "province",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        โควต้าเล่นเกมส์
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("prize_wheel_quota")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "prize_wheel_quota",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{RenderCommaMoney(Number(value), true)}</div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันที่ลงทะเบียน
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("created_at")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "created_at",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{moment(value).format("DD/MM/YYYY")}</div>
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        เวลา
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("created_at_time")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "created_at_time",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        วันที่อัพเดตข้อมูล
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("updated_at")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "updated_at",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => (
      <div>{moment(value).format("DD/MM/YYYY")}</div>
    ),
  },
];

export default withRouter(UserDataContainer);
