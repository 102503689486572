export default function renderIcon({ width = "24", height = "24", color = '#7e84a3' }) {
  return (
    <svg id="Icons_Navigation_icon_12_states_" data-name="Icons / Navigation icon (12 states)" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic_message">
        <path id="Path_381" data-name="Path 381" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_382" data-name="Path 382" d="M3,20l1.3-3.9A7.417,7.417,0,0,1,6.4,5.726a9.856,9.856,0,0,1,11.846.48,7.362,7.362,0,0,1,1.029,10.5A9.733,9.733,0,0,1,7.7,19L3,20" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line id="Line_15" data-name="Line 15" y2="0.01" transform="translate(12 12)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line id="Line_16" data-name="Line 16" y2="0.01" transform="translate(8 12)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line id="Line_17" data-name="Line 17" y2="0.01" transform="translate(16 12)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
