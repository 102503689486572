import React from 'react';
// import PropTypes from 'prop-types'
import { NoOffStyled } from './styled';

const NoOff = ({
  value,
}) => {

  return (
    <NoOffStyled>
      <div className='container_on_off'>
        <div>
          [
        </div>
        <div className={`on_color ${value && `active`}`}>
          ON
        </div>
        <div>
          /
        </div>
        <div className={`off_color ${value && `active`}`}>
          OFF
        </div>
        <div>
          ]
        </div>
      </div>
    </NoOffStyled>
  );
};

NoOff.propTypes = {};

export default NoOff;
