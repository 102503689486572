import styled from 'styled-components';

export const InputSwitchStyled = styled.div`
  
  .error {
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    margin-top: 0.4rem;
    text-align: right;
  }

  .theme_standard {
  }
`;
