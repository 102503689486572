import React from 'react';
import { BannerUpdateContainerStyled } from './styled';
import { Buttons, Displays } from 'components'
import { withRouter } from 'navigations/withRouter';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { retailerService } from 'apiServices';
import moment from 'moment';
import { IMG_PATH } from 'utils/constants/imgPath';
import { AlertControl } from 'components/Modals/Alert';
import { toast } from 'react-toastify';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class BannerUpdateContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN()
    this.fetchData();
  }

  fetchData = async () => {
    let res = await retailerService.GET_RETAILER_BANNER_DETAIL(this.props.router.params.id);

    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      })
    }
  };

  handleBack = () => {
    this.props.router.navigate(RETAILER_ROUTE_PATH.BANNER);
  };

  handleEdit = () => {
    this.props.router.navigate(`${RETAILER_ROUTE_PATH.BANNER_EDIT}/${this.props.router.params.id}`);
  };

  handleDelete = () => {
    AlertControl.show({
      title: 'Confirm Delete',
      description: 'Do you want to delete it?',
      btnLabel1: 'Confirm',
      btnLabel2: 'Cancle',
      bgColor2: 'red',
      onEvent1: this.actionDelete
    });
  };

  actionDelete = async () => {
    let res = await retailerService.DELETE_RETAILER_BANNER(this.props.router.params.id);

    if (res && res.status === 200) {
      toast.success('Delete success');
      this.props.router.navigate(RETAILER_ROUTE_PATH.BANNER);
    } else {
      toast.error('Delete Failed');
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <BannerUpdateContainerStyled>
        {
          isLoading
            ?
            <div className='loading_show'>
              <Displays.Loading />
            </div>
            :
            <>
              <div className="title_show">Banner</div>
              <div className='label_zone'>
                <div>
                  สร้างเมื่อวันที่  {` `}
                  {data && moment(data.created_at).format('DD/MM/YYYY')}
                </div>
                <Displays.NoOff value={data ? data.is_active : true} />
              </div>
              <div className='box_form'>
                <div className='left_form'>
                  ชื่อ Banner
                </div>
                <div className='right_form'>
                  {data?.title}
                </div>
              </div>
              <div className='box_form'>
                <div className='left_form'>
                  รูปภาพ
                </div>
                <div className='right_form'>
                  {
                    data && data.img_path && data.img_path !== '' &&
                    <img className='img_show' src={IMG_PATH + data?.img_path} alt='img' />
                  }
                </div>
              </div>
              <div className='box_form'>
                <div className='left_form'>
                  Link
                </div>
                <div className='right_form'>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data?.link}
                  >
                    {data?.link}
                  </a>
                </div>
              </div>
              <div className='btn_action_price'>
                <div className='btn175'>
                  <Buttons.BtnNormal
                    theme_normal_btn
                    label="ย้อนกลับ"
                    onClick={this.handleBack}
                  />
                </div>
                <div className='btn175'>
                  <Buttons.BtnNormal
                    theme_normal_btn
                    label="แก้ไข"
                    onClick={this.handleEdit}
                  />
                </div>
                <div className='btn175'>
                  <Buttons.BtnNormal
                    theme_normal_btn
                    bgColor='red'
                    label="ลบ"
                    onClick={this.handleDelete}
                  />
                </div>
              </div>
            </>
        }
      </BannerUpdateContainerStyled>
    );
  }
}

export default withRouter(BannerUpdateContainer);