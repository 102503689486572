import React, { useEffect } from 'react';
import { NewsFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';
import moment from 'moment';

const NewsForm = ({ onSubmit, handleBack, initialValues, disabled }) => {
  const schema = yup.object().shape({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        img_path: initialValues.img_path
          ? [{ image_uri: initialValues.img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NewsFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='label_zone'>
          <div>
            สร้างเมื่อวันที่  {` `}
            {initialValues ? moment(initialValues.created_at).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputSwitch
                {...field}
              // errors={errors.phone?.message}
              />
            )}
            name="is_active"
            defaultValue={true}
          />
        </div>
        <div className='box_form'>
          <div className='left_form'>
            หัวข้อข่าว
          </div>
          <div className='right_form'>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextField
                  theme_standard
                  {...field}
                  placeholder='หัวข้อข่าว'
                  errors={errors.title?.message}
                />
              )}
              name="title"
              defaultValue=""
            />
          </div>
        </div>
        <div className='box_form'>
          <div className='left_form'>
            รูปภาพ
          </div>
          <div className='right_form'>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  theme_standard_upload_image
                  acceptType="cover"
                  maxMBFileSizeWarning="1"
                  {...field}
                />
              )}
              name="img_path"
              defaultValue={[]}
            />
          </div>
        </div>
        <div className='box_form'>
          <div className='left_form'>
            เนื้อข่าว
          </div>
          <div className='right_form'>
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextArea
                  {...field}
                  placeholder='เนื้อข่าว'
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
        <div className='btn_action_price'>
          <div className='btn175'>
            <Buttons.BtnNormal
              theme_normal_btn
              label="ย้อนกลับ"
              onClick={handleBack}
            />
          </div>
          <div className='btn175'>
            <Buttons.BtnNormal
              theme_normal_btn
              label="ยืนยัน"
              type='submit'
              disabled={disabled}
            />
          </div>
        </div>
      </form>
    </NewsFormStyled>
  );
};

NewsForm.propTypes = {};

export default NewsForm;
