import React, { useState } from 'react';
import cx from 'classnames';
import { InputFilterStyled } from './styled';
import { Inputs, Icons, Buttons } from 'components';
import momentTZ from 'moment-timezone';

const InputFilter = ({
  theme_standard_input_filter,
  dataFilter,
  onQueryFilter,
  btnLabel,
}) => {
  const customClass = cx({
    theme_standard_input_filter: theme_standard_input_filter,
  });

  const [_filter, _setFilter] = useState({});

  // useEffect(() => {
  //   if (Object.keys(_filter).length === 0) {
  //   } else {
  //     let queryArr = [];
  //     let query = '';
  //     Object.keys(_filter).forEach((key) => {
  //       if (_filter[key].value) {
  //         queryArr.push(`${_filter[key].fieldQuery}=${_filter[key].value}`);
  //       }
  //     });
  //     if (queryArr.length > 0) {
  //       query = `?${queryArr.join('&')}`;
  //     }
  //     onQueryFilter && onQueryFilter(query);
  //   }
  // }, [_filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickFilter = () => {
    if (Object.keys(_filter).length === 0) {
    } else {
      let queryArr = [];
      let query = '';
      Object.keys(_filter).forEach((key) => {
        if (_filter[key].value && _filter[key].type !== 'dateGroup') {
          queryArr.push(`${_filter[key].fieldQuery}=${_filter[key].value}`);
        } else {
          queryArr.push(`${_filter[key].value}`);
        }
      });
      if (queryArr.length > 0) {
        query = `?${queryArr.join('&')}`;
      }
      onQueryFilter && onQueryFilter(query);
    }
  };

  const renderFilter = (e) => {
    switch (e.filter.type) {
      case 'text':
        return (
          <Inputs.InputTextField
            theme_filter
            label={e.filter.label}
            placeholder={e.filter.placeholder && e.filter.placeholder}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: f.target.value,
                },
              });
            }}
          />
        );

      case 'date':
        return (
          <Inputs.InputDatePikerCustom
            label={e.filter.label}
            theme_standard_date_picker_custom
            placeholder={e.filter.placeholder && e.filter.placeholder}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: momentTZ(f).format('YYYY-MM-DD'),
                },
              });
            }}
          />
        );

      case 'dropdown':
        return (
          <Inputs.InputDropDownFilter
            iconSvgClose={<Icons.ArrowDropDown />}
            iconSvgOpen={<Icons.ArrowDropDown up />}
            placeholder={e.placeHolder}
            options={e.filter.options}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: f,
                },
              });
            }}
          />
        );

      case 'label':
        return <div className={`box_label active`}>{e.labelName}</div>;

      case 'dateGroup':
        return (
          <Inputs.DateGroup
            initialValues={e.filter.initialValues}
            iconSvgClose={<Icons.ArrowDropDown />}
            iconSvgOpen={<Icons.ArrowDropDown up />}
            placeholder={e.filter.placeholder}
            options={e.filter.options}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: f,
                },
              });
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <InputFilterStyled>
      <div className={customClass}>
        {dataFilter &&
          dataFilter.map((e, i) => (
            <div key={i}>
              <div key={i + 1} className="box_filter">
                {e.label && <div className="label_top">{e.label}</div>}
                <div key={i} style={{ width: `${e.length}` }}>
                  {renderFilter(e)}
                </div>
              </div>
            </div>
          ))}
        <div className="btn_action_filter">
          <Buttons.BtnNormal
            theme_filter
            label={btnLabel}
            height="100%"
            onClick={clickFilter}
          />
        </div>
      </div>
    </InputFilterStyled>
  );
};

InputFilter.propTypes = {};

export default InputFilter;
