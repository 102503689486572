import styled from 'styled-components';

export const LoginFormStyled = styled.div`
  padding: 24px;
  .f_title {
    text-align: center;
    margin-bottom: 16px;
  }
  .input_wrap {
    &.mb_24 {
      margin-bottom: 24px;
    }
  }
`;
