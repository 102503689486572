import styled from 'styled-components';

export const LeftRightLabelStyled = styled.div`
  .continaer_left_right {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    .box_right {
      display: flex;
      align-items: baseline;
      .label2 {
        margin-right: 25px;
      }
    }
  }

  .theme_normal_left_right {

  }


`;
