import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputTextAreaStyled } from './styled';

const InputTextArea = ({
  theme_standard,
  disabled,
  styCus,
  errors,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputTextAreaStyled disabled={disabled}>
      <div className={customClass}>
        <textarea
          style={styCus && { ...styCus }}
          {...props}
          disabled={disabled}
        />
         {errors && <div className="error">*{errors}</div>}
      </div>
    </InputTextAreaStyled>
  );
};

InputTextArea.propTypes = {};

export default InputTextArea;
