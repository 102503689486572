export const RenderStatusName = (e) => {
  switch (e) {
    case "1":
      return 'รอดำเนินการ';

    case "2":
      return 'เตรียมจัดส่ง';

    case "3":
      return 'จัดส่ง';

    case "4":
      return 'สำเร็จ';

    default:
      return '-';
  }
};
