import React from "react";
// import PropTypes from 'prop-types'
import { BtnNormalStyled } from "./styled";
import cx from "classnames";

const BtnNormal = ({
  theme_normal_btn,
  theme_filter,
  theme_delete,
  bgColor,
  height,
  type = "button",
  label,
  onClick,
  disabled,
  icon,
}) => {
  const customClass = cx({
    theme_normal_btn: theme_normal_btn,
    theme_filter: theme_filter,
    theme_delete: theme_delete,
  });

  return (
    <BtnNormalStyled height={height} disabled={disabled} bgColor={bgColor}>
      <div className={customClass}>
        <button
          className="btn"
          type={type}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && <div className="show_icon">{icon}</div>}
          <div>{label}</div>
        </button>
      </div>
    </BtnNormalStyled>
  );
};

BtnNormal.propTypes = {};

export default BtnNormal;
