import styled from 'styled-components';

export const NoOffStyled = styled.div`
  .container_on_off {
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    display: flex;
    .on_color {
      &.active {
        color: ${({ theme }) => theme.COLORS.GREEN_3};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
    .off_color {
      color: ${({ theme }) => theme.COLORS.RED_2};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      &.active {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }
`;
