import React from 'react';
// import PropTypes from 'prop-types'
import { IconLabelStyled } from './styled';
import cx from 'classnames';

const IconLabel = ({
  theme_standard_icon_label,
  theme_bold,
  theme_sidebar,
  icon,
  label,
  labelNum,
  fontColor,
  active,
  fontSize,
}) => {
  const customClass = cx({
    theme_standard_icon_label: theme_standard_icon_label,
    theme_bold: theme_bold,
    theme_sidebar: theme_sidebar,
  });

  return (
    <IconLabelStyled fontColor={fontColor} fontSize={fontSize} active={active}>
      <div className={customClass}>
        <div className="continaer_icon_label">
          {icon && <div className="icon_front">{icon}</div>}
          <div className="label_show">{label}</div>
          {labelNum && <div className="num_show">{labelNum}</div>}
          {active && <div className="icl_line_active" />}
        </div>
      </div>
    </IconLabelStyled>
  );
};

IconLabel.propTypes = {};

export default IconLabel;
