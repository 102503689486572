import { combineReducers } from 'redux';
import authenReducer from './authenReducer';
import dataShop from './dataShop';

const rootReducer = combineReducers({
  authenRedux: authenReducer,
  dataShop: dataShop,
});

export default rootReducer;
