import styled from 'styled-components';

export const ProductRegisterDetailContainerStyled = styled.div`
    .loading_show {
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 17px;
        display: flex;
        .mr20 {
            margin-right: 20px;
        }
    }
    .sub_title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 10px;
        display: flex;
        &.mt37 {
            margin-top: 37px;
        }
        .line {
            text-decoration: underline;
            margin-right: 20px;
        }
        .bold {
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        }
    }
    .box_img {
        margin-bottom: 28px;
        background: #e5e5e5;
        height: 250px;
        width: 250px;
        &.hide {
            display: none;
        }
    }
    .box_sub_detail {
        display: flex;
        margin-bottom: 28px;
        .group_box {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            &.mr200 {
                margin-right: 200px;
            }
            &.mr400 {
                margin-right: 400px;
            }
            .line_box {
                display: flex;
                align-items: center;
                .left_label {
                    width: 160px;
                    margin-right: 20px;
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
                    color: ${({ theme }) => theme.COLORS.BLACK_1};
                }
                .right_label {
                    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                    color: ${({ theme }) => theme.COLORS.BLACK_1};
                    .group_accept {
                        display: flex;
                        column-gap: 3px;
                        .accept {
                            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
                            color: ${({ theme }) => theme.COLORS.GREEN_2};
                        }
                        .no_accept {
                            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                            color: ${({ theme }) => theme.COLORS.BLACK_1};
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .table_zone {
        .title_table_zone {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            text-decoration: underline;
            margin-bottom: 10px;
        }
    }
`;
