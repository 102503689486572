import React from 'react';
import { RedemptionStatisticContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { Tables, Icons, Displays, Inputs } from 'components';
import { retailerService } from 'apiServices';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { RenderSortTable } from 'utils/functions/SortTable';
import { connect } from 'react-redux';
import { setReduxDataShop } from 'store/actions';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class RedemptionStatisticContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    isLoadingTable: true,
    filterQuery: null,
    perPage: 10,
    sortTable: {
      reward_name: 1,
      total_amount: 1,
      redeem_amount: 1,
      remain: 1,
      is_active: 1,
    },
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN()
    this.fetchData(`?limit=${perPage}&use_condition=3`);
  }

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleSelectedPerPage = (e) => {
    const { filterQuery } = this.state;

    this.setState({
      perPage: e,
    });
    this.handleJoinQuery(filterQuery, e, 1);
  };

  handleFilterSelect = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&use_condition=3`
      : `?limit=${per_page}&page=${page}&use_condition=3`;
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(joinQuery);
  };

  fetchData = async (stringQuery) => {
    let res = await retailerService.GET_WHEEL_GAME_REWARD_LIST(stringQuery);

    if (res && res.status === 200) {
      let tempData = [];
      res.data.docs.forEach((e) => {
        tempData.push({ ...e, remain: e.total_amount - e.redeem_amount });
      });
      this.setState({
        // data: res.data.docs,
        data: tempData,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleClickRewardSendSuccess = (e) => {
    this.props.setReduxDataShop(e.name);
    this.props.router.navigate(
      `${CONSUMER_ROUTE_PATH.REDEMPTION_STATISTIC}/${e._id}`
    );
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;

    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case 'reward_name':
          sortValue = RenderSortTable(e, data, sortTable.reward_name);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              reward_name: sortValue.sortNumber,
            },
          });

          break;

        case 'total_amount':
          sortValue = RenderSortTable(e, data, sortTable.total_amount);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              total_amount: sortValue.sortNumber,
            },
          });

          break;

        case 'redeem_amount':
          sortValue = RenderSortTable(e, data, sortTable.redeem_amount);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              redeem_amount: sortValue.sortNumber,
            },
          });

          break;

        case 'remain':
          sortValue = RenderSortTable(e, data, sortTable.remain);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              remain: sortValue.sortNumber,
            },
          });

          break;

        case 'is_active':
          sortValue = RenderSortTable(e, data, sortTable.is_active);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              is_active: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  render() {
    const { isLoading, data, pagination, isLoadingTable } = this.state;
    return (
      <RedemptionStatisticContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Redemption statistics</div>
            <div className="title_show">
              <Inputs.InputFilter
                theme_standard_input_filter
                dataFilter={dataFilter}
                btnLabel="เลือก"
                onQueryFilter={this.handleFilterSelect}
              />
            </div>
            <div className="title_show">
              <Tables.TableNormal
                theme_standard_table_normal
                showPagination
                useNo
                data={data}
                pagination={pagination}
                handlePageClick={this.handlePageClick}
                isLoading={isLoadingTable}
                columns={columns9({
                  onClickReward: this.handleClickRewardSendSuccess,
                  onSort: this.handleSort,
                })}
              />
            </div>
          </>
        )}
      </RedemptionStatisticContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'rewardBy',
    length: '311px',
    filter: {
      fieldQuery: 'name',
      type: 'text',
      placeholder: 'ค้นหาชื่อรางวัล',
    },
  },
];

const columns9 = ({ onClickReward, onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('reward_name')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('total_amount')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'total_amount',
    headerStyle: { display: 'flex', justifyContent: 'flex-end' },
    // style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div style={{ textAlign: 'right' }}>
        {RenderCommaMoney(Number(value), true)}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'right', cursor: 'pointer' }}>
        ออกแล้ว
        <div
          style={{
            marginLeft: '8px',
            display: 'flex',
            textDecoration: 'underline',
          }}
          onClick={() => onSort('reward_name')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'redeem_amount',
    headerStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '130px',
      flexShrink: 0,
    },
    style: { width: '130px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div
        onClick={() => onClickReward(origin)}
        style={{ textDecoration: 'underline', textAlign: 'right' }}
      >
        {RenderCommaMoney(Number(value), true)}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'right', cursor: 'pointer' }}>
        คงเหลือ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('remain')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'remain',
    headerStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '130px',
      flexShrink: 0,
    },
    style: { textAlign: 'right', width: '130px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div>
        {' '}
        {RenderCommaMoney(
          Number(origin.total_amount - origin.redeem_amount),
          true
        )}
      </div>
    ),
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       กำลังนำส่ง
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'send',
  //   headerStyle: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     width: '130px',
  //     flexShrink: 0,
  //   },
  //   style: { textAlign: 'right', width: '130px', flexShrink: 0 },
  //   render: ({ origin, value }) => <div>{value}</div>,
  // },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       ส่งสำเร็จ
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'success',
  //   headerStyle: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     width: '130px',
  //     flexShrink: 0,
  //   },
  //   style: { textAlign: 'right', width: '130px', flexShrink: 0 },
  //   render: ({ origin, value }) => (
  //     <div
  //       style={{ textDecoration: 'underline' }}
  //       onClick={() => onClickReward('rw12')}
  //     >
  //       {value}
  //     </div>
  //   ),
  // },
];

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  setReduxDataShop: (data) => dispatch(setReduxDataShop(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedemptionStatisticContainer));