import React from 'react';
import { UserDataUpdateContainerStyled } from './styled';
import { Tables, Icons, Displays, Buttons } from 'components';
import { withRouter } from 'navigations/withRouter';
import { RETAILER_ROUTE_PATH } from 'utils/constants/routePath';
import { retailerService } from 'apiServices';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { RenderTypeName } from 'utils/functions/TypeName';
import { setReduxDataShop } from 'store/actions';
import { connect } from 'react-redux';
import { RenderSortTable } from 'utils/functions/SortTable';
import { IMG_PATH } from 'utils/constants/imgPath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class UserDataUpdateContainer extends React.Component {
  state = {
    isLoading: true,
    dataBranch: [],
    dataProductRegis: [],
    dataReward: [],
    sortTableBranch: {
      created_at: 1,
      store_name: 1,
      title: 1,
      firstname: 1,
      lastname: 1,
      phone_number: 1,
    },
    sortTableReward: {
      shipping_ref_no: 1,
      shipping_brand: 1,
      shipping_date: 1,
      created_at: 1,
      address_province: 1,
      name: 1,
    },
    sortTableRegis: {
      point: 1,
      product_model: 1,
      install_position: 1,
      product_type: 1,
      car_year: 1,
      car_model: 1,
      car_brand: 1,
      car_plate: 1,
      serial_no: 1,
    },
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchDetail(),
      this.fetchPrdouctRegis(),
      this.fetchBranch(),
      this.fetchAllReward(),
    ]);
    if (res[0] && res[1] && res[2] && res[3]) {
      this.setState({
        data: res[0],
        dataProductRegis: res[1],
        dataBranch: res[2].docs,
        dataReward: res[3],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchDetail = async () => {
    let res = await retailerService.GET_RETAILER_DETAIL(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      return res.data;
    }
  };

  fetchPrdouctRegis = async () => {
    let res = await retailerService.GET_PRODUCT_REGISTED_LIST_RETAILER(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      return res.data;
    }
  };

  fetchBranch = async () => {
    let res = await retailerService.GET_RETAILER_LIST(
      `?parentRetailerRefId=${this.props.router.params.id}`
    );

    if (res && res.status === 200) {
      return res.data;
    }
  };

  fetchAllReward = async () => {
    let res = await retailerService.GET_WHEEL_GAME_HISTORY_LIST(
      `?customer_ref_id=${this.props.router.params.id}&memberType=1`
    );
    let res2 = await retailerService.GET_REWARD_HISTORY_LIST(
      `?customer_ref_id=${this.props.router.params.id}&memberType=1`
    );

    if (res && res.status === 200 && res2 && res2.status === 200) {
      let returnData = [];

      if (res.data.docs.length > 0) {
        res.data.docs.forEach((e) => {
          returnData.push({ ...e, reward_type: 'Wheel game' });
        });
      }

      if (res2.data.docs.length > 0) {
        res2.data.docs.forEach((e) => {
          returnData.push({ ...e, reward_type: 'Reward' });
        });
      }

      return returnData;
    }
  };

  handleEdit = () => {
    const { dataProductRegis, data, dataReward, dataBranch } = this.state;

    this.props.setReduxDataShop({
      regis_amount: RenderCommaMoney(Number(dataProductRegis.length), true),
      point: RenderCommaMoney(Number(data?.point), true),
      reward_amount: RenderCommaMoney(Number(dataReward.length), true),
      quota: RenderCommaMoney(Number(data?.prize_wheel_quota), true),
      branch_amount: RenderCommaMoney(Number(dataBranch.length), true),
    });
    this.props.router.navigate(
      `${RETAILER_ROUTE_PATH.USER_DATA_EDIT}/${this.props.router.params.id}`
    );
  };

  handleSortDataBranch = (e) => {
    const { dataBranch, sortTableBranch } = this.state;

    if (dataBranch && dataBranch.length > 0) {
      let sortValue;
      switch (e) {
        case 'created_at':
          sortValue = RenderSortTable(
            e,
            dataBranch,
            sortTableBranch.created_at
          );
          this.setState({
            dataBranch: sortValue.data,
            sortTableBranch: {
              ...sortTableBranch,
              created_at: sortValue.sortNumber,
            },
          });

          break;

        case 'store_name':
          sortValue = RenderSortTable(
            e,
            dataBranch,
            sortTableBranch.store_name
          );
          this.setState({
            dataBranch: sortValue.data,
            sortTableBranch: {
              ...sortTableBranch,
              store_name: sortValue.sortNumber,
            },
          });

          break;

        case 'firstname':
          sortValue = RenderSortTable(e, dataBranch, sortTableBranch.firstname);
          this.setState({
            dataBranch: sortValue.data,
            sortTableBranch: {
              ...sortTableBranch,
              firstname: sortValue.sortNumber,
            },
          });

          break;

        case 'lastname':
          sortValue = RenderSortTable(e, dataBranch, sortTableBranch.lastname);
          this.setState({
            dataBranch: sortValue.data,
            sortTableBranch: {
              ...sortTableBranch,
              lastname: sortValue.sortNumber,
            },
          });

          break;

        case 'phone_number':
          sortValue = RenderSortTable(
            e,
            dataBranch,
            sortTableBranch.phone_number
          );
          this.setState({
            dataBranch: sortValue.data,
            sortTableBranch: {
              ...sortTableBranch,
              phone_number: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  handleSortDataReward = (e) => {
    const { dataReward, sortTableReward } = this.state;

    if (dataReward && dataReward.length > 0) {
      let sortValue;
      switch (e) {
        case 'created_at':
          sortValue = RenderSortTable(
            e,
            dataReward,
            sortTableReward.created_at
          );
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              created_at: sortValue.sortNumber,
            },
          });

          break;

        case 'reward_detail.name':
          sortValue = RenderSortTable(e, dataReward, sortTableReward.name);
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              name: sortValue.sortNumber,
            },
          });

          break;

        case 'address_province':
          sortValue = RenderSortTable(
            e,
            dataReward,
            sortTableReward.address_province
          );
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              address_province: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_date':
          sortValue = RenderSortTable(
            e,
            dataReward,
            sortTableReward.shipping_date
          );
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              shipping_date: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_brand':
          sortValue = RenderSortTable(
            e,
            dataReward,
            sortTableReward.shipping_brand
          );
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              shipping_brand: sortValue.sortNumber,
            },
          });

          break;

        case 'shipping_ref_no':
          sortValue = RenderSortTable(
            e,
            dataReward,
            sortTableReward.shipping_ref_no
          );
          this.setState({
            dataReward: sortValue.data,
            sortTableReward: {
              ...sortTableReward,
              shipping_ref_no: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  handleSortDataRegis = (e) => {
    const { dataProductRegis, sortTableRegis } = this.state;

    if (dataProductRegis && dataProductRegis.length > 0) {
      let sortValue;
      switch (e) {
        case 'point':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.point
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              point: sortValue.sortNumber,
            },
          });

          break;

        case 'product_model':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.product_model
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              product_model: sortValue.sortNumber,
            },
          });

          break;

        case 'install_position':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.install_position
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              install_position: sortValue.sortNumber,
            },
          });

          break;

        case 'product_type':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.product_type
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              product_type: sortValue.sortNumber,
            },
          });

          break;

        case 'car_year':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.car_year
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              car_year: sortValue.sortNumber,
            },
          });

          break;

        case 'car_model':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.car_model
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              car_model: sortValue.sortNumber,
            },
          });

          break;

        case 'car_brand':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.car_brand
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              car_brand: sortValue.sortNumber,
            },
          });

          break;

        case 'car_plate':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.car_plate
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              car_plate: sortValue.sortNumber,
            },
          });

          break;

        case 'serial_no':
          sortValue = RenderSortTable(
            e,
            dataProductRegis,
            sortTableRegis.serial_no
          );
          this.setState({
            dataProductRegis: sortValue.data,
            sortTableRegis: {
              ...sortTableRegis,
              serial_no: sortValue.sortNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  };

  render() {
    const { isLoading, data, dataProductRegis, dataBranch, dataReward } =
      this.state;
    return (
      <UserDataUpdateContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">
              <div>Retailer data</div>
              <div className="btn_w">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="แก้ไข"
                  onClick={this.handleEdit}
                />
              </div>
            </div>
            <div className="sub_title_show">รายละเอียดร้านค้า</div>
            {data ? (
              <>
                <div className="box_img">
                  <img
                    src={
                      data.img_path && data.img_path.includes('https')
                        ? data.img_path
                        : IMG_PATH + data.img_path
                    }
                    alt="img"
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div className="box_sub_detail">
                  <div className="group_box mr200">
                    <div className="line_box">
                      <div className="left_label">รหัสร้านค้า</div>
                      <div className="right_label">{data?.shop_no}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">ชื่อร้านค้า</div>
                      <div className="right_label">{data?.store_name}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">เลขบัตร</div>
                      <div className="right_label">{data?.tax_id}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">ที่อยู่</div>
                      <div className="right_label">
                        {`${data?.address} ต.${data?.sub_district} อ.${data?.district} จ.${data?.province} ${data?.zipcode}`}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">ชื่อติดต่อ</div>
                      <div className="right_label">
                        {`${data?.firstname} ${data?.lastname}`}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">เบอร์โทรศัพท์</div>
                      <div className="right_label">{data?.phone_number}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนสาขาย่อย</div>
                      <div className="right_label">
                        {dataBranch && dataBranch.length}
                      </div>
                    </div>
                  </div>
                  <div className="group_box">
                    <div className="line_box">
                      <div className="left_label">ประเภทร้าน</div>
                      <div className="right_label">
                        {RenderTypeName(data?.type)}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">PDPA</div>
                      <div className="right_label">
                        <div className="group_accept">
                          {data?.is_active_pdpa ? (
                            <div className="accept">ยอมรับ</div>
                          ) : (
                            <div className="no_accept">ไม่ยอมรับ</div>
                          )}
                        </div>
                        <div className="date_label">
                          {momentTZ(data?.pdpa_updated_at)
                            .tz('Asia/Bangkok')
                            .format('DD-MM-YYYY HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">นโยบาย กติกา</div>
                      <div className="right_label">
                        <div className="group_accept">
                          {data?.is_active_tc ? (
                            <div className="accept">ยอมรับ</div>
                          ) : (
                            <div className="no_accept">ไม่ยอมรับ</div>
                          )}
                        </div>
                        <div className="date_label">
                          {momentTZ(data?.tc_updated_at)
                            .tz('Asia/Bangkok')
                            .format('DD-MM-YYYY HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนลงทะเบียน</div>
                      <div className="right_label">
                        {RenderCommaMoney(
                          Number(dataProductRegis.length),
                          true
                        )}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนแต้ม</div>
                      <div className="right_label">
                        {RenderCommaMoney(Number(data?.reward_point), true)}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนสิทธิ์ที่เหลือ</div>
                      <div className="right_label">
                        {RenderCommaMoney(
                          Number(data?.prize_wheel_quota),
                          true
                        )}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนของรางวัลที่ได้รับ</div>
                      <div className="right_label">
                        {RenderCommaMoney(Number(dataReward.length), true)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table_zone">
                  <div className="title_table_zone">สาขาย่อย</div>
                  <Tables.TableNormal
                    theme_standard_table_normal
                    showPagination
                    useNo
                    data={dataBranch}
                    columns={columns4({
                      onSort: this.handleSortDataBranch,
                    })}
                  />
                </div>
                <div className="table_zone mt10">
                  <div className="title_table_zone">การได้รับรางวัล</div>
                  <Tables.TableNormal
                    theme_standard_table_normal
                    showPagination
                    useNo
                    data={dataReward}
                    columns={columns5({
                      onSort: this.handleSortDataReward,
                    })}
                  />
                </div>
                <div className="table_zone mt10">
                  <div className="title_table_zone">การลงทะเบียนสินค้า</div>
                  <Tables.TableNormal
                    theme_standard_table_normal
                    showPagination
                    useNo
                    data={dataProductRegis}
                    columns={columns6({
                      onSort: this.handleSortDataRegis,
                    })}
                  />
                </div>
              </>
            ) : (
              <div className="no_data">No data</div>
            )}
          </>
        )}
      </UserDataUpdateContainerStyled>
    );
  }
}

const columns5 = ({ onSort }) => [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       เลข S/N
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'sn_num',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'left' },
  //   render: ({ origin, value }) => (
  //     <div style={{ textDecoration: 'underline' }}>
  //       {value}
  //     </div>
  //   ),
  // },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รางวัลที่ได้รับ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('reward_detail.name')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'reward_detail.name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value ? value : ''}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รางวัลจาก
      </div>
    ),
    field: 'reward_type',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value ? value : ''}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('address_province')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address_province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่จัดส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_date')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_date',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>
        {value
          ? `${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`
          : ''}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_brand')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_brand',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value ? value : ''}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('shipping_ref_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shipping_ref_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value ? value : ''}</div>,
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       วันที่ได้รับ
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'date2',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'left' },
  //   render: ({ origin, value }) => (
  //     <div >
  //       {value}
  //     </div>
  //   ),
  // },
];

const columns4 = ({ onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อร้าน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('store_name')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'store_name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>{value}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('firstname')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'firstname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        นามสกุล
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('lastname')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'lastname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('phone_number')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone_number',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ที่อยู่
      </div>
    ),
    field: 'address',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>
        {`${origin.address} ต.${origin.sub_district} อ.${origin.district} จ.${origin.province} ${origin.zipcode}`}
      </div>
    ),
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       จำนวนลงทะเบียน
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'amount',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'center' },
  //   render: ({ origin, value }) => (
  //     <div >
  //       {value}
  //     </div>
  //   ),
  // },
];

const columns6 = ({ onSort }) => [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('created_at')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลข S/N
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('serial_no')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'serial_no',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>{value}</div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ทะเบียนรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_plate')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_plate',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       ผู้ลงทะเบียน
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'man_regis',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'left' },
  //   render: ({ origin, value }) => (
  //     <div >
  //       {value}
  //     </div>
  //   ),
  // },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ยี่ห้อรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_brand')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_brand',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นรถ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_model')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_model',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ปี
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('car_year')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_year',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทโช้คอัพ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('product_type')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_type',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ตำแหน่งโช้คอัพ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('install_position')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'install_position',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นสินค้า
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('product_model')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_model',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนแต้มที่ได้รับ
        <div
          style={{ marginLeft: '8px', display: 'flex' }}
          onClick={() => onSort('point')}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'point',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div>{RenderCommaMoney(Number(value), true)}</div>
    ),
  },
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxDataShop: (data) => dispatch(setReduxDataShop(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDataUpdateContainer));
