import React from 'react';
import { UserDataEditContainerStyled } from './styled';
import { Forms, Displays } from 'components';
import { withRouter } from 'navigations/withRouter';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { consumerService } from 'apiServices';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class UserDataEditContainer extends React.Component {
  state = {
    isLoading: true,
    initialValues: null,
    disabled: false,
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await consumerService.GET_CONSUMER_DETAIL(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleBack = () => {
    this.props.router.navigate(
      `${CONSUMER_ROUTE_PATH.USER_DATA}/${this.props.router.params.id}`
    );
  };

  handleSubmit = async (values) => {
    let params = {
      address: values.address,
      firstname: values.firstname,
      lastname: values.lastname,
      phone_number: values.phone_number,
      is_active_pdpa: values.is_active_pdpa,
      is_active_tc: values.is_active_tc,
      zipcode: values.zipcode,
      district: values.district.value,
      province: values.province.value,
      sub_district: values.sub_district.value,
    };

    this.setState({
      disabled: true,
    });

    let res = await consumerService.PUT_CONSUMER(
      this.props.router.params.id,
      params
    );

    if (res && res.status === 200) {
      toast.success('Edit success');
      this.props.router.navigate(
        `${CONSUMER_ROUTE_PATH.USER_DATA}/${this.props.router.params.id}`
      );
    } else {
      toast.error('Edit failed');
    }
  };

  render() {
    const { isLoading, initialValues, disabled } = this.state;
    const { dataShop } = this.props;

    return (
      <UserDataEditContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <Forms.ConsumerForm
            handleBack={this.handleBack}
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            disabled={disabled}
            dataShop={dataShop}
          />
        )}
      </UserDataEditContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  dataShop: state.dataShop,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDataEditContainer));
