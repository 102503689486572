import React from "react";
import { connect } from "react-redux";
import { RedemptionOnProcessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { CONSUMER_ROUTE_PATH } from "utils/constants/routePath";
import { retailerService } from "apiServices";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { RenderSortTable } from "utils/functions/SortTable";
import { setReduxDataShop } from "store/actions";
import * as XLSX from "xlsx";
import moment from "moment";
import { Buttons, Tables, Icons, Displays } from "components";

class RedemptionOnProcessContainer extends React.Component {
  state = {
    isLoading: true,
    isLoadingTable: true,
    filterQuery: null,
    sortTable: {
      reward_name: 1,
      totalCount: 1,
    },
  };

  componentDidMount() {
    this.fetchData(`?pagination=false&memberType=2&status_key=onprocess`);
  }

  fetchData = async (stringQuery) => {
    let res =
      await retailerService.GET_WHEEL_GAME_REWARD_ON_PROCESS_RETAILER_LIST(
        stringQuery
      );
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        isLoadingTable: false,
      });
    }
  };

  handleClickItem = (e) => {
    this.props.setReduxDataShop(e._id.reward_name);
    this.props.router.navigate(
      `${CONSUMER_ROUTE_PATH.REDEMPTION_PROCESS}/${e._id.reward_ref_id}`
    );
  };

  handleSort = (e) => {
    const { data, sortTable } = this.state;
    if (data && data.length > 0) {
      let sortValue;
      switch (e) {
        case "reward_name_group":
          sortValue = RenderSortTable(e, data, sortTable.reward_name);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              reward_name: sortValue.sortNumber,
            },
          });
          break;
        case "totalCount":
          sortValue = RenderSortTable(e, data, sortTable.totalCount);
          this.setState({
            data: sortValue.data,
            sortTable: {
              ...sortTable,
              totalCount: sortValue.sortNumber,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  handleDownloadExcel = async () => {
    let res = await retailerService.GET_WHEEL_GAME_HISTORY_LIST(
      `?reward_type=2&status=1&pagination=false`
    );
    var wb = XLSX.utils.book_new();
    var ws_name = "SheetJS";
    if (res && res.data && res.data.docs.length > 0) {
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.firstname_shipping
            ? `${e.firstname_shipping} ${e.lastname_shipping}`
            : `${e.consumer_detail.firstname} ${e.consumer_detail.lastname}`,
          e.phone_number_shipping
            ? e.phone_number_shipping
            : e.consumer_detail.phone_number,
          e.reward_name,
          1,
          moment(e.created_at).format("DD MMM YYYY"),
          e.shipping_date ? moment(e.shipping_date).format("DD MMM YYYY") : "-",
          e.shipping_brand,
          e.shipping_ref_no,
          e.address_province
            ? `${e.address} ${e.address_sub_district} ${e.address_district} ${e.address_province} ${e.address_zipcode} `
            : `${e.consumer_detail.province} ${e.consumer_detail.district} ${e.consumer_detail.sub_district} ${e.consumer_detail.zipcode} ${e.consumer_detail.address}`,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "ลำดับ",
        "ชื่อ-นามสกุล",
        "เบอร์ติดต่อ",
        "ของรางวัลที่แลก",
        "จำนวน",
        "วันที่แลก",
        "วันที่จัดส่ง",
        "ขนส่ง",
        "เลข Tracking",
        "ที่อยู่จัดส่ง",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 35 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Consumer Redemption on process report ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
    }
  };

  render() {
    const { isLoading, data, isLoadingTable } = this.state;
    return (
      <RedemptionOnProcessContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="header_row">
              <div className="title_show mb37">Redemption on process</div>
              <div className="btn_export_wrap">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="Export"
                  onClick={this.handleDownloadExcel}
                />
              </div>
            </div>
            <div className="title_show">
              <Tables.TableNormal
                theme_standard_table_normal
                useNo
                data={data}
                isLoading={isLoadingTable}
                columns={columns11({
                  onClickItem: this.handleClickItem,
                  onSort: this.handleSort,
                })}
              />
            </div>
          </>
        )}
      </RedemptionOnProcessContainerStyled>
    );
  }
}

const columns11 = ({ onClickItem, onSort }) => [
  {
    title: "ลำดับ",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        ชื่อของรางวัล
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("reward_name_group")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "_id.reward_name",
    headerStyle: { textAlign: "left" },
    style: { textAlign: "left" },
    render: ({ origin, value }) => <div>{value}</div>,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        กำลังนำส่ง
        <div
          style={{ marginLeft: "8px", display: "flex" }}
          onClick={() => onSort("totalCount")}
        >
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: "totalCount",
    headerStyle: {
      display: "flex",
      justifyContent: "center",
      width: "130px",
      flexShrink: 0,
    },
    style: { textAlign: "right", width: "130px", flexShrink: 0 },
    render: ({ origin, value }) => (
      <div
        style={{ textDecoration: "underline" }}
        onClick={() => onClickItem(origin)}
      >
        {RenderCommaMoney(Number(value), true)}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxDataShop: (data) => dispatch(setReduxDataShop(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedemptionOnProcessContainer));
