import styled from 'styled-components';

export const SidebarWidgetStyled = styled.div`
  min-height: calc(100% - 8px);
  width: 260px;
  padding: 0px 8px 8px 8px;
  background: ${({ theme }) => theme.COLORS.GREEN_1};
  .switch_system_wrap {
    width: 225px;
    margin: auto;
  }
  .sidbar_block_section {
    margin-bottom: 6px;
    padding: 12px;
    background: ${({ theme, bg }) => (bg ? bg : theme.COLORS.WHITE_1)};
    &.blue {
      background: #EAEDFC;
    }
    &.pink {
      background: #F7E4E4;
    }
    .sb_menu_item {
      cursor: pointer;
    }
  }
`;
