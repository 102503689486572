import styled from 'styled-components';

export const PrizesSortContainerStyled = styled.div`
  border-radius: 4px;
  padding: 12px;
  width: 70vw;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .prize_title {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn_wrap {
      margin-right: 14px;
      min-width: 120px;
    }
  }
  .prize_sort_body {
    padding: 0 12px;
    .item_wrap {
      margin-bottom: 8px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_2};
      padding: 8px;
      display: flex;
      justify-content: space-between;
      .ps_label {
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
      .ps_value {
      }
    }
  }
`;
