import React from "react";
import { BackgroundContainerStyled } from "./styled";
import { retailerService } from "apiServices";
import { Displays, Forms } from "components";
import { toast } from "react-toastify";

class BackgroundContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await retailerService.GET_RETAILER_BG_CONFIG_SYSTEM();
    if (res && res.status === 200) {
      this.setState({
        initialValues:
          res.data.docs && res.data.docs.length > 0 ? res.data.docs[0] : false,
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    const { initialValues } = this.state;
    const validateFile =
      values.bg_img_file &&
      values.bg_img_file.some((e) => e.isNewUpload || e.isDelete)
        ? true
        : false;
    if (!validateFile) {
      toast.error("กรุณาอัพโหลดรูป");
    } else {
      if (values.bg_img_file.length === 1 && values.bg_img_file[0].isDelete) {
        let params = {
          _id: initialValues._id,
          file_path: initialValues.bg_img_path,
        };
        let res = await retailerService.POST_RETAILER_DELETE_BG_CONFIG_SYSTEM(
          params
        );
        if (res && res.status === 200) {
          toast.success("ลบสำเร็จ");
          this.setState({
            isLoading: true,
          });
          this.fetchData();
        } else {
          toast.error("อัพโหลดไม่สำเร็จ");
        }
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append("image", values.bg_img_file[0]);
        let res = await retailerService.POST_RETAILER_UPLOAD_BG_CONFIG_SYSTEM(
          bodyFormData
        );
        if (res && res.status === 200) {
          toast.success("อัพโหลดสำเร็จ");
          this.setState({
            isLoading: true,
          });
          this.fetchData();
        } else {
          toast.error("อัพโหลดไม่สำเร็จ");
        }
      }
    }
  };

  render() {
    const { isLoading, initialValues } = this.state;
    return (
      <BackgroundContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Change Background</div>
            <Forms.BackgroundUploadForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </BackgroundContainerStyled>
    );
  }
}

export default BackgroundContainer;
