export const RenderTypeName = (e) => {
  switch (e) {
    case "1":
      return 'ร้านอะไหล่';

    case "2":
      return 'ศูนย์บริการติดตั้ง';

    case "3":
      return 'ร้านอะไหล่+ติดตั้ง';

    default:
      return '-';
  }
};
