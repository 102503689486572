export default function renderIcon({ width = "24", height = "24", color = '#7e84a3' }) {
  return (
    <svg id="Icons_Navigation_icon_12_states_" data-name="Icons / Navigation icon (12 states)" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic_dashboard">
        <g id="home">
          <path id="Path_372" data-name="Path 372" d="M0,0H24V24H0Z" fill="none" />
        </g>
        <path id="Shape" d="M13,20H1a1,1,0,0,1-1-1V6.5a2,2,0,0,1,2-2H6V2A2,2,0,0,1,8,0h4a2,2,0,0,1,2,2V9h4a2,2,0,0,1,2,2v8a1,1,0,0,1-1,1Zm5-2V11H14v7ZM8,18h4V11q0-.03,0-.059V2H8ZM2,18H6V6.5H2Z" transform="translate(2 2)" fill={color}/>
      </g>
    </svg>
  )
}
