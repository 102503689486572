import apiService from '../apiService';

const apiConsumerPath = '/api/v1/consumer';
// const apiPortalPath = '/api/v1/portal';

export const consumerService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiConsumerPath}/login`, params);
  },

   // CONSUMER
   GET_MY_PROFILE: () => {
    return apiService.get(`${apiConsumerPath}/admin/myProfile`);
  },
  GET_CONSUMER_LIST: (stringQuery) => {
    return apiService.get(`${apiConsumerPath}/consumer${stringQuery ? stringQuery : ''}`);
  },
  GET_CONSUMER_DETAIL: (id) => {
    return apiService.get(`${apiConsumerPath}/consumer/${id}`);
  },
  PUT_CONSUMER: (id, params) => {
    return apiService.put(`${apiConsumerPath}/consumer/${id}`, params);
  },

  //NEW CONSUMER
  GET_CONSUMER_NEW_LIST: (stringQuery) => {
    return apiService.get(`${apiConsumerPath}/c_news${stringQuery ? stringQuery : ''}`);
  },
  GET_CONSUMER_NEW_DETAIL: (id) => {
    return apiService.get(`${apiConsumerPath}/c_news/${id}`);
  },
  POST_CONSUMER_NEW: (params) => {
    return apiService.post(`${apiConsumerPath}/c_news`, params);
  },
  PUT_CONSUMER_NEW: (id, params) => {
    return apiService.put(`${apiConsumerPath}/c_news/${id}`, params);
  },
  DELETE_CONSUMER_NEW: (id) => {
    return apiService.delete(`${apiConsumerPath}/c_news/${id}`);
  },
  POST_UPLOAD_CONSUMER_NEW: (params) => {
    return apiService.post_formdata(`${apiConsumerPath}/c_news/upload`, params);
  },
  POST_DELETE_UPLOAD_CONSUMER_NEW: (params) => {
    return apiService.post(`${apiConsumerPath}/c_news/deleteUpload`, params);
  },

  //BANNER CONSUMER
  GET_CONSUMER_BANNER_LIST: (stringQuery) => {
    return apiService.get(`${apiConsumerPath}/c_banner${stringQuery ? stringQuery : ''}`);
  },
  GET_CONSUMER_BANNER_DETAIL: (id) => {
    return apiService.get(`${apiConsumerPath}/c_banner/${id}`);
  },
  POST_CONSUMER_BANNER: (params) => {
    return apiService.post(`${apiConsumerPath}/c_banner`, params);
  },
  PUT_CONSUMER_BANNER: (id, params) => {
    return apiService.put(`${apiConsumerPath}/c_banner/${id}`, params);
  },
  DELETE_CONSUMER_BANNER: (id) => {
    return apiService.delete(`${apiConsumerPath}/c_banner/${id}`);
  },
  POST_UPLOAD_CONSUMER_BANNER: (params) => {
    return apiService.post_formdata(`${apiConsumerPath}/c_banner/upload`, params);
  },
  POST_DELETE_UPLOAD_CONSUMER_BANNER: (params) => {
    return apiService.post(`${apiConsumerPath}/c_banner/deleteUpload`, params);
  },
};
