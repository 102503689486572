import styled from 'styled-components';

export const RedemptionStatisticContainerStyled = styled.div`
    .loading_show {
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title_show {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 17px;
        &.mb37 {
            margin-bottom: 37px;
        }
    }
    .btn_zone {
        display: flex;
        margin-bottom: 24px;
        justify-content: flex-end;
        .box_btn {
            width: 188px;
        }
    }
    .label_zone {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        margin-bottom: 11px;
    }
    .opt_select_2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .drop {
            width: 212px;
        }
    }
    .opt_zone {
        display: flex;
        margin-top: 34px;
        margin-bottom: 8px;
        column-gap: 10px;
        .btnw {
            width: 174px;
        }
    }
`;
