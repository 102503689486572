import styled from "styled-components";

export const UploadImageStyled = styled.div`
  .theme_standard_upload_image {
    display: flex;
    .body_upload {
      // border: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      // background: ${({ theme }) => theme.COLORS.WHITE_1};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      // width: 465px;
      width: 100%;
      height: 197px;
      margin-right: 62px;
      &.no_display {
        display: none;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .show_img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        // background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: relative;
        .close {
          position: absolute;
          right: 7px;
          top: 8px;
          cursor: pointer;
          z-index: 2;
        }
      }
    }
    .group_btn {
      display: flex;

      align-items: flex-end;
      column-gap: 1rem;
      .remove_btn {
        width: 152px;
      }
      .add_btn {
        width: 166px;
      }
    }
  }
`;
