import React from "react";
import { DashboardContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { connect } from "react-redux";
import { DashboardExportContainer } from "../Modals";
import { Displays, Buttons, Inputs, Tables, Modals } from "components";
import { consumerService, retailerService } from "apiServices";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";

class DashboardContainer extends React.Component {
  state = { isLoading: true, isLoadingTable: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async (
    query = `?pagination=false&start_date=${moment().format(
      "YYYY-MM-DD"
    )}&end_date=${moment().format("YYYY-MM-DD")}&memberType=2`
  ) => {
    let res = await Promise.all([
      this.fetchProductRegisted(query),
      this.fetchConsumerRegisted(query),
    ]);
    if (res) {
      this.setState({
        isLoading: false,
        data: res[0].data,
        carBrandRegis: res[0].carBrandRegis,
        productRegis: res[0].productRegis,
        provinceRegis: res[0].provinceRegis.filter((e) => e.province),
        retailerProductRegis: res[0].retailerProductRegis,
        provinceRetailerRegisted: res[1].provinceRetailerRegis.filter(
          (e) => e.province
        ),
        retailerRegisted: res[1].retailerRegis.filter((e) => e.phone_number),
        isLoadingTable: false,
      });
    }
  };

  fetchConsumerRegisted = async (query) => {
    let res = await consumerService.GET_CONSUMER_LIST(query);
    if (res && res.status === 200) {
      return {
        retailerRegis: res.data.docs,
        provinceRetailerRegis: res.data.docs.reduce((result, data, i) => {
          if (i === 0) {
            result.push({
              province: data.province,
              retailerList: [data],
              count: 1,
            });
          } else {
            let findProvinceIndex = result.findIndex(
              (e) => e.province === data.province
            );
            if (findProvinceIndex === -1) {
              result.push({
                province: data.province,
                retailerList: [data],
                count: 1,
              });
            } else {
              let resulttemp = result;
              resulttemp[findProvinceIndex].count += 1;
              resulttemp[findProvinceIndex].retailerList.push(data);
              result = resulttemp.sort(function (a, b) {
                return b.count - a.count;
              });
            }
          }
          return result;
        }, []),
      };
    }
  };

  fetchProductRegisted = async (query) => {
    let res = await retailerService.GET_PRODUCT_REGISTED_LIST(query);
    if (res && res.status === 200) {
      return {
        data: res.data,
        ...res.data.docs.reduce(
          (result, data, i) => {
            if (i === 0) {
              result.provinceRegis.push({
                province: data.retailer_detail.province,
                retailerList: [data],
                count: 1,
              });
              result.carBrandRegis.push({ ...data, count: 1 });
              result.productRegis.push({ ...data, count: 1 });
            } else {
              if (data.retailer_ref_id) {
                let findProvinceIndex = result.provinceRegis.findIndex(
                  (e) => e.province === data.retailer_detail.province
                );
                if (findProvinceIndex === -1) {
                  result.provinceRegis.push({
                    province: data.retailer_detail.province,
                    retailerList: [data],
                    count: 1,
                  });
                } else {
                  let provinceRegisTemp = result.provinceRegis;
                  provinceRegisTemp[findProvinceIndex].count += 1;
                  provinceRegisTemp[findProvinceIndex].retailerList.push(data);
                  result.provinceRegis = provinceRegisTemp.sort(function (
                    a,
                    b
                  ) {
                    return b.count - a.count;
                  });
                }
              }
              if (data.car_brand) {
                let findCarBrandRegis = result.carBrandRegis.findIndex(
                  (e) => e.car_brand === data.car_brand
                );
                if (findCarBrandRegis === -1) {
                  result.carBrandRegis.push({ ...data, count: 1 });
                } else {
                  let carBrandRegisTemp = result.carBrandRegis;
                  carBrandRegisTemp[findCarBrandRegis].count += 1;
                  result.carBrandRegis = carBrandRegisTemp.sort(function (
                    a,
                    b
                  ) {
                    return b.count - a.count;
                  });
                }
              }
              if (data.product_model) {
                let findProductRegis = result.productRegis.findIndex(
                  (e) => e.product_model === data.product_model
                );
                if (findProductRegis === -1) {
                  result.productRegis.push({ ...data, count: 1 });
                } else {
                  let productRegisTemp = result.productRegis;
                  productRegisTemp[findProductRegis].count += 1;
                  result.productRegis = productRegisTemp.sort(function (a, b) {
                    return b.count - a.count;
                  });
                }
              }
            }
            return result;
          },
          {
            provinceRegis: [],
            carBrandRegis: [],
            productRegis: [],
          }
        ),
      };
    }
  };

  handleClickExport = () => {
    this.setState({
      isShowModal: true,
    });
  };
  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleFilterKeyword = (e) => {
    this.setState({
      isLoadingTable: true,
    });
    this.fetchData(`${e}&pagination=false&memberType=2`);
  };

  render() {
    const {
      isShowModal,
      isLoading,
      data,
      carBrandRegis,
      productRegis,
      provinceRegis,
      retailerRegisted,
      provinceRetailerRegisted,
      isLoadingTable,
    } = this.state;
    return (
      <DashboardContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="top_sec">
              <div className="ts_left_col"></div>
              <div className="ts_right_col">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="All data"
                  onClick={this.handleClickExport}
                />
              </div>
            </div>
            <div className="bottom_sec">
              <div className="filter_wrap">
                <Inputs.InputFilter
                  theme_standard_input_filter
                  dataFilter={dataFilter}
                  btnLabel="เลือก"
                  onQueryFilter={this.handleFilterKeyword}
                />
              </div>
              <div className="row">
                <div className="left_col">
                  <div className="score_wrap">
                    <Displays.LeftRightLabel
                      label1="จำนวนลูกค้าลงทะเบียน"
                      label2={retailerRegisted.length || 0}
                      label3="คน"
                    />
                  </div>
                  <Tables.TableNormal
                    theme_standard_table_small
                    titleTable="10 จังหวัดที่ลูกค้าลงทะเบียนมากที่สุด"
                    useNo
                    columns={columnsRetailer}
                    data={provinceRetailerRegisted.slice(0, 10)}
                    isLoading={isLoadingTable}
                  />
                </div>
                <div className="right_col">
                  <div className="score_wrap">
                    <Displays.LeftRightLabel
                      label1="จำนวนลงทะเบียนสินค้า"
                      label2={data.totalDocs || 0}
                      label3="ครั้ง"
                    />
                  </div>
                  <Tables.TableNormal
                    theme_standard_table_small
                    titleTable="10 จังหวัดที่ลงทะเบียนสินค้ามากที่สุด"
                    useNo
                    columns={columnsProvince}
                    data={provinceRegis.slice(0, 10)}
                    isLoading={isLoadingTable}
                  />
                </div>
              </div>
              <div className="row">
                <div className="left_col">
                  <Tables.TableNormal
                    theme_standard_table_small
                    titleTable="10 ยี่ห้อลงทะเบียนมากที่สุด"
                    useNo
                    columns={columnsCarBrand}
                    data={carBrandRegis.slice(0, 10)}
                    isLoading={isLoadingTable}
                  />
                </div>
                <div className="right_col">
                  <Tables.TableNormal
                    theme_standard_table_small
                    titleTable="10 รุ่นสินค้าที่ลงทะเบียนมากที่สุด"
                    useNo
                    columns={columnsProduct}
                    data={productRegis.slice(0, 10)}
                    isLoading={isLoadingTable}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          <div className="m_dashboard_container">
            <DashboardExportContainer
              onCloseModal={this.handleCloseModal}
              data={data?.docs}
            />
          </div>
        </Modals.BodyEmpty>
      </DashboardContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "dateBy",
    placeHolder: "ระยะเวลา",
    filter: {
      fieldQuery: "types",
      type: "dateGroup",
      initialValues: { level1: "day" },
      options: [
        { label: "วันนี้", value: "day" },
        { label: "เดือนนี้", value: "month" },
        { label: "ไตรมาส", value: "quarter" },
        { label: "ระหว่างวันที่", value: "date_range" },
      ],
    },
  },
];

const columnsRetailer = [
  {
    title: "",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "",
    field: "province",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ value }) => `${value || "รอกรอกข้อมูล"}`,
  },
  {
    title: "",
    field: "count",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "right" },
    render: ({ value }) => `${value} ครั้ง`,
  },
];

const columnsProvince = [
  {
    title: "",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "",
    field: "province",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
    render: ({ value }) => value || "รอร้านค้า active",
  },
  {
    title: "",
    field: "count",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "right" },
    render: ({ value }) => `${value} ครั้ง`,
  },
];

const columnsCarBrand = [
  {
    title: "",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "",
    field: "car_brand",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "",
    field: "count",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "right" },
    render: ({ value }) => `${value} ครั้ง`,
  },
];

const columnsProduct = [
  {
    title: "",
    field: "no",
    headerStyle: { textAlign: "center", width: "50px", flexShrink: 0 },
    style: { textAlign: "center", width: "50px", flexShrink: 0 },
  },
  {
    title: "",
    field: "product_model",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "left" },
  },
  {
    title: "",
    field: "count",
    headerStyle: { textAlign: "center" },
    style: { textAlign: "right" },
    render: ({ value }) => `${value} ครั้ง`,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardContainer));
