import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputDropDownFilterStyled } from './styled';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import { Icons } from 'components';

const InputDropDownFilter = ({
  theme_fileter,
  theme_normal,
  theme_big,
  fontSize,
  label,
  iconSvgClose,
  iconSvgOpen,
  errors,
  onChangeCustom,
  onChange,
  placeholder = 'Select an option',
  options = defaultOptions,
  ...props
}) => {
  const customClass = cx({
    theme_fileter: theme_fileter,
    theme_normal: theme_normal,
    theme_big: theme_big,
  });
  return (
    <InputDropDownFilterStyled fontSize={fontSize}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="input_container">
          <Dropdown
            className="input_dropdown"
            controlClassName="input_dropdown_control"
            placeholderClassName="input_dropdown_placeholder"
            menuClassName="input_dropdown_menu"
            options={options}
            {...props}
            arrowClosed={
              <span className="arrow_icon arrow-closed">{iconSvgClose}</span>
            }
            arrowOpen={
              <span className="arrow_icon arrow-open">{iconSvgOpen}</span>
            }
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e.value);
              onChangeCustom && onChangeCustom(e.value);
            }}
          />
        </div>
        {errors && <div className="error">*{errors}</div>}
      </div>
    </InputDropDownFilterStyled>
  );
};

const defaultOptions = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two', className: 'myOptionClassName' },
  {
    type: 'group',
    name: 'group1',
    items: [
      { value: 'three', label: 'Three', className: 'myOptionClassName' },
      { value: 'four', label: 'Four' },
    ],
  },
  {
    type: 'group',
    name: 'group2',
    items: [
      { value: 'five', label: 'Five' },
      { value: 'six', label: 'Six' },
    ],
  },
];

InputDropDownFilter.propTypes = {};

export default InputDropDownFilter;
