import styled from "styled-components";

export const BtnNormalStyled = styled.div`
  height: 100%;
  .btn {
    display: flex;
    border-radius: 4px;
    width: 100%;
    padding: 11px;
    background: ${({ theme, disabled, bgColor }) =>
      disabled
        ? theme.COLORS.GRAY_5
        : bgColor
        ? bgColor
        : theme.COLORS.GREEN_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : ({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme, fontFamilyTop }) =>
      fontFamilyTop ? fontFamilyTop : theme.FONT.STYLE.MEDIUM};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    height: 100%;
    align-items: center;
    justify-content: center;
    .show_icon {
      display: flex;
      margin-right: 4px;
    }
  }

  .theme_normal_btn {
    height: 100%;
  }
  .theme_filter {
    .btn {
      padding: 10px 11px;
    }
  }

  .theme_delete {
    .btn {
      border: 1px solid ${({ theme }) => theme.COLORS.RED_1};
      padding: 10px;
      background: ${({ theme, disabled, bgColor }) =>
        disabled ? theme.COLORS.GRAY_5 : bgColor ? bgColor : "transparent"};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
`;
