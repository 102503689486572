export default function renderIcon({ width = "31", height = "33", color = '#909090' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="33" viewBox="0 0 31 33">
      <g id="Group_228" data-name="Group 228" transform="translate(-326 -480)">
        <g id="icon_navigation_arrow_drop_up_24px" data-name="icon/navigation/arrow_drop_up_24px" transform="translate(326 480)">
          <rect id="Boundary" width="24" height="24" fill="none" />
          <path id="_Color" data-name=" ↳Color" d="M0,5,5,0l5,5Z" transform="translate(7 9)" fill={color} />
        </g>
        <g id="icon_navigation_arrow_drop_down_24px" data-name="icon/navigation/arrow_drop_down_24px" transform="translate(333 489)">
          <rect id="Boundary-2" data-name="Boundary" width="24" height="24" fill="none" />
          <path id="_Color-2" data-name=" ↳Color" d="M0,0,5,5l5-5Z" transform="translate(7 10)" fill={color} />
        </g>
        <g id="icon_content_add_24px" data-name="icon/content/add_24px" transform="translate(326 485)">
          <rect id="Boundary-3" data-name="Boundary" width="24" height="24" fill="none" />
          <path id="_Color-3" data-name=" ↳Color" d="M8,8v6H6V0H8V8Z" transform="translate(5 5)" fill={color} />
          <path id="_Color-4" data-name=" ↳Color" d="M8,8v6H6V0H8V8Z" transform="translate(12 4)" fill={color} />
        </g>
      </g>
    </svg>
  )
}
