import styled from 'styled-components';

export const TitleSwitchLabelStyled = styled.div`
  .continaer_title_switch {
    border-radius: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.COLORS.BLUE_1};
    padding: 0px 10px;
    padding-left: 23px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    .ctw_col {
      flex: 1;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      text-decoration: underline;
      cursor: pointer;
      &.active {
        text-decoration: none;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        border-radius: 100px;
      }
    }
  }

  .theme_standard_title_switch {
  }
`;
