import styled from 'styled-components';

export const ComponentContainerStyled = styled.div`
    padding: 40px;
    .w100 {
        display: flex;
        margin-top: 20px;
        column-gap: 10px;
        .btnw {
            width: 147px;
        }
    }
    .w200 {
        margin-top: 20px;
        .title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            margin-bottom: 17px;
        }
        .opt_zone {
            display: flex;
            margin-top: 34px;
            margin-bottom: 8px;
            column-gap: 10px;
            .btnw {
                width: 174px;
            }
        }
        .opt_zone2 {
            margin-top: 19px;
            margin-bottom: 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            .labl_zone {
                margin-bottom: 18px;
            }
            .opt_select {
                display: flex;
                column-gap: 10px;
                .drop {
                    width: 212px;
                }
                .btnw {
                    width: 174px;
                }
            }
        }
    }
    .w1200 {
        margin-top: 20px;
        width: 1200px;
        .title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            margin-bottom: 17px;
            &.mb37 {
                margin-bottom: 37px;
            }
        }
        .btn_zone {
            display: flex;
            margin-bottom: 24px;
            justify-content: flex-end;
            .box_btn {
                width: 188px;
            }
        }
        .label_zone {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            margin-bottom: 11px;
        }
        .opt_select_2 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .drop {
                width: 212px;
            }
        }
        .opt_zone {
            display: flex;
            margin-top: 34px;
            margin-bottom: 8px;
            column-gap: 10px;
            .btnw {
                width: 174px;
            }
        }
    }
    .w300 {
        margin-top: 20px;
        .title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            margin-bottom: 17px;
            display: flex;
            .mr20 {
                margin-right: 20px;
            }
        }
        .sub_title_show {
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            margin-bottom: 10px;
            display: flex;
            &.mt37 {
                margin-top: 37px;
            }
            .line {
                text-decoration: underline;
                margin-right: 20px;
            }
            .bold {
                font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
            }
        }
        .box_sub_detail {
            display: flex;
            margin-bottom: 28px;
            .group_box {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                &.mr200 {
                    margin-right: 200px;
                }
                &.mr400 {
                    margin-right: 400px;
                }
                .line_box {
                    display: flex;
                    align-items: center;
                    .left_label {
                        width: 160px;
                        margin-right: 20px;
                        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
                        color: ${({ theme }) => theme.COLORS.BLACK_1};
                    }
                    .right_label {
                        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                        color: ${({ theme }) => theme.COLORS.BLACK_1};
                        .group_accept {
                            display: flex;
                            column-gap: 3px;
                            .accept {
                                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                                font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
                                color: ${({ theme }) => theme.COLORS.GREEN_2};
                            }
                            .no_accept {
                                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                                color: ${({ theme }) => theme.COLORS.BLACK_1};
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .table_zone {
            .title_table_zone {
                font-size: ${({ theme }) => theme.FONT.SIZE.S16};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                color: ${({ theme }) => theme.COLORS.BLACK_1};
                text-decoration: underline;
                margin-bottom: 10px;
            }
        }
    }
`;
