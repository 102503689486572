import styled from 'styled-components';

export const InputTextAreaStyled = styled.div`
  textarea {
    padding: 12px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
    width: calc(100% - 28px);
    resize: none;
    min-height: 145px;
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    // position: absolute;
  }

  .theme_standard {
  }
`;
