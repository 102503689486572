import React from 'react';
import { ComponentContainerStyled } from './styled';
import { Displays, Icons, Tables, Buttons, Inputs, Forms } from 'components';

class ComponentContainer extends React.Component {
  render() {
    return (
      <ComponentContainerStyled>
        <div style={{ background: '#307E8D', width: '300px' }}>
          <Displays.Profile />
        </div>
        <div style={{ width: '225px', marginBottom: '20px' }}>
          <Displays.TitleSwitchLabel
            labelLeft="Consumer"
            labelRight="Retailer"
          />
        </div>
        <div>
          <Displays.IconLabel
            icon={<Icons.Home />}
            theme_bold
            label="Dash Board"
            fontColor="#0058FF"
          />
          <Displays.IconLabel icon={<Icons.Graph />} label="Consumer data" />
          <Displays.IconLabel
            icon={<Icons.Graph />}
            label="Product register data"
          />
          <Displays.IconLabel
            icon={<Icons.Graph />}
            label="Wheel game prizes"
          />
          <Displays.IconLabel
            icon={<Icons.Graph />}
            label="Redemption statistics "
          />
          <Displays.IconLabel
            icon={<Icons.Graph />}
            label="Redemption on process"
          />
          <Displays.IconLabel
            icon={<Icons.Graph />}
            label="Redemption success"
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <Displays.IconLabel icon={<Icons.Papper />} label="Prizes" />
          <Displays.IconLabel
            icon={<Icons.Papper />}
            label="Redemption on process"
            labelNum="16"
          />
          <Displays.IconLabel
            icon={<Icons.Papper />}
            label="Redemption success"
          />
          <Displays.IconLabel icon={<Icons.Chat />} label="New" />
          <Displays.IconLabel icon={<Icons.Chat />} label="Banner" />
        </div>
        <div style={{ gridGap: '20px', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนร้านค้าลงทะเบียนวันนี้"
              label2="10"
              label3="คน"
            />
          </div>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนลงทะเบียนสินค้าวันนี้"
              label2="10"
              label3="คน"
            />
          </div>
        </div>
        <div style={{ gridGap: '20px', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนร้านค้าลงทะเบียนเดือนนี้"
              label2="150"
              label3="คน"
            />
          </div>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนลงทะเบียนสินค้าเดือนนี้"
              label2="150"
              label3="คน"
            />
          </div>
        </div>
        <div style={{ gridGap: '20px', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนร้านค้าลงทะเบียนทั้งหมด"
              label2="1,000,000"
              label3="คน"
            />
          </div>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนลงทะเบียนสินค้าทั้งหมด"
              label2="1,000,000"
              label3="คน"
            />
          </div>
        </div>
        <div style={{ width: '188px' }}>
          <Buttons.BtnNormal theme_normal_btn label="All data" />
        </div>
        <div>
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="เลือก"
          // onQueryFilter={this.handleFilterKeyword}
          />
        </div>
        <div style={{ gridGap: '20px', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนร้านค้าลงทะเบียน"
              label2="10"
              label3="คน"
            />
          </div>
          <div style={{ width: '400px' }}>
            <Displays.LeftRightLabel
              label1="จำนวนลงทะเบียนสินค้า"
              label2="10"
              label3="คน"
            />
          </div>
        </div>
        <div style={{ gridGap: '20px', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '391px' }}>
            <Tables.TableNormal
              theme_standard_table_small
              titleTable="10 จังหวัดที่ร้านค้าทะเบียนมากที่สุด"
              useNo
              data={tempData}
              columns={columns}
            />
          </div>
          <div style={{ width: '391px' }}>
            <Tables.TableNormal
              theme_standard_table_small
              titleTable="10 จังหวัดที่ทะเบียนสินค้ามากที่สุด"
              useNo
              data={tempData}
              columns={columns}
            />
          </div>
          <div style={{ width: '391px' }}>
            <Tables.TableNormal
              theme_standard_table_small
              titleTable="10 ยี่ห้อลงทะเบียนมากที่สุด"
              useNo
              data={tempData}
              columns={columns}
            />
          </div>
          <div style={{ width: '391px' }}>
            <Tables.TableNormal
              theme_standard_table_small
              titleTable="10 รุ่นสินค้าที่ลงทะเบียนมากที่สุด"
              useNo
              data={tempData}
              columns={columns}
            />
          </div>
        </div>
        <div className='w100'>
          <Inputs.InputDropDownFilter
            placeholder='เลือก'
            iconSvgClose={
              <Icons.ArrowDropDown />
            }
            iconSvgOpen={<Icons.ArrowDropDown up />}
            options={[
              { label: 'แสดง 10 แถว', value: '10' },
              { label: 'แสดง 25 แถว', value: '25' },
            ]}
          />
          <div className='btnw'>
            <Buttons.BtnNormal theme_normal_btn label="Export" />
          </div>
        </div>
        <div className='w100'>
          <Tables.TableNormal
            theme_standard_table_normal
            useNo
            // data={tempData}
            columns={columns2}
          />
        </div>
        <div className='w200'>
          <div className='title_show'>
            Retailer data
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter2}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData3}
              columns={columns3}
            />
          </div>
        </div>
        <div className='w300'>
          <div className='title_show'>
            Retailer data
          </div>
          <div className='sub_title_show'>
            รายละเอียดร้านค้า
          </div>
          <div className='box_sub_detail'>
            <div className='group_box mr200'>
              <div className='line_box'>
                <div className='left_label'>
                  รหัสร้านค้า
                </div>
                <div className='right_label'>
                  A0004
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ชื่อร้านค้า
                </div>
                <div className='right_label'>
                  หจก. รักยานยนต์
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  เลขบัตร
                </div>
                <div className='right_label'>
                  369220362158
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ที่อยู่
                </div>
                <div className='right_label'>
                  ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ชื่อติดต่อ
                </div>
                <div className='right_label'>
                  สาทิต ประเสริญศรี
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  เบอร์โทรศัพท์
                </div>
                <div className='right_label'>
                  0819999999
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  จำนวนสาขาย่อย
                </div>
                <div className='right_label'>
                  2
                </div>
              </div>
            </div>
            <div className='group_box'>
              <div className='line_box'>
                <div className='left_label'>
                  ประเภทร้าน
                </div>
                <div className='right_label'>
                  ร้านอะไหล่
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  PDPA
                </div>
                <div className='right_label'>
                  <div className='group_accept'>
                    <div className='accept' >
                      ยอมรับ
                    </div>
                    <div >
                      /
                    </div>
                    <div className='no_accept'>
                      ไม่ยอมรับ
                    </div>
                  </div>
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  นโยบาย กติกา
                </div>
                <div className='right_label'>
                  <div className='group_accept'>
                    <div className='accept' >
                      ยอมรับ
                    </div>
                    <div >
                      /
                    </div>
                    <div className='no_accept'>
                      ไม่ยอมรับ
                    </div>
                  </div>
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  จำนวนลงทะเบียน
                </div>
                <div className='right_label'>
                  220
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  จำนวนแต้ม
                </div>
                <div className='right_label'>
                  560
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  จำนวนสิทธิ์ที่เหลือ
                </div>
                <div className='right_label'>
                  2
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  จำนวนของรางวัลที่ได้รับ
                </div>
                <div className='right_label'>
                  8
                </div>
              </div>
            </div>
          </div>
          <div className='table_zone'>
            <div className='title_table_zone'>
              สาขาย่อย
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData4}
              columns={columns4}
            />
          </div>
          <div className='table_zone'>
            <div className='title_table_zone'>
              การได้รับรางวัล
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData5}
              columns={columns5}
            />
          </div>
          <div className='table_zone'>
            <div className='title_table_zone'>
              การลงทะเบียนสินค้า
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData6}
              columns={columns6}
            />
          </div>
        </div>
        <div className='w200'>
          <Forms.RetailerForm />
        </div>
        <div className='w200'>
          <div className='title_show'>
            Product register data
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter3}
              btnLabel="เลือก"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone'>
            <Inputs.InputDropDownFilter
              placeholder='เลือก'
              iconSvgClose={
                <Icons.ArrowDropDown />
              }
              iconSvgOpen={<Icons.ArrowDropDown up />}
              options={[
                { label: 'แสดง 10 แถว', value: '10' },
                { label: 'แสดง 25 แถว', value: '25' },
              ]}
            />
            <div className='btnw'>
              <Buttons.BtnNormal theme_normal_btn label="Export" />
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData7}
              columns={columns7}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Wheel game prizes
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData8}
              columns={columns8}
            />
          </div>
        </div>
        <div className='w300'>
          <div className='title_show'>
            Product register data
          </div>
          <div className='title_show'>
            <div className='mr20'>S/N Detail:</div>
            <div>SN123A123A125</div>
          </div>
          <div className='sub_title_show'>
            <div className='line'>
              ลงทะเบียนร้านค้าโดย
            </div>
            <div className='bold'>
              สาขาหลัก
            </div>
          </div>
          <div className='box_sub_detail'>
            <div className='group_box mr200'>
              <div className='line_box'>
                <div className='left_label'>
                  รหัสร้านค้า
                </div>
                <div className='right_label'>
                  A0004
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ชื่อร้านค้า
                </div>
                <div className='right_label'>
                  หจก. รักยานยนต์
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  เลขบัตร
                </div>
                <div className='right_label'>
                  369220362158
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ที่อยู่
                </div>
                <div className='right_label'>
                  ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ชื่อติดต่อ
                </div>
                <div className='right_label'>
                  สาทิต ประเสริญศรี
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  เบอร์โทรศัพท์
                </div>
                <div className='right_label'>
                  0819999999
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                จำนวนสาขาย่อย
                </div>
                <div className='right_label'>
                  2
                </div>
              </div>
            </div>
            <div className='group_box'>
              <div className='line_box'>
                <div className='left_label'>
                  ประเภทร้าน
                </div>
                <div className='right_label'>
                  ร้านอะไหล่
                </div>
              </div>
            </div>
          </div>
          <div className='table_zone'>
            <div className='title_table_zone'>
              สาขาย่อย
            </div>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData4}
              columns={columns4}
            />
          </div>
          <div className='sub_title_show mt37'>
            <div className='line'>
              รายละเอียดสินค้า
            </div>
          </div>
          <div className='box_sub_detail'>
            <div className='group_box mr400'>
              <div className='line_box'>
                <div className='left_label'>
                  ยี้ห้อรถ
                </div>
                <div className='right_label'>
                  Toyota
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  รุ่นรถ
                </div>
                <div className='right_label'>
                  Camry
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ปี
                </div>
                <div className='right_label'>
                  2010 - 2014
                </div>
              </div>
            </div>
            <div className='group_box'>
              <div className='line_box'>
                <div className='left_label'>
                  ประเภทโช้คอัพ
                </div>
                <div className='right_label'>
                  Xxxxxxxxxxxx
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  ตำแหน่งโช้คอัพ
                </div>
                <div className='right_label'>
                  Xxxxxxxxxxxx
                </div>
              </div>
              <div className='line_box'>
                <div className='left_label'>
                  รุ่นสินค้า
                </div>
                <div className='right_label'>
                  Xxxxxxxxxxxx
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Redemption statistics
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData9}
              columns={columns9}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Redemption statistics
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล:  สุดยอดบัตร`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData10}
              columns={columns10}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Redemption on process
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData11}
              columns={columns11}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Redemption on process
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล:  สุดยอดบัตร`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData12}
              columns={columns12}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Redemption success
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter}
              btnLabel="เลือก"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData13}
              columns={columns13}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Redemption success
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล:  สุดยอดบัตร`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData14}
              columns={columns14}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Prizes
          </div>
          <div className='btn_zone'>
            <div className='box_btn'>
              <Buttons.BtnNormal
                theme_normal_btn
                label="เพิ่มรางวัล"
              />
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData15}
              columns={columns15}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Prizes
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล:  สุดยอดบัตร`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData14}
              columns={columns14}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            Prizes
          </div>
          <div className='label_zone'>
            สร้างเมื่อวันที่  25/5/2565
          </div>
          <div className='title_show'>
            <Forms.PrizeForm />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show mb37'>
            Redemption on process
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData16}
              columns={columns16}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Redemption on process
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล: สุดยอดบัตร ของวันนี้ 10/5/2565`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData17}
              columns={columns17}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show mb37'>
            Redemption success
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData18}
              columns={columns18}
            />
          </div>
        </div>
        <div className='w200'>
          <div className='title_show'>
            Redemption success
          </div>
          <div className='title_show'>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter4}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='opt_zone2'>
            <div className='labl_zone'>
              {`ชื่อของรางวัล:  สุดยอดบัตร`}
            </div>
            <div className='opt_select'>
              <div className='drop'>
                <Inputs.InputDropDownFilter
                  placeholder='เลือก'
                  iconSvgClose={
                    <Icons.ArrowDropDown />
                  }
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={[
                    { label: 'แสดง 10 แถว', value: '10' },
                    { label: 'แสดง 25 แถว', value: '25' },
                  ]}
                />
              </div>
              <div className='btnw'>
                <Buttons.BtnNormal theme_normal_btn label="Export" />
              </div>
            </div>
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData14}
              columns={columns14}
            />
          </div>
        </div>
        <div className='w1200'>
          <div className='title_show'>
            New
          </div>
          <div className='btn_zone'>
            <div className='box_btn'>
              <Buttons.BtnNormal
                theme_normal_btn
                label="เพิ่มข่าว"
                icon={<Icons.Plus />}
              />
            </div>
          </div>
          <div className='opt_select_2'>
            <div className='drop'>
              <Inputs.InputDropDownFilter
                placeholder='เลือก'
                iconSvgClose={
                  <Icons.ArrowDropDown />
                }
                iconSvgOpen={<Icons.ArrowDropDown up />}
                options={[
                  { label: 'แสดง 10 แถว', value: '10' },
                  { label: 'แสดง 25 แถว', value: '25' },
                ]}
              />
            </div>
            <Inputs.InputFilter
              theme_standard_input_filter
              dataFilter={dataFilter5}
              btnLabel="ค้นหา"
            // onQueryFilter={this.handleFilterKeyword}
            />
          </div>
          <div className='title_show'>
            <Tables.TableNormal
              theme_standard_table_normal
              showPagination
              useNo
              data={tempData19}
              columns={columns19}
            />
          </div>
        </div>
        <Forms.NewsForm />
      </ComponentContainerStyled>
    );
  }
}

const columns19 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        Active
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'isActive',
    headerStyle: { display: 'flex', justifyContent: 'center', width: '100px', flexShrink: 0 },
    style: { textAlign: 'left', width: '100px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div style={{ width: '100px', flexShrink: 0 }}>
        <Displays.NoOff value={value} />
      </div>
    ),
  },
  {
    title: 'รูป',
    field: 'picture',
    headerStyle: { display: 'flex', justifyContent: 'left', width: '150px', flexShrink: 0 },
    style: { textAlign: 'left', width: '150px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div style={{ width: '150px', flexShrink: 0, height: '90px', background: '#ffff' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        หัวข้อข่าว
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'news',
    headerStyle: { display: 'flex', justifyContent: 'left', width: '500px', flexShrink: 0 },
    style: { textAlign: 'left', width: '500px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div style={{
        textDecoration: 'underline',
        overflow: 'hidden',
        width: '500px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}   >

        {value}
      </div>
    ),
  },
  {
    title: 'แก้ไข',
    field: 'picture',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        แก้ไข
      </div>
    ),
  },
];

const tempData19 = [
  {
    date1: '20/5/2022',
    isActive: true,
    picute: 'picture',
    news: 'ทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าว'
  },
  {
    date1: '20/5/2022',
    isActive: false,
    picute: 'picture',
    news: 'ทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าว'
  },
  {
    date1: '20/5/2022',
    isActive: false,
    picute: 'picture',
    news: 'ทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าว'
  },
  {
    date1: '20/5/2022',
    isActive: true,
    picute: 'picture',
    news: 'ทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าว'
  },
  {
    date1: '20/5/2022',
    isActive: true,
    picute: 'picture',
    news: 'ทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าวทดสอบหัวข้อข่าว'
  },
]

const columns18 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ส่งสำเร็จ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
];

const tempData18 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    remain: '939',
    send: '20',
  },
]

const columns17 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'man_win',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        สถาะน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'status',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ color: value === 'ส่งแล้ว' ? '#30BC76' : '#FF0000' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'company',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'num',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: 'Edit',
    field: 'date3',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        Edit
      </div>
    ),
  },
];

const tempData17 = [
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    status: 'ส่งแล้ว',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    status: 'ส่งแล้ว',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    status: 'ยังไม่ได้ส่ง',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    status: 'ส่งแล้ว',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    status: 'ยังไม่ได้ส่ง',
    company: 'Kerry',
    num: 'TH43544D4226',
  },

]

const columns16 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        คำร้องนำส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'request',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline', color: '#FF0000', fontWeight: 'bold' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        คงเหลือ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'remain',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        กำลังนำส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
];

const tempData16 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    request: '61',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    request: '61',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    request: '61',
    remain: '939',
    send: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    request: '61',
    remain: '939',
    send: '20',
  },
]

const columns15 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ออกแล้ว
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'out',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        คงเหลือ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'remain',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนแต้ม
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'count',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        แก้ไข
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date2',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        แก้ไข
      </div>
    ),
  },
];

const tempData15 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    count: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    count: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    count: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    count: '20',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    count: '20',
  },
]

const columns14 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'man_win',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date2',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'company',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'num',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ได้รับ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date3',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
];

const tempData14 = [
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
    date3: '20/5/2565',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
    date3: '20/5/2565',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
    date3: '20/5/2565',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
    date3: '20/5/2565',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
    date3: '20/5/2565',
  },
]

const columns13 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ส่งสำเร็จ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
];

const tempData13 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },

]

const columns12 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'man_win',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date2',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'company',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'num',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div  >
        {value}
      </div>
    ),
  },
];

const tempData12 = [
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
  {
    product_id: 'A0056',
    man_win: 'หจก. มีโชคประดับยนต์',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date2: '20/5/2565',
    company: 'Kerry',
    num: 'TH43544D4226',
  },
]

const columns11 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        กำลังนำส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
];

const tempData11 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    send: '21',
  },

]

const columns10 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ที่ได้รับรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'receive',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { display: 'flex', justifyContent: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData10 = [
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
  {
    product_id: 'A0002',
    receive: 'หจก. ขายดีโชคไพศาล',
    phone: '0819999999',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
  },
]

const columns9 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ออกแล้ว
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'out',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        คงเหลือ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'remain',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        กำลังนำส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ส่งสำเร็จ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'success',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData9 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '61',
    remain: '939',
    send: '21',
    success: '40',
  },
]

const columns8 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อของรางวัล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนของรางวัลทั้งหมด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ออกแล้ว
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'out',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        คงเหลือ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'remain',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { textAlign: 'right' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        สถานะ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'status',
    headerStyle: { display: 'flex', justifyContent: 'center' },
    style: { display: 'flex', justifyContent: 'center' },
    render: ({ origin, value }) => (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
        <div style={{ background: '#1EAC3E', width: '25%', height: '16px', borderRadius: '100px' }} />
      </div>
    ),
  },
];

const tempData8 = [
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
  {
    name: 'สุดยอดของรางวัล',
    amount: '1,000',
    out: '762',
    remain: '238',
    status: 'status',
  },
]

const columns7 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลข S/N
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'sn_num',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ทะเบียนรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'product_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ยี้ห้อรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'brand_car',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type_car',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ปี
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'year',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทโช้คอัพ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ตำแหน่งโช้คอัพ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'position',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นสินค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type_product',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData7 = [
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    product_id: 'A0001',
    province: 'นครศรีธรรมราช',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
  },
]

const columns6 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลข S/N
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'sn_num',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ทะเบียนรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'car_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ผู้ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'man_regis',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ยี้ห้อรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'brand_car',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นรถ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type_car',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ปี
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'year',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทโช้คอัพ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ตำแหน่งโช้คอัพ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'position',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รุ่นสินค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'type_product',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนแต้มที่ได้รับ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'point',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData6 = [
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    man_regis: 'สาขาหลัก',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
    point: '25',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    man_regis: 'สาขาหลัก',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
    point: '25',
  },
  {
    date1: '20/5/2565',
    sn_num: 'SN123A123A125',
    car_id: 'รวย 2365',
    man_regis: 'สาขาหลัก',
    brand_car: 'Toyota',
    type_car: 'Camry',
    year: '2010 - 2014',
    type1: 'Xxxxxxxxxxxx',
    position: 'Xxxxxxxxxxxx',
    type_product: 'Xxxxxxxxxxxx',
    point: '25',
  },
]

const columns5 = [
  {
    title: 'ชิ้นที่',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลข S/N
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'sn_num',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รางวัลที่ได้รับ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'reward',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ทำรายการ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date1',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date_send',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        บ. ที่จัดส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'company_send',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขที่ส่ง
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'send_num',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ได้รับ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date2',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData5 = [
  {
    sn_num: 'SN123A123A125',
    reward: 'สุดยอดของรางวัล',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date_send: '20/5/2565',
    company_send: 'Kerry',
    send_num: 'TH43544D4226',
    date2: '20/5/2565',
  },
  {
    sn_num: 'SN123A123A125',
    reward: 'สุดยอดของรางวัล',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date_send: '20/5/2565',
    company_send: 'Kerry',
    send_num: 'TH43544D4226',
    date2: '20/5/2565',
  },
  {
    sn_num: 'SN123A123A125',
    reward: 'สุดยอดของรางวัล',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date_send: '20/5/2565',
    company_send: 'Kerry',
    send_num: 'TH43544D4226',
    date2: '20/5/2565',
  },
  {
    sn_num: 'SN123A123A125',
    reward: 'สุดยอดของรางวัล',
    province: 'นครศรีธรรมราช',
    date1: '20/5/2565',
    date_send: '20/5/2565',
    company_send: 'Kerry',
    send_num: 'TH43544D4226',
    date2: '20/5/2565',
  },
]

const dataFilter5 = [
  {
    accessor: 'newsBy',
    length: '311px',
    filter: {
      fieldQuery: 'news',
      type: 'text',
      placeholder: 'ค้นหาหัวข้อข่าว',
    },
  },
];

const dataFilter = [
  {
    accessor: 'dateBy',
    placeHolder: 'ระยะเวลา',
    length: '176px',
    filter: {
      fieldQuery: 'types',
      type: 'dropdown',
      options: [
        { label: 'วันนี้', value: 'today' },
        { label: 'เดือนนี้', value: 'this_month' },
      ],
    },
  },
  {
    accessor: 'quaterBy',
    placeHolder: 'ไตรมาส',
    length: '176px',
    filter: {
      fieldQuery: 'quater',
      type: 'dropdown',
      options: [
        { label: 'ไตรมาส 1', value: 'q1' },
        { label: 'ไตรมาส 2', value: 'q2' },
        { label: 'ไตรมาส 3', value: 'q3' },
        { label: 'ไตรมาส 4', value: 'q4' },
      ],
    },
  },
  {
    accessor: 'startDateBy',
    length: '176px',
    filter: {
      fieldQuery: 'startDate',
      type: 'date',
    },
  },
  {
    accessor: 'endDateBY',
    length: '176px',
    filter: {
      fieldQuery: 'endDate',
      type: 'date',
    },
  },
  {
    accessor: 'all',
    length: '176px',
    labelName: 'ทั้งหมด',
    filter: {
      fieldQuery: 'all',
      type: 'label',
    },
  },
];


const dataFilter3 = [
  {
    accessor: 'dateBy',
    placeHolder: 'ระยะเวลา',
    length: '176px',
    filter: {
      fieldQuery: 'types',
      type: 'dropdown',
      options: [
        { label: 'วันนี้', value: 'today' },
        { label: 'เดือนนี้', value: 'this_month' },
      ],
    },
  },
  {
    accessor: 'quaterBy',
    placeHolder: 'ไตรมาส',
    length: '176px',
    filter: {
      fieldQuery: 'quater',
      type: 'dropdown',
      options: [
        { label: 'ไตรมาส 1', value: 'q1' },
        { label: 'ไตรมาส 2', value: 'q2' },
        { label: 'ไตรมาส 3', value: 'q3' },
        { label: 'ไตรมาส 4', value: 'q4' },
      ],
    },
  },
  {
    accessor: 'startDateBy',
    length: '176px',
    filter: {
      fieldQuery: 'startDate',
      type: 'date',
    },
  },
  {
    accessor: 'endDateBY',
    length: '176px',
    filter: {
      fieldQuery: 'endDate',
      type: 'date',
    },
  },
  {
    accessor: 'all',
    length: '176px',
    labelName: 'ทั้งหมด',
    filter: {
      fieldQuery: 'all',
      type: 'label',
    },
  },
  {
    accessor: 'provinceBy',
    placeHolder: 'จังหวัด',
    length: '162px',
    filter: {
      fieldQuery: 'province',
      type: 'dropdown',
      options: [
        { label: 'เชียงใหม่', value: '1' },
        { label: 'กรุงเทพ', value: '2' },
      ],
    },
  },
  {
    accessor: 'carBy',
    placeHolder: 'ยี่ห้อรถ',
    length: '162px',
    filter: {
      fieldQuery: 'car',
      type: 'dropdown',
      options: [
        { label: 'car 1', value: '1' },
        { label: 'car 2', value: '2' },
      ],
    },
  },
  {
    accessor: 'genBy',
    placeHolder: 'รุ่น',
    length: '150px',
    filter: {
      fieldQuery: 'gen',
      type: 'dropdown',
      options: [
        { label: 'รุ่น 1', value: '1' },
        { label: 'รุ่น 2', value: '2' },
      ],
    },
  },
  {
    accessor: 'yearBy',
    placeHolder: 'ปี',
    length: '150px',
    filter: {
      fieldQuery: 'year',
      type: 'dropdown',
      options: [
        { label: '2565', value: '1' },
        { label: '2564', value: '2' },
      ],
    },
  },
  {
    accessor: 'type1',
    placeHolder: 'ประเภทโช้คอัพ',
    length: '170px',
    filter: {
      fieldQuery: 'type1',
      type: 'dropdown',
      options: [
        { label: 'โช้คอัพ 1', value: '1' },
        { label: 'โช้คอัพ 2', value: '2' },
      ],
    },
  },
  {
    accessor: 'type1',
    placeHolder: 'ตำแหน่งโช้ค',
    length: '170px',
    filter: {
      fieldQuery: 'type2',
      type: 'dropdown',
      options: [
        { label: 'ตำแหน่งโช้ค 1', value: '1' },
        { label: 'ตำแหน่งโช้ค 2', value: '2' },
      ],
    },
  },
  {
    accessor: 'type2',
    placeHolder: 'รุ่นสินค้า',
    length: '170px',
    filter: {
      fieldQuery: 'type2',
      type: 'dropdown',
      options: [
        { label: 'รุ่นสินค้า 1', value: '1' },
        { label: 'รุ่นสินค้า 2', value: '2' },
      ],
    },
  },
];

const dataFilter4 = [
  {
    accessor: 'nameBy',
    length: '260px',
    filter: {
      fieldQuery: 'name',
      type: 'text',
      placeholder: 'ค้นหาชื่อ',
    },
  },
  {
    accessor: 'phoneBy',
    length: '260px',
    filter: {
      fieldQuery: 'phone',
      type: 'text',
      placeholder: 'ค้นหาเบอร์โทร',
    },
  },
  {
    accessor: 'provinceBy',
    length: '260px',
    filter: {
      fieldQuery: 'province',
      type: 'text',
      placeholder: 'ค้นหาจังหวัด',
    },
  },
];

const dataFilter2 = [
  {
    accessor: 'shopId',
    length: '260px',
    filter: {
      fieldQuery: 'shop_id',
      type: 'text',
      placeholder: 'ค้นหารหัสร้านค้า',
    },
  },
  {
    accessor: 'shopName',
    length: '260px',
    filter: {
      fieldQuery: 'shop_name',
      type: 'text',
      placeholder: 'ค้นหาชื่อร้านค้า',
    },
  },
  {
    accessor: 'personId',
    length: '374px',
    filter: {
      fieldQuery: 'person_id',
      type: 'text',
      placeholder: 'ค้นหาเลขผู้เสียภาษี/บัตรประชาชน',
    },
  },
];

const columns4 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'date',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อร้าน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shop_name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        นามสกุล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'surname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ที่อยู่
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'amount',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
];

const tempData4 = [
  {
    date: '20/5/2565',
    shop_name: 'รักยานยนต์ สาขายางนก',
    name: 'รวยยานยต์',
    surname: 'บุญรักษา',
    phone: '0816666666',
    address: 'ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์',
    amount: '12',
  },
  {
    date: '20/5/2565',
    shop_name: 'รักยานยนต์ สาขายางนก',
    name: 'รวยยานยต์',
    surname: 'บุญรักษา',
    phone: '0816666666',
    address: 'ที่อยู่ + ตำบล + อำเภอ + จังหวัด + รหัสไปรษณีย์',
    amount: '12',
  },
]

const tempData3 = [
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
  {
    shop_id: 'A0001',
    shop_name: 'หจก. รักยานยนต์',
    shop_type: 'ร้านอะไหล่',
    phone: '0889999999',
    province: 'กาญจนบุรี',
    person_id: '35216210569874',
    branch: '2',
    regis_amount: '20',
    point: '560',
    right_remain: '2',
    regis_date: '20/5/2565',
  },
]
const columns3 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        รหัสร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shop_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }} >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อร้านค้า
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shop_name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ประเภทร้าน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'shop_type',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จังหวัด
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'province',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เลขภาษี/บัตร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'person_id',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนสาขาย่อย
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'branch',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        จำนวนลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'regis_amount',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        แต้ม
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'point',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        สิทธ์คงเหลือ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'right_remain',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'regis_date',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: 'Edit',
    field: '',
    headerStyle: { textAlign: 'center', width: '60px', flexShrink: 0 },
    style: { textAlign: 'center', width: '60px', flexShrink: 0 },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        Edit
      </div>
    ),
  },
];

const columns = [
  {
    title: '',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: '',
    field: 'name',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: '',
    field: 'num',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'right' },
  },
  {
    title: '',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
];

const columns2 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: 'วันที่ลงทะเบียน',
    field: 'name',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'เลข S/N',
    field: 'num',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'right' },
  },
  {
    title: 'รหัสสินค้า',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ชื่อสินค้า',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'เลขบัตร',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ประเภทร้าน',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'สาขาหลัก/ย่อย',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'เบอร์โทรศัพท์',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ที่อยู่ของสาขาที่ลงทะเบียน',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'จังหวัด',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'เลขทะเบียนรถ',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ระยะกิโลเมตร',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ยี่ห้อรถ',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'รุ่นรถ',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ปี',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ประเภทโช้คอัพ',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'ตำแหน่งโช้คอัพ',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
  {
    title: 'รุ่นสินค้า',
    field: 'label',
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'left' },
  },
];


const tempData = [
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
  {
    name: 'Xxxxxxxxxxx',
    num: '2,000',
    label: 'คร้้ง',
  },
];

export default ComponentContainer;
