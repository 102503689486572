import React from 'react';
// import PropTypes from 'prop-types'
import { LeftRightLabelStyled } from './styled';
import cx from 'classnames';

const LeftRightLabel = ({
  theme_normal_left_right,
  label1,
  label2,
  label3,
}) => {
  const customClass = cx({
    theme_normal_left_right: theme_normal_left_right,
  });

  return (
    <LeftRightLabelStyled>
      <div className={customClass}>
        <div className='continaer_left_right'>
          <div className='label1'>
            {label1}
          </div>
          <div className='box_right'>
            <div className='label2'>
              {label2}
            </div>
            <div className='label3'>
              {label3}
            </div>
          </div>
        </div>
      </div>
    </LeftRightLabelStyled>
  );
};

LeftRightLabel.propTypes = {};

export default LeftRightLabel;
