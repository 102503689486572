import { useEffect, useState } from 'react';
// import PropTypes from 'prop-types'
import { TitleSwitchLabelStyled } from './styled';
import cx from 'classnames';

const TitleSwitchLabel = ({
  theme_standard_title_switch,
  active,
  fontColor,
  fontSize,
  onActive
}) => {
  const customClass = cx({
    theme_standard_title_switch: theme_standard_title_switch,
  });

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (active) {
      _setActive(active);
    }
  }, [active]);

  const _handleClickSystem = (e) => {
    _setActive(e.value);
    onActive && onActive(e.value);
  };

  return (
    <TitleSwitchLabelStyled fontColor={fontColor} fontSize={fontSize}>
      <div className={customClass}>
        <div className="continaer_title_switch">
          {systemList &&
            systemList.map((e, i) => (
              <div
                key={i}
                className={`ctw_col ${_active === e.value ? 'active' : ''}`}
                onClick={() => _handleClickSystem(e)}
              >
                {e.label}
              </div>
            ))}
        </div>
      </div>
    </TitleSwitchLabelStyled>
  );
};

const systemList = [
  {
    label: 'Consumer',
    value: 'consumer_system',
  },
  {
    label: 'Retailer',
    value: 'retailer_system',
  },
];

TitleSwitchLabel.propTypes = {};

export default TitleSwitchLabel;
