import styled from 'styled-components';

export const HeaderH1Styled = styled.div`
  font-size: ${({ theme }) => theme.FONT.SIZE.S22};
  font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
  color: ${({ theme }) => theme.COLORS.BLACK_1};

  .theme_standard {
  }
`;
