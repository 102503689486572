import React from 'react';
import { UserDataUpdateContainerStyled } from './styled';
import { Displays, Buttons } from 'components';
import { withRouter } from 'navigations/withRouter';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { consumerService } from 'apiServices';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import momentTZ from 'moment-timezone';
import { setReduxDataShop } from 'store/actions';
import { connect } from 'react-redux';
import { IMG_PATH } from 'utils/constants/imgPath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class UserDataUpdateContainer extends React.Component {
  state = {
    isLoading: true,
    dataBranch: [],
    dataProductRegis: [],
    dataReward: [],
    sortTableBranch: {
      created_at: 1,
      store_name: 1,
      title: 1,
      firstname: 1,
      lastname: 1,
      phone_number: 1,
    },
    sortTableReward: {
      shipping_ref_no: 1,
      shipping_brand: 1,
      shipping_date: 1,
      created_at: 1,
      address_province: 1,
      name: 1,
    },
    sortTableRegis: {
      point: 1,
      product_model: 1,
      install_position: 1,
      product_type: 1,
      car_year: 1,
      car_model: 1,
      car_brand: 1,
      car_plate: 1,
      serial_no: 1,
    },
  };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchDetail(),
      // this.fetchPrdouctRegis(),
      // this.fetchBranch(),
      // this.fetchAllReward(),
    ]);

    if (res[0]) {
      this.setState({
        data: res[0],
        // dataProductRegis: res[1],
        // dataBranch: res[2].docs,
        // dataReward: res[3],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchDetail = async () => {
    let res = await consumerService.GET_CONSUMER_DETAIL(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleEdit = () => {
    const { data } = this.state;

    this.props.setReduxDataShop({
      // regis_amount: RenderCommaMoney(Number(dataProductRegis.length), true),
      // point: RenderCommaMoney(Number(data?.point), true),
      // reward_amount: RenderCommaMoney(Number(dataReward.length), true),
      quota: RenderCommaMoney(Number(data?.prize_wheel_quota), true),
      // branch_amount: RenderCommaMoney(Number(dataBranch.length), true),
    });
    this.props.router.navigate(
      `${CONSUMER_ROUTE_PATH.USER_DATA_EDIT}/${this.props.router.params.id}`
    );
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <UserDataUpdateContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">
              <div>Consumer data</div>
              <div className="btn_w">
                <Buttons.BtnNormal
                  theme_normal_btn
                  label="แก้ไข"
                  onClick={this.handleEdit}
                />
              </div>
            </div>
            <div className="sub_title_show">รายละเอียดลูกค้า</div>
            {data ? (
              <>
                <div className="box_img">
                  <img
                    src={
                      data.img_path && data.img_path.includes('https')
                        ? data.img_path
                        : IMG_PATH + data.img_path
                    }
                    alt="img"
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div className="box_sub_detail">
                  <div className="group_box mr200">
                    <div className="line_box">
                      <div className="left_label">ชื่อ</div>
                      <div className="right_label">{data?.firstname}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">นามสกุล</div>
                      <div className="right_label">{data?.lastname}</div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">ที่อยู่</div>
                      <div className="right_label">
                        {`
                            ${data.address ? data.address : ''} 
                            ${
                              data.sub_district
                                ? ` ต.${data?.sub_district} `
                                : ''
                            } 
                            ${data.district ? ` อ.${data?.district} ` : ''} 
                            ${data.province ? ` จ.${data?.province} ` : ''} 
                            ${data.zipcode ? data.zipcode : ''} 
                            `}
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">เบอร์โทรศัพท์</div>
                      <div className="right_label">{data?.phone_number}</div>
                    </div>
                  </div>
                  <div className="group_box">
                    <div className="line_box">
                      <div className="left_label">PDPA</div>
                      <div className="right_label">
                        <div className="group_accept">
                          {data?.is_active_pdpa ? (
                            <div className="accept">ยอมรับ</div>
                          ) : (
                            <div className="no_accept">ไม่ยอมรับ</div>
                          )}
                        </div>
                        <div className="date_label">
                          {momentTZ(data?.pdpa_updated_at)
                            .tz('Asia/Bangkok')
                            .format('DD-MM-YYYY HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">นโยบาย กติกา</div>
                      <div className="right_label">
                        <div className="group_accept">
                          {data?.is_active_tc ? (
                            <div className="accept">ยอมรับ</div>
                          ) : (
                            <div className="no_accept">ไม่ยอมรับ</div>
                          )}
                        </div>
                        <div className="date_label">
                          {momentTZ(data?.tc_updated_at)
                            .tz('Asia/Bangkok')
                            .format('DD-MM-YYYY HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div className="line_box">
                      <div className="left_label">จำนวนสิทธิ์ที่เหลือ</div>
                      <div className="right_label">
                        {RenderCommaMoney(
                          Number(data?.prize_wheel_quota),
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="no_data">No data</div>
            )}
          </>
        )}
      </UserDataUpdateContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxDataShop: (data) => dispatch(setReduxDataShop(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDataUpdateContainer));
