import React, { useState, useEffect, forwardRef } from 'react';
import { InputDatePikerCustomStyled } from './styled';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import th from 'date-fns/locale/th';
import moment from 'moment';
import 'moment-timezone';
import { Icons } from 'components';

const InputDatePikerCustom = ({
  theme_standard_date_picker_custom,
  onChangeCustom,
  minDate,
  dateFormat,
  showYearPicker,
  showMonthYearPicker,
  showFullMonthYearPicker,
  showQuarterYearPicker,
  showTimeInput,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  maxDate,
  value,
  placeholder,
  onChange,
  errors,
  disabled,
  label,
}) => {
  const customClass = cx({
    theme_standard_date_picker_custom: theme_standard_date_picker_custom,
  });

  const [_value, _setValue] = useState();
  // const [_onClick] = useState(false);

  useEffect(() => {
    if (value !== _value) {
      if (!value) {
        // _setValue(new Date());
      } else {
        _setValue(new Date(value));
      }

    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePicker = (e) => {
    _setValue(e);
    onChangeCustom && onChangeCustom(e);
    onChange && onChange(e);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="custom_date" onClick={onClick} ref={ref}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className='show_value' style={{ width: '100%' }}>
          {
            _value ?
              `${moment(_value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`
              :
              placeholder
          }
        </div>
        <div><Icons.ArrowDropDown transform='translate(13 11) rotate(180)' /></div>
      </div>
    </button>
  ));

  return (
    <InputDatePikerCustomStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <DatePicker
          className="date_picker"
          selected={_value}
          onChange={(e) => {
            handleChangePicker(e);
          }}
          // locale={th}
          dateFormat={dateFormat ? dateFormat : 'dd/MMMM/yyyy'}
          showMonthYearPicker={showMonthYearPicker}
          showFullMonthYearPicker={showFullMonthYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          showTimeInput={showTimeInput}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          showYearPicker={showYearPicker}
          timeCaption={timeCaption}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          customInput={<ExampleCustomInput />}
        />
      </div>
      {errors && <div className="error">{errors}</div>}
    </InputDatePikerCustomStyled>
  );
};

InputDatePikerCustom.propTypes = {};

export default InputDatePikerCustom;
