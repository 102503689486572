import styled from 'styled-components';

export const InputTextFieldStyled = styled.div`
  .input_container {
    position: relative;
    .icon_top {
      position: absolute;
      top: 11px;
      left: 25px;
    }
    .label {
      margin-bottom: 8px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      // color: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
    input {
      border-radius: 4px;
      width: 100%;
      box-sizing: border-box;
      padding: 8px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 + '57' : theme.COLORS.WHITE_1};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${({ theme }) => theme.COLORS.BLACK_4};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${({ theme }) => theme.COLORS.BLACK_4};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${({ theme }) => theme.COLORS.BLACK_4};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${({ theme }) => theme.COLORS.BLACK_4};
      }
    }
    .btn_submit {
      position: absolute;
      bottom: 7px;
      right: 10px;
      display: flex;
      cursor: pointer;
    }
  }
  .error {
    margin-top: 3px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    // position: absolute;
  }

  .theme_standard {
    input {
      border: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 + '57' : theme.COLORS.WHITE_1};
      border-radius: 0px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      padding: 10px 16px;
    }
  }

  .theme_filter {
    input {
      border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
      background: ${({ theme }) => theme.COLORS.BLACK_1}0D;
    }
  }

  .theme_mini {
    input {
      border: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      background: ${({ theme, disabled }) =>
        disabled ? theme.COLORS.WHITE_1 + '57' : theme.COLORS.WHITE_1};
      border-radius: 0px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      padding: 4px 16px;
    }
  }
`;
