import styled from 'styled-components';

export const DateGroupStyled = styled.div`
  .dddddfdsfdsf {
    display: flex;
    column-gap: 10px;
    .dg_input_container {
      position: relative;
      min-width: 150px;
      .arrow_icon {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
        right: 12px;
      }
      .input_dropdown_control {
        border-radius: 4px;
        padding: 8px;
        padding-right: 24px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        background: ${({ theme }) => theme.COLORS.GREEN_1}1A;
        border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
      }
      .input_dropdown_placeholder {
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .input_dropdown_menu {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        border-radius: 4px;
        border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
      }
    }
  }

  .theme_standard {
  }
`;
