import styled from 'styled-components';

export const InputDropDownFilterStyled = styled.div`
  position: relative;
  .label {
    margin-bottom: 5px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .input_container {
    position: relative;
    min-width: 150px;
    .arrow_icon {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 12px;
    }
    .input_dropdown_control {
      border-radius: 4px;
      padding: 8px;
      padding-right: 24px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      background: ${({ theme }) => theme.COLORS.GREEN_1}1A;
      border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    }
    .input_dropdown_placeholder {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .input_dropdown_menu {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      border-radius: 4px;
      border: 2px solid ${({ theme }) => theme.COLORS.GREEN_1};
    }
  }
  .error {
    margin-top: 3px;
    // position: absolute;
    // left: 7px;
    // bottom: -20px;
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_fileter {
    
  }
  .theme_normal {
    .input_container {
      .input_dropdown_control {
        padding: 4px 16px;
        padding-right: 24px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
        border-radius: 0px;
      }
      .input_dropdown_menu {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
      }
    }
  }
  
  .theme_big {
    .input_container {
      .input_dropdown_control {
        padding: 10px 16px;
        padding-right: 24px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
        border-radius: 0px;
      }
      .input_dropdown_menu {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
      }
    }
  }
`;
