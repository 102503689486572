import React from 'react';
import { RedemptionOnProcessBottomDetailEditContainerStyled } from './styled';
import { Forms } from 'components';
import { retailerService } from 'apiServices';
import { toast } from 'react-toastify';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { SHIPPING_OPTIONS } from 'utils/functions/shipping';

class RedemptionOnProcessBottomDetailEditContainer extends React.Component {
  componentDidMount() {
    scrollToTopPageFN();
  }

  handleSubmit = async (values) => {
    const { succesEdit, data, onCloseModal } = this.props;
    const renderShippingInfo = SHIPPING_OPTIONS.find(
      (e) => e.value === values.shipping_brand.value
    );
    let params = {
      shipping_ref_no: values.shipping_ref_no,
      shipping_brand: values.shipping_brand.value,
      shipping_phone_number: renderShippingInfo.phoneNumber,
      shipping_link: renderShippingInfo.link,
      shipping_date: values.shipping_date,
      status: '3',
    };
    let res = await retailerService.PUT_REWARD_HISTORY(data._id, params);
    if (res && res.status === 200) {
      toast.success('Update success');
      onCloseModal();
      succesEdit();
    } else {
      toast.error('Update failed');
    }
  };

  render() {
    const { onCloseModal, data } = this.props;

    return (
      <RedemptionOnProcessBottomDetailEditContainerStyled>
        <div className="title_show">Redemption on process</div>
        <div className="sub_title">
          {`รหัสร้านค้า : ${
            data && data.retailer_detail.shop_no
              ? data.retailer_detail.shop_no
              : '-'
          }`}
        </div>
        <div className="sub_title">
          {`ผู้ที่ได้รับรางวัล :  ${
            data &&
            data.firstname_shipping &&
            `${data.firstname_shipping} ${data.lastname_shipping}`
          }`}
        </div>
        <div className="title_show">
          <Forms.RedemptionSendForm
            onSubmit={this.handleSubmit}
            handleBack={onCloseModal}
            initialValues={data}
          />
        </div>
      </RedemptionOnProcessBottomDetailEditContainerStyled>
    );
  }
}

export default RedemptionOnProcessBottomDetailEditContainer;
