import styled from 'styled-components';

export const NewUpdateContainerStyled = styled.div`
  .loading_show {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title_show {
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    margin-bottom: 17px;
    &.mb37 {
      margin-bottom: 37px;
    }
  }
  .label_zone {
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box_form {
    background: ${({ theme }) => theme.COLORS.BLUE_2};
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
    .left_form {
      border-right: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      width: 20%;
      padding: 18px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .right_form {
      width: 80%;
      padding: 16px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      &.flex_show {
        display: flex;
        justify-content: space-between;
      }
      .w120 {
        width: 120px;
      }
      .img_show {
        background: #fff;
        margin: auto;
        // width: 70%;
        height: 197px;
      }
    }
  }
  .btn_action_price {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 27px;
    .btn175 {
      width: 175px;
    }
  }
`;
