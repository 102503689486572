import styled from 'styled-components';

export const UserDataContainerStyled = styled.div`
  .loading_show {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title_show {
    margin-bottom: 16px;
  }
  .filter_wrap {
    margin-bottom: 22px;
  }
  .export_zone {
    display: flex;
    margin-bottom: 22px;
    column-gap: 10px;
    .btnw {
        width: 174px;
    }
  }
`;
