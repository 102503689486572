import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { HeaderH1Styled } from './styled';

const HeaderH1 = ({ theme_standard, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <HeaderH1Styled>
      <div className={customClass}>{label}</div>
    </HeaderH1Styled>
  );
};

HeaderH1.propTypes = {};

export default HeaderH1;
