import { useEffect, useState } from 'react';
import { SidebarWidgetStyled } from './styled';
import { useNavigate, useLocation } from 'react-router-dom';
import retailerRoutes from 'navigations/retailerRoutes';
import consumerRoutes from 'navigations/consumerRoutes';
import { Displays } from 'components';
import {
  RETAILER_ROUTE_PATH,
  CONSUMER_ROUTE_PATH,
} from 'utils/constants/routePath';

const SidebarWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split('/');
      _setActive(splitPathname);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.path);
  };

  const _handleActiveSystem = (e) => {
    switch (e) {
      case 'retailer_system':
        navigate(RETAILER_ROUTE_PATH.DASHBOARD);
        break;
      case 'consumer_system':
        navigate(CONSUMER_ROUTE_PATH.DASHBOARD);
        break;
      default:
        break;
    }
  };

  return (
    <SidebarWidgetStyled>
      <div className="sidbar_block_section">
        <div className="switch_system_wrap">
          <Displays.TitleSwitchLabel
            active={_active && _active[2]}
            labelLeft="Consumer"
            labelRight="Retailer"
            onActive={_handleActiveSystem}
          />
        </div>
      </div>
      {_active && _active[2] === 'consumer_system'
        ? consumerRoutes.map((e, i) => (
          <div key={i} className={`sidbar_block_section ${i === 1 ? 'blue' : i === 2 && 'pink'}`}>
            {e.children
              .filter((g) => !g.isSubMenu)
              .map((f, j) => (
                <div
                  key={j}
                  className="sb_menu_item"
                  onClick={() => _handleClickMenu(f)}
                >
                  <Displays.IconLabel
                    theme_sidebar
                    icon={f.icon}
                    label={f.name}
                    active={_active && _active[3] === f.key}
                  />
                </div>
              ))}
          </div>
        ))
        : retailerRoutes.map((e, i) => (
          <div key={i} className={`sidbar_block_section ${i === 1 ? 'blue' : i === 2 && 'pink'}`}>
            {e.children
              .filter((g) => !g.isSubMenu)
              .map((f, j) => (
                <div
                  key={j}
                  className="sb_menu_item"
                  onClick={() => _handleClickMenu(f)}
                >
                  <Displays.IconLabel
                    theme_sidebar
                    icon={f.icon}
                    label={f.name}
                    active={_active && _active[3] === f.key}
                  />
                </div>
              ))}
          </div>
        ))}
    </SidebarWidgetStyled>
  );
};

export default SidebarWidget;
