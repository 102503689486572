import styled from 'styled-components';

export const NavSidebarStyled = styled.div`
  height: 100%;
  .body_mainlayout {
    min-height: calc(100% - 68px);
    display: flex;
    .sidbar_wrap {
      flex-shrink: 0;
    }
    .content_mainlayout {
      flex: 1;
      padding: 12px;
    }
  }
`;
