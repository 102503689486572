import styled from 'styled-components';

export const TableNormalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_standard_table_normal {
    .table_container {
      background: ${({ theme }) => theme.COLORS.BLUE_2};
      .table_header {
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
        .header_row {
          // max-width: 1200px;
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          .header_col {
            width: 100%;
            padding: 10px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .display_loading {
          height: 150px;
          padding: 10px;
          padding-bottom: 12px;
        }
        .label_empty {
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
        .body_row_wrap {
          // border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_1}1A;
          .body_row {
            // max-width: 1200px;
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;

            cursor: pointer;
            padding: 10px 0;
            align-items: center;

            .body_col {
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              color: ${({ theme }) => theme.COLORS.BLACK_1};
              padding: 0 10px;
              width: 100%;
            }
          }
          &:hover {
            background: ${({ theme }) => theme.COLORS.BLACK_1}0D;
          }
        }
      }
      .pagi_layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        padding-top: ${({ paddingTopPagi }) =>
          paddingTopPagi ? paddingTopPagi : '15px'};
        .lable_total {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          margin-left: 30px;
        }
      }
    }
  }

  .theme_standard_table_small {
    background: ${({ theme }) => theme.COLORS.BLUE_2};
    padding: 12px;
    .title_table {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_4};
      padding-bottom: 10px;
      text-align: left;
      padding-left: 10px;
    }
    .table_container {
      .table_header {
        // background: ${({ theme }) => theme.COLORS.ORANGE_1};
        display: none;
        .header_row {
          max-width: 1200px;
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          .header_col {
            width: 100%;
            padding: 15px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S15};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
            // color: ${({ theme }) => theme.COLORS.BLUE_10};
            &:first-child {
              border-left: none;
            }
          }
        }
      }
      .table_body {
        .label_empty {
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
        .body_row_wrap {
          // border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLACK_1}1A;
          .body_row {
            max-width: 1200px;
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;

            cursor: pointer;
            padding: 12px 0;
            align-items: center;

            .body_col {
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
              padding: 0 15px;
              width: 100%;
            }
          }
          &:hover {
            // background: ${({ theme }) => theme.COLORS.GRAY_1}0D;
          }
        }
      }
      // .pagi_layout_top {
      //   margin-bottom: ${({ marginBottomPagination }) =>
        marginBottomPagination ? marginBottomPagination : '22px'};
      // }
      // .pagi_layout {
      //   margin-top: ${({ paddingTopPagi }) =>
        paddingTopPagi ? paddingTopPagi : '32px'};
      // }
    }
  }
`;
