import React from 'react';
import { ProductRegisterDetailContainerStyled } from './styled';
import { Icons, Tables, Displays } from 'components';
import { retailerService } from 'apiServices';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import 'moment-timezone';
import { IMG_PATH } from 'utils/constants/imgPath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class ProductRegisterDetailContainer extends React.Component {
  state = { isLoading: true, data: null, dataBranch: [] };

  componentDidMount() {
    scrollToTopPageFN()
    this.fetchData();
  }

  fetchData = async () => {
    let res = await retailerService.GET_PRODUCT_REGISTED_DETAIL(this.props.router.params.id);
    if (res && res.status === 200) {
      let querString = `?parentRetailerRefId=${res.data.parent_retailer_ref_id}`
      let resBranch = await retailerService.GET_RETAILER_LIST(querString);

      if (resBranch && resBranch.status === 200) {
        this.setState({
          data: res.data,
          dataBranch: resBranch.data.docs,
          isLoading: false,
        })
      } else {
        this.setState({
          data: res.data,
          isLoading: false,
        })
      }
    }
  };

  render() {
    const { isLoading, data, dataBranch } = this.state;
    return (
      <ProductRegisterDetailContainerStyled>
        {
          isLoading
            ?
            <div className='loading_show'>
              <Displays.Loading />
            </div>
            :
            <>
              <div className='title_show'>
                Product register data
              </div>
              <div className='title_show'>
                <div className='mr20'>S/N Detail:</div>
                <div>{data?.serial_no}</div>
              </div>
              <div className='sub_title_show'>
                <div className='line'>
                  ลงทะเบียนร้านค้าโดย
                </div>
                <div className='bold'>
                  {
                    data?.retailer_detail?.is_main_branch ? 'สาขาหลัก' : 'สาขาย่อย'
                  }
                </div>
              </div>
              <div className='box_sub_detail'>
                <div className='group_box mr200'>
                  <div className='line_box'>
                    <div className='left_label'>
                      รหัสร้านค้า
                    </div>
                    <div className='right_label'>
                      {data?.retailer_detail?.shop_no}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ชื่อร้านค้า
                    </div>
                    <div className='right_label'>
                      {data?.retailer_detail?.store_name}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      เลขบัตร
                    </div>
                    <div className='right_label'>
                      {data?.retailer_detail?.tax_id ? data.retailer_detail.tax_id : '-'}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ที่อยู่
                    </div>
                    <div className='right_label'>
                      {
                        data?.retailer_detail
                          ?
                          `${data.retailer_detail.address} ${data.retailer_detail.sub_district} ${data.retailer_detail.district} ${data.retailer_detail.province} ${data.retailer_detail.zipcode}`
                          :
                          `-`
                      }
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ชื่อติดต่อ
                    </div>
                    <div className='right_label'>
                      {`${data?.retailer_detail?.firstname} ${data?.retailer_detail?.lastname}`}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      เบอร์โทรศัพท์
                    </div>
                    <div className='right_label'>
                      {data?.retailer_detail?.phone_number}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      จำนวนสาขาย่อย
                    </div>
                    <div className='right_label'>
                      {dataBranch.length > 0 ? dataBranch.length : '-'}
                    </div>
                  </div>
                </div>
                <div className='group_box'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ประเภทร้าน
                    </div>
                    <div className='right_label'>
                      ร้านอะไหล่
                    </div>
                  </div>
                </div>
              </div>
              <div className='table_zone'>
                <div className='title_table_zone'>
                  สาขาย่อย
                </div>
                <Tables.TableNormal
                  theme_standard_table_normal
                  showPagination
                  useNo
                  data={dataBranch}
                  columns={columns4}
                />
              </div>
              <div className='sub_title_show mt37'>
                <div className='line'>
                  รายละเอียดสินค้า
                </div>
              </div>
              {
                (data.slip_img_path && data.slip_img_path !== '') &&
                <div className='box_img'>
                  <img
                    src={IMG_PATH + data?.slip_img_path}
                    alt="img"
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  />
                </div>
              }

              <div className='box_sub_detail'>
                <div className='group_box mr400'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ยี้ห้อรถ
                    </div>
                    <div className='right_label'>
                      {data?.car_brand}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      รุ่นรถ
                    </div>
                    <div className='right_label'>
                      {data?.car_model}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ปี
                    </div>
                    <div className='right_label'>
                      {data?.car_year}
                    </div>
                  </div>
                </div>
                <div className='group_box'>
                  <div className='line_box'>
                    <div className='left_label'>
                      ประเภทโช้คอัพ
                    </div>
                    <div className='right_label'>
                      {data?.product_type}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      ตำแหน่งโช้คอัพ
                    </div>
                    <div className='right_label'>
                      {data?.install_position}
                    </div>
                  </div>
                  <div className='line_box'>
                    <div className='left_label'>
                      รุ่นสินค้า
                    </div>
                    <div className='right_label'>
                      {data?.product_model}
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
      </ProductRegisterDetailContainerStyled>
    );
  }
}

const columns4 = [
  {
    title: 'ลำดับ',
    field: 'no',
    headerStyle: { textAlign: 'center', width: '50px', flexShrink: 0 },
    style: { textAlign: 'center', width: '50px', flexShrink: 0 },
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        วันที่ลงทะเบียน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'created_at',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {`${moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')}`}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อร้าน
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'store_name',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div style={{ textDecoration: 'underline' }}>
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ชื่อ
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'firstname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        นามสกุล
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'lastname',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        เบอร์โทร
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'phone_number',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {value}
      </div>
    ),
  },
  {
    title: (
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        ที่อยู่
        <div style={{ marginLeft: '8px', display: 'flex' }}>
          <Icons.ArrowSort />
        </div>
      </div>
    ),
    field: 'address',
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    render: ({ origin, value }) => (
      <div >
        {`${origin.address} ${origin.sub_district} ${origin.district} ${origin.province} ${origin.zipcode}`}
      </div>
    ),
  },
  // {
  //   title: (
  //     <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
  //       จำนวนลงทะเบียน
  //       <div style={{ marginLeft: '8px', display: 'flex' }}>
  //         <Icons.ArrowSort />
  //       </div>
  //     </div>
  //   ),
  //   field: 'amount',
  //   headerStyle: { textAlign: 'left' },
  //   style: { textAlign: 'center' },
  //   render: ({ origin, value }) => (
  //     <div >
  //       {value}
  //     </div>
  //   ),
  // },
];

export default withRouter(ProductRegisterDetailContainer);