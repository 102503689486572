import React from 'react';
// import PropTypes from 'prop-types'
import { ProfileStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components'

const Profile = ({
  them_profile,
}) => {
  const customClass = cx({
    them_profile: them_profile,
  });

  return (
    <ProfileStyled>
      <div className={customClass}>
        <div className='continaer_profile'>
          <div>
            Ronnie Woodkin
          </div>
          <div className='icon_show'>
            <Icons.ArrowDown />
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};

Profile.propTypes = {};

export default Profile;
