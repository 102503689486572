import React from 'react';

export default function renderIcon({
  width="20.307",
  height="19.958" ,
  color = '#4E5B7E',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.307 19.958">
      <g id="Date" transform="translate(1 1)">
        <rect id="Rectangle" width="17.915" height="4.31" transform="translate(0 2.155)" fill="none" stroke="#394875" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path" d="M.479,0V2.155" transform="translate(4.746)" fill="none" stroke="#394875" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path-2" data-name="Path" d="M.479,0V2.155" transform="translate(12.211)" fill="none" stroke="#394875" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path-3" data-name="Path" d="M17.915,0H0V11.493H14.78l.672-.646A8,8,0,0,0,17.915,5.1Z" transform="translate(0 6.465)" fill="none" stroke="#394875" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path-4" data-name="Path" d="M0,6.465V2.873H0a4.2,4.2,0,0,0,2.986-1.8L3.732,0" transform="translate(14.183 11.493)" fill="none" stroke="#394875" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
      </g>
    </svg>
  );
}
