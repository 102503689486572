import { DATA_SHOP } from '../actions/actionTypes';

const initialState = {};

const dataShop = (state = initialState, action) => {
  switch (action.type) {
    case DATA_SHOP:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default dataShop;
