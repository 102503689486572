import React, { useEffect } from 'react';
import { RedemptionSendFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SHIPPING_OPTIONS } from 'utils/functions/shipping';
import { Inputs, Buttons, Icons } from 'components';

const RedemptionSendForm = ({ onSubmit, handleBack, initialValues }) => {
  const schema = yup.object().shape({
    shipping_ref_no: yup.string().required('Shipping no is required'),
    // shipping_brand: yup.string().required('Shipping brand is required'),
    shipping_brand: yup.object().shape({
      label: yup.string().required('Shipping brand is required'),
      value: yup.string().required('Shipping brand is required'),
    }),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        shipping_ref_no: initialValues.shipping_ref_no,
        shipping_brand: initialValues.shipping_brand && {
          label: initialValues.shipping_brand,
          value: initialValues.shipping_brand,
        },
        shipping_date:
          initialValues.shipping_date && initialValues.shipping_date,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RedemptionSendFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_form">
          <div className="left_form">บ. ที่จัดส่ง</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputDropDown
                  theme_big
                  placeholder="บ. ที่จัดส่ง"
                  {...field}
                  iconSvgClose={<Icons.ArrowDropDown />}
                  iconSvgOpen={<Icons.ArrowDropDown up />}
                  options={SHIPPING_OPTIONS}
                  errors={errors.shipping_brand?.message}
                />
              )}
              name="shipping_brand"
              defaultValue={SHIPPING_OPTIONS[0]}
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">เลขที่ส่ง</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputTextField
                  theme_standard
                  {...field}
                  placeholder="เลขที่ส่ง"
                  errors={errors.shipping_ref_no?.message}
                />
              )}
              name="shipping_ref_no"
              defaultValue=""
            />
          </div>
        </div>
        <div className="box_form">
          <div className="left_form">วันที่ส่ง</div>
          <div className="right_form">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputDatePikerCustom
                  theme_standard_date_picker_custom
                  {...field}
                  dateFormat="dd/MM/yyyy"
                  // errors={errors.shipping_date?.message}
                />
              )}
              name="shipping_date"
              defaultValue={new Date()}
            />
          </div>
        </div>
        <div className="btn_action_price">
          <div className="btn175">
            <Buttons.BtnNormal
              theme_normal_btn
              label="ย้อนกลับ"
              onClick={handleBack}
            />
          </div>
          <div className="btn175">
            <Buttons.BtnNormal theme_normal_btn label="ยืนยัน" type="submit" />
          </div>
        </div>
      </form>
    </RedemptionSendFormStyled>
  );
};

RedemptionSendForm.propTypes = {};

export default RedemptionSendForm;
