import React, { useState, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UploadImageStyled } from "./styled";
import { Buttons } from "components";
import { useDropzone } from "react-dropzone";
import { IMG_PATH } from "utils/constants/imgPath";

const UploadImage = ({
  theme_standard_upload_image,
  acceptType = "cover",
  onChange,
  value,
  maxMBFileSizeWarning,
  disabled,
  fieldName,
  name,
  isDelete,
}) => {
  const customClass = cx({
    theme_standard_upload_image: theme_standard_upload_image,
  });

  const [files, setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        return "image/*";
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return "video/*";
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
        }
      }
      let fileTemp = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  const onClickRemove = (index, data) => {
    if (data[0].isNewUpload) {
      let filesTemp = files;
      filesTemp.splice(0, 1);
      setFiles([...filesTemp]);
      onChange && onChange([...filesTemp]);
    } else {
      let filesTemp = files;
      filesTemp[0].isDelete = true;
      filesTemp[0].fieldName = fieldName || name;
      let filter = [...filesTemp.filter((e) => !e.isNewUpload)];
      setFiles(filter);
      onChange && onChange(filter);
    }
  };

  return (
    <UploadImageStyled>
      <div className={customClass}>
        <div
          className={`body_upload ${
            files && files.length > 0 ? "" : "no_display"
          } `}
        >
          {files && files.length > 0 ? (
            files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  className="show_img"
                  key={i}
                  style={{ backgroundImage: `url(${e.preview})` }}
                  alt="upload reward"
                >
                  {/* <div className="close" onClick={() => onClickRemove(i, e)}>
                    <Icons.CloseX /> d 
                  </div> */}
                </div>
              ) : (
                !e.isDelete && (
                  <div
                    className="show_img"
                    key={i}
                    style={{
                      backgroundImage: `url(${IMG_PATH + e.image_uri})`,
                    }}
                    alt="upload reward"
                  >
                    {/* <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX /> 
                    </div> */}
                  </div>
                )
              )
            )
          ) : (
            // <Icons.Upload />
            <div>a</div>
          )}
        </div>
        <div className="group_btn">
          {isDelete && files && files.length > 0 && (
            <div className="remove_btn">
              <Buttons.BtnNormal
                theme_delete
                label="ลบรูป"
                onClick={() => onClickRemove(0, files)}
              />
            </div>
          )}
          <div {...getRootProps({ className: "btn_upload" })}>
            <input {...getInputProps()} disabled={disabled} />
            <div className="add_btn">
              <Buttons.BtnNormal
                theme_normal_btn
                label={files && files.length > 0 ? "แก้ไขรูป" : "เพิ่มรูป"}
              />
            </div>
          </div>
        </div>
      </div>
    </UploadImageStyled>
  );
};

UploadImage.propTypes = {};

export default UploadImage;
