import styled from 'styled-components';

export const SkeletonLoadStyled = styled.div`
  height: ${({ height }) => height ? height : '374px'};
  .body_skeleton {
    height: 100%;
  }
  .theme_skeleton_normal {
    height: ${({ height }) => height ? height : '374px'};
  }
`;
