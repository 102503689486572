import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types'
import { BgLoadingStyled } from './styled';
import * as Loader from 'react-loader-spinner';

const BgLoading = ({
  theme_standard_loading,
  visible,
  color = '#307E8D',
  height = 70,
  width = 70,
  timeout,
}) => {
  let loadingRef = useRef();
  const [_isLoading, _setIsLoading] = useState(false);

  useEffect(() => {
    _setIsLoading(visible ? true : false);
    if (visible) {
      loadingRef.current = setTimeout(() => {
        _setIsLoading(false);
      }, 6000);
    } else {
      loadingRef.current && clearTimeout(loadingRef.current);
    }
    return () => {
      _setIsLoading(false);
      clearInterval(loadingRef.current);
    };
  }, [visible]);

  return (
    <BgLoadingStyled visible={_isLoading}>
      <Loader.Oval
        color={color}
        height={height}
        width={width}
        timeout={timeout} //3 secs
      />
    </BgLoadingStyled>
  );
};

BgLoading.propTypes = {};

export default BgLoading;
