import React from 'react';
import { BannerEditContainerStyled } from './styled';
import { Displays, Forms } from 'components';
import { withRouter } from 'navigations/withRouter';
import { CONSUMER_ROUTE_PATH } from 'utils/constants/routePath';
import { consumerService } from 'apiServices';
import { toast } from 'react-toastify';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';

class BannerEditContainer extends React.Component {
  state = {
    isLoading: true,
    initialValues: null,
    disabled: false,
    labelSucces:
      this.props.router.params.id !== 'create'
        ? 'Update success'
        : 'Add success',
    labelFailed:
      this.props.router.params.id !== 'create' ? 'Update failed' : 'Add failed',
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  fetchData = async () => {
    let res = await consumerService.GET_CONSUMER_BANNER_DETAIL(
      this.props.router.params.id
    );

    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleBack = () => {
    if (this.props.router.params.id === 'create') {
      this.props.router.navigate(CONSUMER_ROUTE_PATH.BANNER);
    } else {
      this.props.router.navigate(
        `${CONSUMER_ROUTE_PATH.BANNER}/${this.props.router.params.id}`
      );
    }
  };

  handleSubmit = async (values) => {
    const { labelSucces, labelFailed } = this.state;
    let params = {
      title: values.title,
      link: values.link,
      is_active: values.is_active,
    };

    this.setState({
      disabled: true,
    });
    if (this.props.router.params.id === 'create') {
      let res = await consumerService.POST_CONSUMER_BANNER(params);
      if (res && res.status === 200) {
        if (
          !values.img_path ||
          values.img_path.length === 0 ||
          !values.img_path[0].isNewUpload
        ) {
          toast.success(labelSucces);
          this.props.router.navigate(
            `${CONSUMER_ROUTE_PATH.BANNER}/${res.data._id}`
          );
        } else {
          this.handleUpload(res.data._id, values.img_path);
        }
      } else {
        toast.error(labelFailed);
      }
    } else {
      let res = await consumerService.PUT_CONSUMER_BANNER(
        this.props.router.params.id,
        params
      );
      if (res && res.status === 200) {
        if (
          !values.img_path ||
          values.img_path.length === 0 ||
          !values.img_path[0].isNewUpload
        ) {
          toast.success(labelSucces);
          this.props.router.navigate(
            `${CONSUMER_ROUTE_PATH.BANNER}/${this.props.router.params.id}`
          );
        } else {
          this.handleUpload(this.props.router.params.id, values.img_path);
        }
      } else {
        toast.error(labelFailed);
      }
    }
  };
  handleUpload = async (id, files) => {
    let bodyFormData = new FormData();
    bodyFormData.set('_id', id);
    bodyFormData.append('image', files[0]);
    let res = await consumerService.POST_UPLOAD_CONSUMER_BANNER(bodyFormData);
    if (res && res.status === 200) {
      toast.success('Update success');
      this.props.router.navigate(`${CONSUMER_ROUTE_PATH.BANNER}/${id}`);
    } else {
      toast.error('Upload fail');
    }
  };

  render() {
    const { isLoading, initialValues, disabled } = this.state;

    return (
      <BannerEditContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="title_show">Banner</div>
            <Forms.BannerForm
              handleBack={this.handleBack}
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              disabled={disabled}
            />
          </>
        )}
      </BannerEditContainerStyled>
    );
  }
}

export default withRouter(BannerEditContainer);
