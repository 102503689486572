import React from 'react';
import { TableNormalStyled } from './styled';
import cx from 'classnames';
import { Displays } from 'components';

export const TableNormal = ({
  theme_standard_table_small,
  theme_standard_table_normal,
  columns,
  data,
  pagination,
  // isShowTopPagination,
  titleTable,
  tableHeight,
  bodyHeight,
  useNo,
  isLoading,
  bgColor,
  bgHeader,
  showPagination,
  paddingTopPagi,
  marginBottomPagination,
  handlePageClick,
  lableBottom,
  // labelEmpty,
  onClickRow,
  useRenderNoPage,
}) => {
  const customClass = cx({
    theme_standard_table_small: theme_standard_table_small,
    theme_standard_table_normal: theme_standard_table_normal,
  });

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  return (
    <TableNormalStyled
      bgHeader={bgHeader}
      paddingTopPagi={paddingTopPagi}
      marginBottomPagination={marginBottomPagination}
      tableHeight={tableHeight}
      bodyHeight={bodyHeight}
    >
      <div className={customClass}>
        <div className="table_container">
          {
            titleTable &&
            <div className='title_table'>
              {titleTable}
            </div>
          }
          <div className="table_header" style={{ background: bgColor }}>
            <div className="header_row">
              {columns &&
                columns.map((e, i) => (
                  <div key={i} className="header_col" style={e.headerStyle}>
                    {e.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className="table_body">
            {isLoading ? (
              <div className='display_loading'>
                <Displays.SkeletonLoad
                  theme_skeleton_normal
                  height='100%'
                />
              </div>
            ) : !data || data.length === 0 ? (
              <div className="label_empty">No data</div>
            ) : (
              data.map((e, i) => {
                return (
                  <div key={i} className="body_row_wrap">
                    <div
                      className="body_row"
                      style={e.rowSty ? e.rowSty : {}}
                      onClick={() => {
                        onClickRow && onClickRow(e);
                      }}
                    >
                      {columns.map((e1, i2) => (
                        <div key={i2} className="body_col" style={e1.style}>
                          {useNo && i2 === 0 ? (
                            <div className="no">
                              {e1.render
                                ? e1.render({
                                  origin: e,
                                  value: i + 1,
                                })
                                : !useRenderNoPage
                                  ? i + 1
                                  : pagination.from + i}
                            </div>
                          ) : e1.render ? (
                            e1.render({
                              origin: e,
                              value: getDescendantProp(e, e1.field),
                            })
                          ) : (
                            getDescendantProp(e, e1.field)
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {showPagination && pagination && data && data.length > 0 && (
            <div className="pagi_layout">
              <div className='lable_total'>
                {lableBottom && lableBottom}
              </div>
              <Displays.PaginationCustom
                theme_standard_pagination
                totalPages={pagination?.last_page}
                currentPageData={pagination?.current_page - 1}
                _handlePageClick={handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    </TableNormalStyled>
  );
};

export default TableNormal;
